import React from 'react'
import { images } from '../Pictures/images'

const AltHackCarousel = () => {
  return (
    <div
      id='carouselExampleDark'
      className='carousel carousel-dark slide'
      data-bs-ride='carousel'
    >
      <div className='carousel-indicators'>
        <button
          type='button'
          data-bs-target='#carouselExampleDark'
          data-bs-slide-to='0'
          className='active'
          aria-current='true'
          aria-label='Slide 1'
        ></button>
        <button
          type='button'
          data-bs-target='#carouselExampleDark'
          data-bs-slide-to='1'
          aria-label='Slide 2'
        ></button>
        <button
          type='button'
          data-bs-target='#carouselExampleDark'
          data-bs-slide-to='2'
          aria-label='Slide 3'
        ></button>
      </div>
      <div className='carousel-inner py-5'>
        <div className='carousel-item active' data-bs-interval='10000'>
          <div className='row'>
            <div className='col-md-6'>
              <img
                src={images.hydStory}
                className='img-fluid '
                loading='lazy'
                alt='...'
              />
            </div>
            <div className='col-md-6'>
              <h5>
                {/* <span className='quote-size'>&#8220;</span> */}
                Digital India Alt Hack Hyderabad
                {/* <span className='quote-size'>&#8221;</span> */}
              </h5>
              <p>
                Digital India Alt Hack Hyderabad, held at Tech Mahindra from
                November 22 to November 29. With over 300 on ground
                participants, Alt Hack enabled students to seamlessly transition
                from Web2.0 to Web3.0, it was a groundbreaking event that
                ignited innovation.
              </p>
            </div>
          </div>
        </div>
        <div className='carousel-item' data-bs-interval='2000'>
          <div className='row'>
            <div className='col-md-6'>
              <img
                src={images.vizStory}
                className='img-fluid'
                loading='lazy'
                alt='img'
              />
            </div>
            <div className='col-md-6'>
              <h5>
                {/* <span className='quote-size'>&#8220;</span> */}
                Digital India Alt Hack Vizag
                {/* <span className='quote-size'>&#8221;</span> */}
              </h5>
              <p>
                Digital India Alt Hack Vizag, groundbreaking event held at GVPCE
                from December 16 to December 23. Witness how it empowered
                students with seamless transition from Web2.0 to Web3.0.
                Innovative sessions, mentorship, and hands-on learning helped
                students generate over 2300+ ideas.
              </p>
            </div>
          </div>
        </div>
        <div className='carousel-item' data-bs-interval='2000'>
          <div className='row'>
            <div className='col-md-6'>
              <img
                src={images.chennaiStory}
                className='img-fluid'
                loading='lazy'
                alt='img'
              />
            </div>
            <div className='col-md-6'>
              <h5>
                {/* <span className='quote-size'>&#8220;</span> */}
                Digital India Alt Hack Chennai
                {/* <span className='quote-size'>&#8221;</span> */}
              </h5>
              <p>
                Digital India Alt Hack Chennai commenced on March 24 through
                March 31,2023 at Anna University with over 350+ on ground
                participants. Engaging panel discussions, live online sessions
                and ideation sessions helped students generate around 2000
                ideas, while transitioning smoothly from Web2.0 to Web3.0.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AltHackCarousel
