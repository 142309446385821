import React, { useState } from 'react'

import { learningData } from '../../../utils/constants'

import img1 from '../../../assets/images/CEP-1.png'
import img2 from '../../../assets/images/CEP-2.png'
import img3 from '../../../assets/images/CEP-3.png'
import { useLocation, useNavigate, useParams } from 'react-router'
import CoursesGrid from '../../shared/CoursesGrid'
import { Modal } from 'react-bootstrap'
import {
  getUserCourseInterest,
  postUserCourseInterest
} from '../../../API/trackingAPI'
import swal from 'sweetalert'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { logOut } from '../../../redux/loginLogout'

const Courses = () => {
  const imgs = [img1, img2, img3]

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const [course, setCourse] = useState(null)

  const closeModal = () => {
    setShowModal(!showModal)
  }

  const getInterest = async (courseId, courseData) => {
    try {
      const res = await getUserCourseInterest(courseId)

      const { data } = res

      if (data?.data[0]?.isInterested) {
        navigate(`${location?.pathname}/${courseData?.courseId}`, {
          state: { courseId: courseData?.courseId }
        })
      } else {
        setCourse({ ...courseData })
        setShowModal(true)
      }
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error
      swal(
        `${response?.data?.errors?.message || 'Unable to Update.'}`,
        '',
        'error'
      )
    }
  }

  const postInterest = async (courseId) => {
    try {
      const res = await postUserCourseInterest({
        courseId: parseInt(courseId)
      })
      navigate(`${location?.pathname}/${course?.courseId}`, {
        state: { courseId: course?.courseId }
      })
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error
      swal(
        `${response?.data?.errors?.message || 'Unable to Update.'}`,
        '',
        'error'
      )
    }
  }

  return (
    <div className='learning-page'>
      <h3
        className='fw-semibold fs-4 mb-4 ms-2'
        style={{
          color: '#121212'
        }}
      >
        Courses
      </h3>
      <div className='row m-0 p-0 course-overflow-div'>
        {learningData?.map((data) => {
          return (
            <div
              className='col-12 col-md-6 col-lg-4 col-xl-3'
              key={data?.courseId}
            >
              <CoursesGrid
                text={data?.courseTitle}
                click={() => {
                  getInterest(data?.courseId, data)
                }}
                courseImg={imgs[data?.courseId - 1]}
              />
            </div>
          )
        })}
      </div>
      <Modal show={showModal} onHide={closeModal} className='course-modal'>
        <Modal.Header
          closeButton
          className='course-modal-head bg-white border-0'
        >
          <Modal.Title className='fs-4 fw-bold'>
            {course?.courseTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=''>
          <div
            style={{
              border: '1px solid #D7D7D7'
            }}
            className='p-4'
          >
            <h3 className='fw-semibold fs-6'>About the course</h3>
            <p className='fs-14 '>{course?.courseDescription}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex align-items-center justify-content-center bg-white py-3 border-0'>
          <button
            type='button'
            className='text-white fs-16 px-5 border-0 rounded-1 py-3'
            style={{
              backgroundColor: '#11142D'
            }}
            onClick={() => {
              // navigate(`${location?.pathname}/${course?.courseId}`, {
              //   state: { courseId: course?.courseId }
              // })
              postInterest(course?.courseId)
            }}
          >
            I’m Interested
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Courses

