import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { getIdea, updateIdea } from '../../../API/eventsAPI'
import { useNavigate, useParams, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import swal from 'sweetalert'
import Cookies from 'js-cookie'
import { Tooltip } from 'react-tooltip'
import { logOut } from '../../../redux/loginLogout'
import { set } from 'lodash'
import { infoIcon } from '../../../assets/svg'

const IdeaSubmission = ({ setTabActive }) => {
  const hiddenInputRef = useRef(null)
  const [selectedfile, setSelectedFile] = useState(null)
  const formRef = useRef(null)
  const [touched, setTouched] = useState(false)

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { id } = params

  const [formValues, setFormValues] = useState({
    solution: '',
    referenceLinks: ''
  })

  useEffect(() => {
    // Loop over them and prevent submission

    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  useEffect(() => {
    getEventIdea()
  }, [])

  const getEventIdea = async () => {
    try {
      const res = await getIdea(id)
      const { data } = res
      setFormValues({
        solution: data?.data[0]?.solution || '',
        referenceLinks: data?.data[0]?.referenceLinks || '',
        ideaFile: data?.data[0]?.ideaFile || ''
      })
    } catch (error) {
      debugger
    }
  }

  const handleClick = (event) => {
    event.preventDefault()
    hiddenInputRef.current.click()
  }

  const handleFileUpload = (event) => {
    setTouched(true)

    if (event.target.files.length === 0) {
      return false
    }
    if (event.target.files[0].size >= 1000000 * 10) {
      toast.error('Max allowed file size is 10MB .')
    } else {
      setSelectedFile(event.target.files[0])
    }
  }

  const handleChange = (e) => {
    setTouched(true)
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const updateIdeaSubmission = async (e) => {
    e.preventDefault()

    let formData = new FormData()

    formData.append('ideaFile', selectedfile)
    formData.append('eventId', id)
    formData.append('referenceLinks', formValues.referenceLinks)
    formData.append('solution', formValues.solution)

    try {
      const res = await updateIdea(formData)
      swal(
        `${res?.data?.message || 'Idea submission successful.'}`,
        '',
        'success'
      )
      setTabActive(0)
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  return (
    <div className='team-formation create-team py-3'>
      <div className=''>
        <div className='card card-form-bg border-0 shadow-none'>
          <div className='card-body'>
            <div className='d-flex align-items-start justify-content-start'>
              <h6 className='head-h6'>Idea Submission</h6>
              <div className='tool-tip ms-2 d-flex align-items-center'>
                <div className='tooltip-icon' id='idea-submit-tooltip'>
                  {infoIcon}
                </div>
              </div>
            </div>

            <form
              className='row g-3 py-3 needs-validation'
              onSubmit={updateIdeaSubmission}
              noValidate
              ref={formRef}
            >
              <div className='col-md-12'>
                <label htmlFor='validationCustom02' className='form-label'>
                  Brief Solution Approach *
                </label>

                <input
                  type='text'
                  className='form-control'
                  id='validationCustom02'
                  required
                  placeholder='Solution Approach'
                  name='solution'
                  value={formValues.solution}
                  onChange={handleChange}
                />

                <div className='invalid-feedback'>Please input this field!</div>
              </div>

              <div className='col-md-6'>
                <label
                  for='formFile'
                  className='form-label d-flex align-items-start justify-content-start tooltip-label'
                >
                  Submit Your Idea in a Document *
                  <div className='tool-tip ms-2 d-flex align-items-center'>
                    <div className='tooltip-icon' id='submit-doc-tooltip'>
                      {infoIcon}
                    </div>
                  </div>
                </label>
                <input
                  type='file'
                  ref={hiddenInputRef}
                  onChange={handleFileUpload}
                  style={{
                    display: 'none'
                  }}
                  accept='.pdf,.docx'
                />
                <div className='upload-box d-flex align-items-center'>
                  <button
                    className='btn btn-icon'
                    onClick={handleClick}
                    type='button'
                  >
                    <i className='fa-solid fa-plus' id='formFile'></i>
                  </button>
                  {touched ? (
                    <div className='mb-1'>{selectedfile?.name || ''}</div>
                  ) : formValues?.ideaFile?.includes('https') ? null : (
                    <div className='mb-1'>{selectedfile?.name || ''}</div>
                  )}
                </div>
                {formValues?.ideaFile?.includes('https') ? (
                  <a
                    href={formValues?.ideaFile}
                    target='blank'
                    className='mt-2 d-block text-decoration-none'
                  >
                    {formValues.ideaFile}
                  </a>
                ) : null}
                <div className='invalid-feedback'>Please input this field!</div>
              </div>

              <div className='col-md-12'>
                <label htmlFor='validationCustom02' className='form-label'>
                  Any Reference Link
                </label>

                <input
                  type='text'
                  className='form-control'
                  id='validationCustom02'
                  placeholder='Any reference Links'
                  name='referenceLinks'
                  value={formValues.referenceLinks}
                  onChange={handleChange}
                />
              </div>

              <div className='col-md-12 text-end py-4'>
                <button type='submit' className='btn btn-blue round-0 '>
                  Submit Your Idea
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Tooltip
        anchorSelect='#idea-submit-tooltip'
        place='right'
        content='Submit your final team idea.'
        className='custom-tooltip'
        style={{
          background: '#f5F5F5',
          boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)',
          color: '#11142D',
          fontSize: '14px',
          borderRadius: '4px',
          padding: '10px 20px'
        }}
      />
      <Tooltip
        anchorSelect='#submit-doc-tooltip'
        place='right'
        content='(Accepted file type : pdf, doc, docx. Maximum file size is 10MB)'
        className='custom-tooltip'
        style={{
          background: '#f5F5F5',
          boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)',
          color: '#11142D',
          fontSize: '14px',
          borderRadius: '4px',
          padding: '10px 20px'
        }}
      />
    </div>
  )
}

export default IdeaSubmission
