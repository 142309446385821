import React from 'react'
import swal from 'sweetalert'
import moment from 'moment'

const CepQuizzes = () => {
  const tableHeadsQuizzes = [
    'Topic Name',
    'Start Date & Time',
    'Quiz Link',
    'Assignment Link'
  ]

  // for CEP Program
  const cepQuizzes = [
    {
      id: 1,
      topic: 'Rust Training  Tech Session 1',
      startdate: '11/08/2023',
      startime: '20:00:00',
      enddate: '',
      endtime: '24:00:00',
      quizLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSdjqvpi9rQY5G0I9D-w64oGSQqPp51SWcnlZgk18gnxE_FbEA/viewform',
      assignmentLink:
        'https://docs.google.com/forms/d/1vdSKzNHf7m05oSAPDSuFI5ISSyNfySGktacqtn-IjqY/edit',
      isEnabled: true
    },
    {
      id: 2,
      topic: 'Rust Training  Tech Session 2',
      startdate: '16/08/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSfZiA9xEzq9Zx_DJMzz5GsE3obejibe8yPo2E6SmuIHi2rgcg/viewform',
      assignmentLink:
        'https://docs.google.com/forms/d/1Wc5mBBFb-7ABVXgvJUf7wkP7vNQgNvfiuNeuVA6-qws/edit',
      isEnabled: true
    },
    {
      id: 3,
      topic: 'Rust Training  Tech Session 3',
      startdate: '18/08/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSfkJgx1Luo6IrUQ9McW45dLoyTp0lERXaT8oJajswURcWdWhQ/viewform',
      assignmentLink:
        'https://docs.google.com/forms/d/1JtFwQiS2CG61ZOTOid8ExK5-ki3UZS3IT3XleiqcPTQ/edit',
      isEnabled: true
    },
    {
      id: 4,
      topic: 'Rust Training  Tech Session 4',
      startdate: '21/08/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSfOUY8Jd8bhsWzHAt1f_d9B-ox8KB4L6uG8M1oQ3Js_wCIf6g/viewform',
      assignmentLink:
        'https://docs.google.com/forms/d/1z0-ATvNypya6jug4jiCs9sNbuSimQGuupZkG9snOQzk/edit#settings'
    },
    {
      id: 5,
      topic: 'Rust Training  Tech Session 5',
      startdate: '23/08/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSe9o0Rgp6pR5WOBFN3IYu-3Gm2JwwCf4O22FiuGFZXvmJ239Q/viewform',
      assignmentLink:
        'https://docs.google.com/forms/d/12KoyHuSghGiooYl2tepsRhf4bbiU1cJEs_d0TO1oN2Y/edit'
    },
    {
      id: 6,
      topic: 'Introduction to Frame Pallet Library',
      startdate: '28/08/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink: ''
      // quizLink:
      //   'https://docs.google.com/forms/d/e/1FAIpQLSdz_m15tVi_rCBms2OAU9AVczOFwFOwRNERWMtsnIHq0DZNXg/viewform'
    },
    {
      id: 7,
      topic: 'Developing a Custom Pallet',
      startdate: '31/08/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink: ''
      // quizLink:
      //   'https://docs.google.com/forms/d/e/1FAIpQLSe1uCc38DE2LRQwTg3WcgaghYodEJjZJCHm27EWsjmdtFkg9A/viewform'
    }
  ]

  const handleQuizLink = (quizLink) => {
    // swal({
    //   text: 'Start your quiz to complete for a seat at IBC Alt Hack Delhi',
    //   buttons: true
    // }).then(function (confirm) {
    //   if (confirm) {
    //     window.open(quizLink, '_blank')
    //   }
    // })

    window.open(quizLink, '_blank')
  }
  return (
    <div>
      <div className='quiz-page py-3'>
        <div className='card'>
          <div className='card-body px-3'>
            <div className='quiz-page__table'>
              <table className=''>
                <thead>
                  <tr>
                    {tableHeadsQuizzes.map((data, i) => {
                      return (
                        <th key={i} scope='col' className='right'>
                          {data}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {cepQuizzes.map((data, i) => {
                    const date = moment(data.startdate, 'DD/MM/YYYY')
                    const currentDate = moment()
                    const fomattedDate = date.format('ll')
                    //for time
                    const timze = 'h:mm A'
                    //replace it with quiz data if date are differnt
                    let startingTime = data?.startime || '20:00:00' // || data?.starttime
                    let endingTime = data?.endtime || '24:00:00' // ||data?.endtime

                    const currentTime = moment(currentDate, timze)
                    const starttime = moment(startingTime, timze)
                    const endtime = moment(endingTime, timze)

                    //check if current time lies between start and end time
                    const timeIsBetween = currentTime.isBetween(
                      starttime,
                      endtime
                    )
                    const isMatchingDate = date.isSame(currentDate, 'day')
                    const isBeforeDate = date.isBefore(currentDate, 'day')
                    return (
                      <tr key={i}>
                        <td className='' data-label={tableHeadsQuizzes[0]}>
                          {data.topic}
                        </td>

                        <td
                          className=' right overflow-text'
                          data-label={tableHeadsQuizzes[1]}
                        >
                          <span className='trx-id'>
                            {fomattedDate}
                            <br />
                            <small
                              style={{ fontSize: '85%' }}
                            >{`${starttime.format('h:mm A')} - ${endtime.format(
                              'h:mm A'
                            )} IST`}</small>
                          </span>
                        </td>
                        <td
                          className='right max-time'
                          data-label={tableHeadsQuizzes[2]}
                        >
                          <p
                            className='time-font text-underline click-link'
                            onClick={() => {
                              if (data?.quizLink) {
                                handleQuizLink(data?.quizLink)
                              }
                            }}
                            style={{
                              cursor: data?.quizLink ? 'pointer' : 'default',
                              textDecoration: data?.quizLink
                                ? 'underline'
                                : 'none'
                            }}
                          >
                            {data?.quizLink ? 'Click Here' : 'N/A'}
                          </p>
                        </td>
                        {/* Assignment Link */}
                        <td
                          className='right max-time mb-0'
                          data-label={tableHeadsQuizzes[3]}
                        >
                          <p
                            className='time-font text-underline click-link'
                            style={{
                              cursor: data?.assignmentLink
                                ? 'pointer'
                                : 'default',
                              textDecoration: data?.assignmentLink
                                ? 'underline'
                                : 'none'
                            }}
                            onClick={() => {
                              if (data?.assignmentLink) {
                                handleQuizLink(data?.assignmentLink)
                              }
                            }}
                          >
                            {data?.assignmentLink ? 'Click Here' : 'N/A'}
                          </p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CepQuizzes
