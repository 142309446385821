import React from 'react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { getProfilePercent } from '../API/userAPI'
import { logOut } from '../redux/loginLogout'
import { useDispatch } from 'react-redux'
import ProfileCard from '../components/ProfileCard'
import ProfileSetting from '../components/ProfileSetting'
import QrCard from '../components/QrCard'
import QuizAssesment from '../components/QuizAssesment'
import ProfileNavMobile from '../components/ProfileNavMobile'

const ProfilePage = () => {
  const [percent, setPrecentage] = React.useState(0)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getPercentage = async () => {
    try {
      const res = await getProfilePercent()
      const { data } = res

      setPrecentage(data?.data?.percent || 0)
    } catch (error) {
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }
    }
  }

  return (
    <div className='profile-page py-3'>
      {/* Top Profile */}

      <div className='show-desktop-only'>
        <div className=' top-profile-ui pb-4 '>
          <div className=''>
            <ProfileCard getPercentage={getPercentage} />
          </div>
          <div className=''>
            <QrCard />
          </div>
          <div className=''>
            <QuizAssesment />
          </div>
        </div>
      </div>
      {/* Show in mobile */}
      <div className='show-mobile-only d-md-block d-lg-none'>
        <ProfileCard type='transparent' getPercentage={getPercentage} />

        {/*  */}
        <ProfileNavMobile />
      </div>

      <ProfileSetting percent={percent} getPercentage={getPercentage} />
    </div>
  )
}

export default ProfilePage
