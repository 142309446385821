import React, { useState, useEffect, useRef } from 'react'
import { Country, State, City } from 'country-state-city'
import { toast } from 'react-toastify'
import swal from 'sweetalert'
import { useDispatch } from 'react-redux'
import PhoneInput from 'react-phone-input-2'

import { genders } from '../../../utils/constants'
import { getPersonalInfo, updatePersonalInfo } from '../../../API/userAPI'
import { getUserData } from '../../../utils/helper'
import { getRoles } from '../../../API/userAPI'
import Cookies from 'js-cookie'
import { logOut } from '../../../redux/loginLogout'
import { useNavigate } from 'react-router'

const PersnolInfo = ({ setTabActive, getPercentage }) => {
  const formRef = useRef()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [country, setCountry] = useState(null)
  const [countryState, setCountryState] = useState(null)
  const [stateCity, setStateCity] = useState(null)
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [roles, setRoles] = useState([])
  const [touched, setTouched] = useState(false)

  const countries = Country.getAllCountries().map((val) =>
    Object.assign({}, { label: val.name, value: val.isoCode })
  )

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    phoneNumber: '',
    country: '',
    state: '',
    role: '',
    city: ''
  })

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getPersonalInfo()
        const { data } = res
        // console.log('Persnol Info', data)
        setFormValues({
          firstName: data?.data[0]?.firstName || '',
          lastName: data?.data[0]?.lastName || '',
          email: data?.data[0]?.email || '',
          gender: data?.data[0]?.gender || '',
          phoneNumber: data?.data[0]?.phoneNumber || '',
          country: data?.data[0]?.country || '',
          state: data?.data[0]?.state || '',
          role: data?.data[0]?.roleId || '',
          city: data?.data[0]?.city || ''
        })
      } catch (error) {
        if (
          error?.response?.data?.message ===
            'Your session has expired. Please relogin.' ||
          error?.response?.data?.message === 'Invalid Token'
        ) {
          Cookies.remove('alt-hack-user')
          Cookies.remove('isUserLoggedIn')
          dispatch(logOut())

          navigate('/login')
        }
      }
    }

    getUser()
    fetchRoles()
  }, [])

  const fetchRoles = async () => {
    try {
      const res = await getRoles()

      const { data } = res
      let temp = []
      data?.data.forEach((role) => {
        temp.push({
          label: role.name,
          value: role.id
        })
      })

      setRoles([...temp])
    } catch (error) {
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }
    }
  }

  useEffect(() => {
    if (formValues.country && formValues.country !== '') {
      if (!country) {
        let cntry = countries.filter(
          (country) => formValues.country === country.label
        )

        setCountry(cntry[0]?.value)
      }

      let temp = []

      State.getStatesOfCountry(country).forEach((val) => {
        temp.push({ label: val.name, value: val.isoCode })
      })

      setStates([...temp])
    }
  }, [formValues?.country, country])

  useEffect(() => {
    if (!countryState) {
      let states = []

      State.getStatesOfCountry(country).forEach((val) => {
        states.push({ label: val.name, value: val.isoCode })
      })

      let state = states.filter((sta) => sta.label === formValues.state)

      setCountryState(state[0]?.value)
    }

    if (countryState && country) {
      let temp = []

      City.getCitiesOfState(country, countryState).forEach((val) => {
        temp.push({ label: val.name, value: val.name })
      })

      setCities([...temp])
    }
  }, [formValues?.state, countryState, country])

  useEffect(() => {
    // Loop over them and prevent submission

    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  const handleChange = (e) => {
    setTouched(true)
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const updateUserInfo = async (e) => {
    e.preventDefault()

    if (formValues.gender === '') {
      swal('Please select gender.', '', 'warning')
      return false
    }

    if (formValues?.phoneNumber?.length < 10) {
      swal('Please enter a valid phone number.', '', 'warning')
      return false
    }

    if (!touched) {
      setTabActive(1)
      return false
    }

    try {
      const res = await updatePersonalInfo({ ...formValues })

      getUserData(dispatch)
      setTabActive(1)
      getPercentage()
      swal('Personal Info updated successfully.', '', 'success')
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  return (
    <div className='multistep-form'>
      <div className=' py-2'>
        <div className='card card-form-bg border-0 shadow-none'>
          <div className='card-body '>
            <form
              className='row g-3 needs-validation'
              noValidate
              ref={formRef}
              onSubmit={updateUserInfo}
            >
              <div className='col-lg-12'>
                <h6>Gender</h6>

                {genders.map((gender, index) => {
                  return (
                    <button
                      type='button'
                      className={`btn btn-outline-secondary rounded-pill me-3 ${
                        formValues?.gender.toLowerCase() ===
                        gender.toLowerCase()
                          ? 'active'
                          : null
                      }`}
                      key={index}
                      onClick={(e) => {
                        e.preventDefault()
                        setTouched(true)
                        setFormValues((prevState) => ({
                          ...prevState,
                          gender
                        }))
                      }}
                    >
                      {gender}
                    </button>
                  )
                })}
              </div>
              <div className='col-md-6'>
                <label htmlFor='validationCustom01' className='form-label'>
                  First Name *
                </label>

                <input
                  type='text'
                  className='form-control'
                  placeholder='First Name'
                  id='validationCustom01'
                  required
                  name='firstName'
                  value={formValues.firstName}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your First Name!
                </div>
              </div>
              <div className='col-md-6'>
                <label htmlFor='validationCustom02' className='form-label'>
                  Last Name *
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Last Name'
                  id='validationCustom02'
                  required
                  name='lastName'
                  value={formValues.lastName}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your Last Name!
                </div>
              </div>
              <div className='col-md-12'>
                <label htmlFor='validationCustom03' className='form-label'>
                  Email *
                </label>

                <input
                  type='email'
                  className='form-control'
                  placeholder='akash.email@gmail.com'
                  id='validationCustom03'
                  disabled
                  name='email'
                  value={formValues.email}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your Email Id!
                </div>
              </div>
              <div className='col-md-6'>
                <label htmlFor='validationCustom04' className='form-label'>
                  Phone Number *
                </label>
                <PhoneInput
                  country={'in'}
                  value={formValues.phoneNumber}
                  className='phone-input form-control'
                  onChange={(phone) => {
                    setTouched(true)
                    setFormValues((prevFormValues) => ({
                      ...prevFormValues,
                      phoneNumber: phone
                    }))
                  }}
                  inputClass='form-control'
                  inputProps={{
                    required: true
                  }}
                  required
                />
                <div className='invalid-feedback'>
                  Please input your Contact Number!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='validationCustom05' className='form-label'>
                  What best describes you? *
                </label>
                <select
                  className='form-select form-select-lg mb-2'
                  aria-label='.form-select-lg example'
                  id='validationCustom05'
                  required
                  name='role'
                  value={formValues.role}
                  onChange={handleChange}
                >
                  {roles &&
                    roles.map((role, index) => {
                      return (
                        <option
                          value={role.value}
                          selected={role.value === formValues.role}
                          key={index}
                        >
                          {role.label}
                        </option>
                      )
                    })}
                </select>
                <div className='invalid-feedback'>
                  Please select what you are!
                </div>
              </div>
              <div className='col-md-6'>
                <label htmlFor='country' className='form-label'>
                  Country *
                </label>
                <select
                  className='form-select mb-2 form-control'
                  required
                  id='country'
                  value={formValues?.country}
                  defaultValue={country}
                  onChange={(e) => {
                    e.preventDefault()
                    setTouched(true)
                    const selectedOption =
                      e.target.options[e.target.selectedIndex]
                    const selectedCountryCode =
                      selectedOption.getAttribute('data-country-code')

                    setCountry(selectedCountryCode)
                    setFormValues((prev) => ({
                      ...prev,
                      country: e.target.value,
                      state: '',
                      city: ''
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    Select Country
                  </option>
                  {countries.map((cntry, i) => (
                    <option
                      key={i}
                      value={cntry.label}
                      data-country-code={cntry?.value}
                      selected={formValues.country === cntry.label}
                    >
                      {cntry.label}
                    </option>
                  ))}
                </select>
                <div className='invalid-feedback'>
                  Please input your Country Name!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='state' className='form-label'>
                  State *
                </label>
                <select
                  className='form-select form-select-lg mb-2 form-control'
                  aria-label='.form-select-lg'
                  id='state'
                  required
                  value={formValues.state}
                  defaultValue={formValues?.state}
                  onChange={(e) => {
                    e.preventDefault()
                    setTouched(true)
                    const selectedOption =
                      e.target.options[e.target.selectedIndex]
                    const selectedStateCode =
                      selectedOption.getAttribute('data-state-code')

                    setCountryState(selectedStateCode)
                    setFormValues((prev) => ({
                      ...prev,
                      state: e.target.value,
                      city: ''
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    State
                  </option>

                  {states.map((state, i) => (
                    <option
                      key={i}
                      value={state.label}
                      data-state-code={state.value}
                    >
                      {state.label}
                    </option>
                  ))}
                </select>
                <div className='invalid-feedback'>
                  Please input your State Name!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='city' className='form-label'>
                  City *
                </label>

                <select
                  className='form-select form-select-lg mb-2 form-control'
                  aria-label='.form-select-lg example'
                  required
                  id='city'
                  value={formValues.city}
                  defaultValue={formValues?.city}
                  name='city'
                  onChange={(e) => {
                    e.preventDefault()
                    setTouched(true)
                    setFormValues((prev) => ({
                      ...prev,
                      city: e.target.value
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    City
                  </option>

                  {cities.map((city, i) => (
                    <option key={i} value={city.label}>
                      {city.label}
                    </option>
                  ))}
                </select>
                <div className='invalid-feedback'>
                  Please input your City Name!
                </div>
              </div>
              <div className='col-md-12'>
                <button
                  type='submit'
                  className='btn btn-blue round-0 float-end'
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersnolInfo
