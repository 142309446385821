import axios from 'axios'
import Cookies from 'js-cookie'

let instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 100000
})

instance.interceptors.request.use((config) => {
  const user = Cookies.get('alt-hack-user')
    ? JSON.parse(Cookies.get('alt-hack-user'))
    : ''

  let token = user?.token || ''

  config.headers.Authorization = `Bearer ${token}`
  return config
})

const getRequest = (API, body) => instance.get(API, body)

const postRequest = (API, body) => instance.post(API, body)

const putRequest = (API, body) => instance.put(API, body)

const deleteRequest = (API, body) => instance.delete(API, body)

export { getRequest, postRequest, putRequest, deleteRequest }

