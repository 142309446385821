import { postRequest, getRequest, putRequest } from '.'

const userLogin = async (body) => {
  const res = await postRequest('/altHackLogin', body)
  return res
}

const userSignUp = async (body) => {
  const res = await postRequest('/altHackSignup', body)
  return res
}

const verifyUser = async (token) => {
  const res = await getRequest(`/verifyEmail/${token}`)
  return res
}

const sendForgetEmail = async (body) => {
  const res = await postRequest(
    `forgotPassword?isRequestFromAltHackWebsite=true`,
    body
  )

  return res
}

const verifyForgetToken = async (token) => {
  const res = await getRequest(`/verifyForgotPasswordToken/${token}`)

  return res
}

const updateForgetPassword = async (userId, token, body) => {
  const res = await putRequest(`/updatePassword/${userId}/${token}`, body)

  return res
}

export {
  userLogin,
  userSignUp,
  verifyUser,
  sendForgetEmail,
  verifyForgetToken,
  updateForgetPassword
}

