import { deleteRequest, getRequest, postRequest, putRequest } from '.'
import Cookies from 'js-cookie'

const searchTeamMember = async (userId) => {
  const res = await getRequest(`/searchIbcUserId/${userId}`)
  return res
}

const getTeamMembers = async (userId, eventId) => {
  const res = await getRequest(`/teamMembers/${userId}/${eventId}`)
  return res
}

const addTeamMember = async (userId) => {
  const res = await putRequest(`/teamMembers/${userId}`)
  return res
}

const deleteTeamMember = async (ibcUserId, teamUniqueId) => {
  const res = await deleteRequest(`/teamMembers/${ibcUserId}/${teamUniqueId}`)
  return res
}

const createTeam = async (body) => {
  const res = await postRequest('/team/createTeam', body)
  return res
}

const updateTeam = async (body) => {
  const res = await postRequest('/team/updateTeamName', body)
  return res
}

const getTeam = async (eventId) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = await getRequest(`/team/getTeam/${user.id}/${eventId}`)
  return res
}

const getTeamInvites = async (eventId) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = await getRequest(`/team/getTeamInvitations/${user.id}/${eventId}`)
  return res
}

const acceptTeamInvite = async (invitedUserId, eventId) => {
  const res = await getRequest(
    `team/acceptTeamInvitation/${invitedUserId}/${eventId}`
  )

  return res
}

const rejectTeamInvite = async (invitedUserId, eventId) => {
  const res = await deleteRequest(
    `team/rejectTeamInvitation/${invitedUserId}/${eventId}`
  )

  return res
}

const exitFromTeam = async (ibcUserId, teamUniqueId) => {
  const res = deleteRequest(`team/exitFromTeam/${ibcUserId}/${teamUniqueId}`)
  return res
}

const sendTeamInvite = async (body) => {
  const res = postRequest('team/sendTeamInvitation', body)
  return res
}

const sendTeamIdeas = async (body) => {
  const res = postRequest(`myIdeas`, body)
  return res
}

const getTeamIdeas = async (eventId) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = getRequest(`myIdeas/${user.id}/${eventId}`)

  return res
}

export {
  createTeam,
  getTeam,
  getTeamInvites,
  acceptTeamInvite,
  updateTeam,
  exitFromTeam,
  rejectTeamInvite,
  sendTeamInvite,
  getTeamIdeas,
  sendTeamIdeas
}

