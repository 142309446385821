import React from 'react'
import { Accordion } from 'react-bootstrap'

const CepEventSchedule = () => {
  const eventData = [
    {
      id: 1,
      module: 'Module 1',
      title: 'Orientation Sessions',
      timing: '7th Aug to 9th Aug, 2023',

      sessions: [
        {
          id: 1,
          titles: [
            'Introduction to IBC CEP',
            'Introduction to Blockchain and Web3'
          ],
          date: '7th August',
          time: '7PM - 9PM',
          url: 'https://youtu.be/yxTZahsEqfw',
          ppt: ''
        },
        {
          id: 2,
          titles: ['Web2 to Web3 Shift'],
          date: '8th August',
          time: '7PM - 9PM',
          url: 'https://youtu.be/VdkeG6CTnuw',
          ppt: ''
        },
        {
          id: 3,
          titles: [
            'Team Formation/ Team Allocation + Business Ideation and MVP Scoping'
          ],
          date: '9th August',
          time: '7PM - 8PM',
          url: 'https://youtu.be/5HwUx5Fhz_I',
          ppt: ''
        }
      ]
    },
    {
      id: 2,
      module: 'Module 2',
      title: 'Technical Sessions',
      timing: '11th August to 31st August, 2023',
      sessions: [
        {
          id: 1,
          titles: [
            <>
              <p className='fs-6 fw-semibold mb-0'>Introduction</p>
              <p className='fs-6'>
                {' '}
                Rust Hello World Using Rust Compiler and Cargo
              </p>
              <p className='fs-6'></p>
              <p className='fs-6'></p>
              <p className='fw-semibold'>Data Types</p>
              <p className='fw-semibold'>Scalar Types</p>
              <p className='mb-0 fw-semibold'> Compound Types</p>
              <p className='mb-0 ms-1 fs-14'>- Tuples</p>
              <p className='ms-1 fs-14'>- Arrays</p>
              <p className='fs-6 mb-0 fw-semibold'>Functions</p>
              <p className='mb-0 ms-1 fs-14'>- Function Declaration</p>
              <p className='ms-1 fs-14'>- Parameters and Return Values</p>
              <p className='fs-6 fw-semibold mb-0'>Control Flow</p>
              <p className='fs-14 mb-0'>If expression</p>
              <p className='fs-14 mb-0'>If else</p>
              <p className='fs-14 mb-0'>If-let</p>
              <p className='mb-0 ms-1 fs-14'>- loop</p>
              <p className='mb-0 ms-1 fs-14'>- while</p>
              <p className='ms-1 fs-14'>- for</p>
              <p className='mb-0 fs-6 fw-semibold'>
                Variables, Constants and Immutability
              </p>
            </>
          ],
          date: '11th August',
          time: '7PM - 8:15PM',
          url: 'https://youtu.be/Pw1Up1x_vlQ',
          ppt: 'https://docs.google.com/presentation/d/1n4YJmtDcWi0mRPqeM-_zklIRHrGxyV_g/edit?usp=sharing&ouid=114941985286666395977&rtpof=true&sd=true',
          isTech: true
        },
        {
          id: 2,
          titles: [
            <>
              <p className='mb-0 fw-semibold'>Ownership</p>{' '}
              <p className='mb-0 ms-1 fs-14'>- 3 Rules of Ownership</p>{' '}
              <p className='mb-0 ms-1 fs-14'>- Stack and Heap</p>
              <p className='ms-1 fs-14'>
                - How Ownership Works Under the Hood The String Type
              </p>
              <p className='fs-6 mb-0 fw-semibold'>References and Borrowing</p>
              <p className='mb-0 ms-1 fs-14'> - Immutable References</p>
              <p className='mb-0 ms-1 fs-14'>- Mutable References</p>{' '}
              <p className='ms-1 fs-14'>
                - Reference Values and Reference Types
              </p>
              <p className='mb-0 fs-6 fw-semibold'>The Slice Type</p>
            </>
          ],
          date: '16th August',
          time: '7PM - 8:15PM',
          url: 'https://youtu.be/1Mf2Vay09ZM',
          ppt: 'https://docs.google.com/presentation/d/1RXPFxBHwPGh4jj4ubtPb-Dgzi7uIc-Gf/edit?usp=drivesdk&ouid=117411319190912896263&rtpof=true&sd=true',
          isTech: true
        },
        {
          id: 3,
          titles: [
            <>
              <p className='mb-0 fs-6 fw-semibold'>Structs</p>
              <p className='mb-0 ms-1 fs-14'>
                - Struct Definition and Implementation
              </p>
              <p className='mb-0 ms-1 fs-14'>- Struct Fields</p>
              <p className='ms-1 fs-14'>
                - Struct Methods and Associated Functions
              </p>
              <p className='mb-0 fs-6 fw-semibold'>Enums</p>
              <p className='ms-1 fs-14 mb-0'>- Enum Definition</p>
              <p className='mb-0 ms-1 fs-14'>- Enum Variants</p>
              <p className='mb-0 ms-1 fs-14'>
                - Methods and Associated Functions for Enums
              </p>
              <p className='mb-0 ms-1 fs-14'>
                {' '}
                - Common Enums From Standard Library
              </p>
            </>
          ],
          date: '18th August',
          time: '7PM - 8:15PM',
          url: 'https://youtu.be/78a6PweoZfE',
          ppt: 'https://docs.google.com/presentation/d/1Rf609ahOpF9ryL9L4qpeOkOP5nO6MzcE/edit?usp=drivesdk&ouid=117411319190912896263&rtpof=true&sd=true',
          isTech: true
        },
        {
          id: 4,
          titles: [
            <>
              <p className='mb-0 fs-6 fw-semibold'>Common Collections</p>
              <p className='mb-0 ms-1 fs-14'>- Vec</p>
              <p className='mb-0 ms-1 fs-14'>- String</p>
              <p className='ms-1 fs-14'>- Hash Map</p>
              <p className='mb-0 fs-6 fw-semibold'>Error Handling</p>
              <p className='mb-0 ms-1 fs-14'>- Error with panic </p>
              <p className='mb-0 ms-1 fs-14'>- Error with Result</p>
            </>
          ],
          date: '21st August',
          time: '7PM - 8:15PM',
          url: 'https://youtu.be/Bq68VcCcG7U',
          ppt: 'https://docs.google.com/presentation/d/1Bk-MSQLO9dx63ETyj3D35Ht8Ogfn3CtI/edit?usp=drivesdk&ouid=117411319190912896263&rtpof=true&sd=true',
          isTech: true
        },
        {
          id: 5,
          titles: [
            <>
              <p className='mb-0 fs-6 fw-semibold'>Generic Types</p>
              <p className='mb-0 ms-1 fs-14'>- In function</p>
              <p className='ms-1 fs-14'>- In Enum Traits</p>
              <p className='fs-6 fw-semibold mb-0'>Traits</p>
              <p className='mb-0 ms-1 fs-14'>- Trait Definition</p>
              <p className='mb-0 ms-1 fs-14'>- Trait Implementation</p>
              <p className='mb-0 ms-1 fs-14'>- Default Behaviour</p>
            </>
          ],
          date: '23rd August',
          time: '7PM - 8:15PM',
          url: 'https://youtu.be/Le9E6hA7j0M',
          ppt: 'https://docs.google.com/presentation/d/1NHn7vlMTlxMvalcJHyhiGQFmVy6WbPyL/edit?usp=drivesdk&ouid=117411319190912896263&rtpof=true&sd=true',
          isTech: true
        },
        {
          id: 6,
          titles: [
            <p className='fs-6 fw-semibold mb-0'>
              Introduction to Polkadot, Substrate Framework
            </p>
          ],
          date: '25th August',
          time: '7PM - 8PM',
          url: 'https://youtu.be/1E_R-55XrQU',
          ppt: 'https://docs.google.com/presentation/d/1yqETjgP0qGTMqR9oadciVONxcrLqs055/edit#slide=id.g1954f7ea877_0_0 ',
          isWorkShop: true
        },
        {
          id: 6,
          titles: [
            <>
              <p className='fs-6 fw-semibold mb-0'>
                Introduction to Frame Pallet Library
              </p>
              <p className='fs-14 mb-0'>
                Building blocks in the Frame Pallet Library
              </p>
              <p className='fs-14 mb-0'>Adding a Pallet to our Blockchain</p>
              <p className='fs-14 mb-0'>Testing the Pallet from the UI</p>
            </>
          ],
          date: '28th August',
          time: '7PM - 8:15PM',
          url: 'https://youtu.be/jrPClMLXDy0',
          ppt: '',
          isTech: true
        },
        {
          id: 7,
          titles: [
            <div>
              <p className='fs-6 fw-semibold mb-0'>
                Developing a Custom Pallet
              </p>
              <p className='fs-14 mb-0'>
                Adding the Custom Pallet to our Blockchain
              </p>
              <p className='fs-14 mb-0'>
                Testing our Custom Pallet from the UI
              </p>
            </div>
          ],
          date: '31st August',
          time: '7PM - 8:15PM',
          url: 'https://youtu.be/IcA4ydG2SWQ',
          ppt: '',
          isTech: true
        }
        // {
        //   id: 8,
        //   titles: ['Extra/ buffer sessions/ workshops, if necesarry'],
        //   date: '28th August',
        //   time: '7PM - 9PM',
        //   url: '/'
        // }
      ]
    },
    {
      id: 3,
      module: 'Module 3',
      title: 'IBC-CEP August, 2023 (Building Sessions) (1st Sept - 30th Sept)			',
      timing: '1st September to 30th September, 2023',
      sessions: [
        {
          id: 1,
          titles: ['Aggregate all the resources needed for your projects'],
          date: '8 days',
          time: '',
          url: '',
          ppt: '',
          isStage: true
        },
        {
          id: 2,
          titles: ['Implementation of pallet or smart contract'],
          date: '8 days',
          time: '',
          url: '',
          ppt: '',
          isStage: true
        },
        {
          id: 3,
          titles: [
            'Implement all the tools required (like decentralized storage, oracle etc. )'
          ],
          date: '14 days',
          time: '',
          url: '',
          ppt: '',
          isStage: true
        }
      ]
    }
  ]

  return (
    <div className='py-3 '>
      <div className='card py-2 px-3 event-schedule'>
        <Accordion defaultActiveKey={0} className='acc-event'>
          {eventData.map((event, i) => {
            return (
              <Accordion.Item eventKey={i} key={i}>
                <Accordion.Header className='accordion-header'>
                  <p className='accordion-title mb-0 d-flex flex-column'>
                    <span>
                      {event.module} {event.title}
                    </span>
                    <span className='fw-light d-inline-block mt-1'>
                      <small>{event.timing}</small>
                    </span>
                  </p>
                </Accordion.Header>

                <Accordion.Body>
                  {event?.sessions && event.sessions.length > 0 ? (
                    <TableData sessions={event?.sessions} />
                  ) : (
                    <p className='text-center'>No Session Found</p>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}

export default React.memo(CepEventSchedule)

const TableData = ({ sessions }) => {
  const tableHeadsQuizzes = [
    'Sessions',
    'Description',
    'Date',
    'Time',
    'Session Link',
    'PPT Link'
  ]

  return (
    <div className='event-page__table'>
      <table>
        <thead>
          <tr>
            {tableHeadsQuizzes.map((data, i) => {
              return (
                <th key={i} scope='col' className='right'>
                  {data}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {sessions?.map((session, i) => {
            return (
              <TableBody
                data={session}
                key={i}
                tableHeadsQuizzes={tableHeadsQuizzes}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const TableBody = ({ data, tableHeadsQuizzes }) => {
  const handlePPTlink = (link) => {
    window.open(link, '_blank')
  }
  return (
    <tr>
      <td className='' data-label={tableHeadsQuizzes[0]}>
        {data?.isWorkShop
          ? 'Workshop'
          : data?.isTech
          ? `Tech Session ${data?.id}`
          : data?.isStage
          ? `Stage ${data?.id}`
          : `Session ${data?.id}`}
      </td>
      <td className=' right overflow-text' data-label={tableHeadsQuizzes[1]}>
        {data?.titles.map((title, i) => {
          return (
            <React.Fragment key={i}>
              <span className='trx-id'>{title}</span> <br />
            </React.Fragment>
          )
        })}
      </td>
      <td className=' right overflow-text' data-label={tableHeadsQuizzes[2]}>
        <span className='trx-id'>{data?.date}</span>
      </td>
      <td className=' right overflow-text' data-label={tableHeadsQuizzes[3]}>
        <span className='trx-id'>
          <small>{data?.time}</small>
          {/* Sponsor Track Briefing/ Team Formation/ Team Allocation */}
        </span>
      </td>
      <td className='right max-time' data-label={tableHeadsQuizzes[4]}>
        <a
          target='_blank'
          rel='noreferrer'
          href={
            data?.url ||
            'https://zoom.us/webinar/register/WN_BVqy8AUFS4u6V_3X-b1P2g#/registration'
          }
          className='time-font'
        >
          Click Here
        </a>
      </td>
      {/* For PPT */}
      <td className='right max-time' data-label={tableHeadsQuizzes[5]}>
        <p
          // href={data?.ppt || ''}
          className='time-font text-underline click-link'
          onClick={() => {
            if (data?.ppt) {
              handlePPTlink(data?.ppt)
            }
          }}
          style={{
            cursor: data?.ppt ? 'pointer' : 'not-allowed'
          }}
        >
          Click Here
        </p>
      </td>
    </tr>
  )
}
