import { getRequest, postRequest } from '.'
import Cookies from 'js-cookie'

const getProgress = async ({ courseId, subjectId, topicId, youtubeId }) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = await getRequest(
    `/lmsVideoActivity/${user?.id}?courseId=${courseId}&subjectId=${subjectId}&topicId=${topicId}&youtubeId=${youtubeId}`
  )
  return res
}

const postProgress = async (body) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = await postRequest('/lmsVideoActivity', {
    ...body,
    userId: user?.id
  })
  return res
}

const getUserCourseInterest = async (courseId) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = await getRequest(
    `/lmsCourseInterests/${user.id}?courseId=${courseId}`
  )
  return res
}

const postUserCourseInterest = async (body) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = postRequest('/lmsCourseInterests', {
    ...body,
    userId: user?.id
  })
  return res
}

export {
  getProgress,
  postProgress,
  postUserCourseInterest,
  getUserCourseInterest
}

