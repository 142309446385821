import React, { useState } from 'react'
import TeamFormation from '../components/views/AltHack/TeamFormation'
import IdeaSubmission from '../components/views/AltHack/IdeaSubmission'
import ProgramSelection from '../components/views/AltHack/ProgramSelection'
import MyIdeas from '../components/views/AltHack/MyIdeas'
import { useLocation } from 'react-router'

const Team = () => {
  const location = useLocation()

  const [tabActice, setTabActive] = useState(
    location?.pathname?.includes('cep') ? 0 : 1
  )

  const teamFormSteps = [
    {
      id: 0,
      title: 'My Ideas',
      page: <MyIdeas setTabActive={setTabActive} />
    },
    {
      id: 1,
      title: 'Team Formation',
      page: <TeamFormation setTabActive={setTabActive} />
    },
    {
      id: 2,
      title: 'Program Selection',
      page: <ProgramSelection setTabActive={setTabActive} />
    },
    {
      id: 3,
      title: 'Idea Submission',
      page: <IdeaSubmission setTabActive={setTabActive} />
    }
  ]

  const teamSelectionCep = [
    {
      id: 0,
      title: 'Team Formation',
      page: <TeamFormation setTabActive={setTabActive} />
    },
    {
      id: 1,
      title: 'Program Selection',
      page: <ProgramSelection setTabActive={setTabActive} />
    },
    {
      id: 2,
      title: 'Idea Submission',
      page: <IdeaSubmission setTabActive={setTabActive} />
    }
  ]

  console.log('location', location.pathname.includes('cep'))

  return (
    <div className='profile-setting py-3'>
      <div className='card'>
        <div className='card-body'>
          {/* <h4>Profile Settings </h4> */}

          <div className='profile-tabs pt-3'>
            <div className='profile-tabs__bar'>
              <div className='button-mobile-nav'>
                <div className='btn-scrolls'>
                  {location?.pathname?.includes('cep')
                    ? teamSelectionCep &&
                      teamSelectionCep.map((tab, i) => {
                        return (
                          <button
                            className={`btn btn-link tabs-btn ${
                              tabActice === i ? 'active' : ''
                            }`}
                            key={i}
                            onClick={() => setTabActive(i)}
                          >
                            {tab.title}
                          </button>
                        )
                      })
                    : teamFormSteps &&
                      teamFormSteps.map((tab, i) => {
                        return (
                          <button
                            className={`btn btn-link tabs-btn ${
                              tabActice === i ? 'active' : ''
                            }`}
                            key={i}
                            onClick={() => setTabActive(i)}
                          >
                            {tab.title}
                          </button>
                        )
                      })}

                  {}
                </div>
              </div>
            </div>
            {/* Profile tab component */}

            {location?.pathname?.includes('cep')
              ? teamSelectionCep[tabActice].page
              : teamFormSteps[tabActice].page}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team

