import React from 'react'
import { images } from '../../Pictures/images'

const WhyAttendCarousel = () => {
  return (
    <div
      id='carouselExampleDark2'
      className='carousel carousel-dark slide'
      data-bs-ride='carousel'
    >
      <div className='carousel-indicators'>
        <button
          type='button'
          data-bs-target='#carouselExampleDark2'
          data-bs-slide-to='0'
          className='active'
          aria-current='true'
          aria-label='Slide 1'
        ></button>
        <button
          type='button'
          data-bs-target='#carouselExampleDark2'
          data-bs-slide-to='1'
          aria-label='Slide 2'
        ></button>
      </div>
      <div className='carousel-inner py-3'>
        <div className='carousel-item active' data-bs-interval='5000'>
          <div className='row'>
            <div className='col-md-12'>
              <img
                src={images.whyAttend1Slider}
                className='img-fluid '
                loading='lazy'
                alt='...'
              />
            </div>
          </div>
        </div>
        <div className='carousel-item' data-bs-interval='5000'>
          <div className='row'>
            <div className='col-md-12'>
              <img
                src={images.whyAttend2Slider}
                className='img-fluid'
                loading='lazy'
                alt='img'
              />
            </div>
          </div>
        </div>
      </div>

      {/* <button
        className='carousel-control-prev'
        type='button'
        data-bs-target='#carouselExampleDark2'
        data-bs-slide='prev'
      >
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Previous</span>
      </button>
      <button
        className='carousel-control-next'
        type='button'
        data-bs-target='#carouselExampleDark2'
        data-bs-slide='next'
      >
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Next</span>
      </button> */}
    </div>
  )
}

export default WhyAttendCarousel
