import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import YouTube from 'react-youtube'
import Cookies from 'js-cookie'

import { learningData } from '../../../utils/constants'
import { getProgress, postProgress } from '../../../API/trackingAPI'
import swal from 'sweetalert'
import { useDispatch } from 'react-redux'
import { logOut } from '../../../redux/loginLogout'
import { watch } from 'fs'
import { ProgressBar } from 'react-bootstrap'

const TopicLanding = () => {
  const [link, setLink] = useState('')

  const ytRef = useRef(null)

  const params = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [courseTitle, setCourseTitle] = useState('')
  const [subjectTitle, setSubjectTitle] = useState('')
  const [materialTitle, setMaterialTitle] = useState('')
  const [eventVideo, setEventVideo] = useState(null)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [totalDuration, setTotalDuration] = useState(0)
  const [isCompleted, setIsCompleted] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isAlreadyWatched, setIsAlreadyWatched] = useState(false)

  const [watchTime, setWatchTime] = useState(0)

  useEffect(() => {
    if (link && link !== '') {
      fetchVideoTime()
    }
  }, [link])

  useEffect(() => {
    if (params?.courseId && params?.subjectId) {
      const { courseId, subjectId, materialId } = params
      const courseSubs = learningData.filter(
        (data) => data?.courseId === parseInt(courseId)
      )
      setCourseTitle(courseSubs[0]?.courseTitle)
      const subjectTopics = courseSubs[0]?.courseSubjects?.filter(
        (data) => data?.subjectId === parseInt(subjectId)
      )

      setSubjectTitle(subjectTopics[0]?.subjectTitle)

      const materialData = subjectTopics[0]?.subjectMaterial?.filter(
        (data) => data?.materialId === parseInt(materialId)
      )

      setMaterialTitle(materialData[0]?.materialTitle)
      setLink(materialData[0]?.materialLink)
    }
  }, [])

  const fetchVideoTime = async () => {
    const { courseId, subjectId, materialId } = params

    try {
      const res = await getProgress({
        courseId,
        subjectId,
        topicId: materialId,
        youtubeId: link.split('/')[link.split('/').length - 1]
      })

      const { data } = res

      if (data?.data?.length !== 0 && data?.data[0]?.lastSeenTime) {
        setWatchTime(Math.floor(data?.data[0]?.lastSeenTime / (60 * 1000)))
        setIsAlreadyWatched(true)
      } else {
        setWatchTime(0)
      }
      setIsLoading(false)
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error
      swal(
        `${response?.data?.errors?.message || 'Unable to Update.'}`,
        '',
        'error'
      )
    }
  }

  const saveVideoProgress = async (videoWatchTime = watchTime) => {
    const { courseId, subjectId, materialId } = params

    try {
      const res = await postProgress({
        courseId: parseInt(courseId),
        subjectId: parseInt(subjectId),
        topicId: parseInt(materialId),
        youtubeId: link.split('/')[link.split('/').length - 1],
        lastSeenTime: Math.floor(videoWatchTime * 60 * 1000, 2)
      })
    } catch (error) {
      debugger
    }
  }

  const checkElapsedTime = (e) => {
    const duration = e.target.getDuration()
    setTotalDuration(duration)

    const currentTime = e.target.getCurrentTime()

    setWatchTime(currentTime)

    if (currentTime / duration > 0.95) {
      setWatchTime(currentTime)
      setIsCompleted(true)
      saveVideoProgress(currentTime)
    }
  }

  // const secondsToHms = (d) => {
  //   d = Number(d)

  //   var h = Math.floor(d / 3600)
  //   var m = Math.floor((d % 3600) / 60)
  //   var s = Math.floor((d % 3600) % 60)

  //   return (
  //     ('0' + h).slice(-2) +
  //     ':' +
  //     ('0' + m).slice(-2) +
  //     ':' +
  //     ('0' + s).slice(-2)
  //   )
  // }

  useEffect(() => {
    if (!isVideoPlaying && isCompleted === null) return
    let timerid
    if (!isCompleted) {
      timerid = setInterval(() => {
        const currentTime = eventVideo.target.getCurrentTime()

        setWatchTime(Math.floor(currentTime, 2))
        saveVideoProgress(Math.floor(currentTime, 2))
      }, 3 * 1000)
    }

    return () => {
      clearInterval(timerid)
    }
  }, [isVideoPlaying, isCompleted])

  const opts = {
    width: '100%',
    height: '450',
    id: 'youtube-frame',
    title: 'YouTube video player',
    frameBorder: '0',
    playerVars: {
      autoplay: 1,
      allowFullScreen: 1,
      'picture-in-picture': 1,
      accelerometer: 1,
      rel: 0,
      // controls: 0,
      origin: window.location.origin
    }
  }

  return (
    <div className='learning-page'>
      <p className='fs-14 ms-2'>
        <Link to='/dashboard/learn/courses' className='course-nav-link'>
          Courses
        </Link>{' '}
        {'> '}
        <Link
          to={`/dashboard/learn/courses/${params?.courseId}`}
          className='course-nav-link'
        >
          {courseTitle}
        </Link>
        {' > '}
        {subjectTitle} {' >'} {materialTitle}
      </p>
      <h3
        className='fw-semibold fs-4 mb-4 ms-2'
        style={{
          color: '#121212'
        }}
      >
        {materialTitle}
      </h3>
      {isLoading ? (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            height: '60vh'
          }}
        >
          <div class='spinner-border text-primary' role='status'>
            <span class='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className='d-flex align-items-center'>
            <p className='mb-0 me-3'>Progress</p>
            <ProgressBar
              striped
              variant='success'
              now={isCompleted ? totalDuration : watchTime}
              min={0}
              max={totalDuration === 0 ? 100 : totalDuration}
              className='w-100'
              style={{
                height: '20px'
              }}
            />
            <p className='mb-0 ms-3'>
              {isCompleted
                ? '100'
                : watchTime
                ? totalDuration === 0
                  ? 100
                  : Math.floor((watchTime / totalDuration) * 100, 2)
                : 0}
              %
            </p>
          </div>

          <div className='video-iframe'>
            {link && link !== '' ? (
              <YouTube
                ref={ytRef}
                videoId={link.split('/')[link.split('/').length - 1]}
                id='youtube-frame'
                className='iframe-yt'
                iframeClassName='iframe-yt'
                style={{
                  margin: '1rem auto 0',
                  display: 'block',
                  width: '100%'
                }}
                title='Youtube video name'
                loading='lazy'
                opts={opts}
                onReady={(e) => {
                  if (isAlreadyWatched) {
                    const duration = e.target.getDuration()
                    if (watch / duration > 0.95) {
                      setWatchTime(Math.floor(duration, 2))
                      setIsCompleted(true)
                    }

                    e.target.seekTo(watchTime, true)
                  }
                }}
                onPlay={(e) => {
                  setIsVideoPlaying(true)
                  setEventVideo(e)
                }}
                onPause={() => {
                  setIsVideoPlaying(false)
                  // if (!isCompleted) {
                  //   saveVideoProgress()
                  // }
                }}
                onStateChange={(e) => {
                  checkElapsedTime(e)
                }}
              />
            ) : (
              <div className='d-flex align-items-center justify-content-center mt-5'>
                <h2 className='mt-5'>No content to show</h2>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default TopicLanding

