import Cookies from 'js-cookie'
import { Outlet, Navigate } from 'react-router'

const PrivateRoute = () => {
  const isLoggedIn = Cookies.get('isUserLoggedIn') ? true : false

  return isLoggedIn ? <Outlet /> : <Navigate to='/login' />
}

export default PrivateRoute

