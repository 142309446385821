import React, { useState } from 'react'
import Footer from '../components/Footer'

const Faq = () => {
  const [activeFaq, setActiveFaq] = useState(1)
  // var singupLink = window.location.origin + '/signup'

  const faqs = [
    {
      id: 1,
      question:
        ' What is Digital India ALT Hack, and how does it differ from other hackathons?',
      answer: (
        <>
          <p>
            Digital India ALT Hack is an innovative, full cycle 8 day education
            bootcamp with the objective of transitioning the student developer
            community seamlessly into Web3. The Alt Hacks are conducted in
            collaboration with Indian governments both at the state and center
            levels, Government Skill Boards and Engineering colleges.
          </p>
          <p>
            The program is well curated and involves orientation (where students
            learn fundamentals of blockchain and Web3), Education & learning
            session (by expert trainers) and Ideation (where students are
            trained on ideation process and then subsequently present their Web3
            ideas and implementation strategy before a jury). 3 winning teams
            are awarded Best Idea Certificates from IBC Media.
          </p>
        </>
      )
    },
    {
      id: 2,

      question: 'Who is eligible to participate in Digital India ALT Hack?',
      answer: (
        <>
          <p>
            Digital India ALT Hack is open to all engineering students in India.
            College SPOCs, government bodies and state government also
            collaborate in optimizing the ALt Hack Program.
          </p>
          <p>
            Eligibility criteria may vary based on specific requirements and
            guidelines for each Digital India ALT Hack edition. Students are
            required to take a quiz before the registration process and apply
            for their spot in the Digital India Alt Hack.
          </p>
        </>
      )
    },
    {
      id: 3,

      question: 'How can I register for Digital India ALT Hack?',
      answer: (
        <>
          <p>
            Registration details and procedures will be provided by the IBC
            Outreach and IBC Marketing Team responsible for Digital India ALT
            Hack. They will share registration links or portals where interested
            participants can sign up and provide the necessary information.
            Students can also register via the IBC Media website.
          </p>
        </>
      )
    },
    {
      id: 4,

      question: 'What is the assessment test, and how is it conducted?',
      answer: (
        <>
          <p>
            The assessment test is designed to evaluate the skills and knowledge
            of participants to ensure a suitable level of competency for the
            Digital India ALT Hack program. Details about the assessment test,
            including the format, topics covered, and evaluation criteria, will
            be provided by the IBC team responsible for ALT Hack.
          </p>
          <p>
            Please sign in to IBC Media’s Discord channel for regular updates.
          </p>
          <a
            href='https://discord.com/invite/zSWaTrZtwP'
            target='_blank'
            rel='noopener noreferrer'
            className='text-white'
          >
            https://discord.com/invite/zSWaTrZtwP
          </a>
        </>
      )
    },
    {
      id: 5,
      question:
        'How are participants selected and invited to Digital India ALT Hack?',
      answer: (
        <>
          <p>
            Participants are selected based on their performance in the
            Assessment Test and other criteria specified by the IBC team.
            Invitations to participate in Digital India ALT Hack are sent to the
            selected individuals through email or other communication channels.
          </p>
          <p>
            Please take the assessment quiz after signing up and logging in.
          </p>
          <p>
            Please Login{' '}
            <a
              href='/login'
              className='text-white'
              target='_blank'
              rel='noopener noreferrer'
            >
              here{' '}
            </a>
          </p>
        </>
      )
    },
    {
      id: 6,

      question:
        'How long does Digital India ALT Hack last, and what is the schedule like?',
      answer: (
        <>
          <p>
            IBC Hack typically lasts eight days, during which participants
            attend the sessions and work on their projects. The IBC team will
            communicate the exact schedule and timeline to the participants. It
            is important to stay updated with the provided information to ensure
            active participation.
          </p>
          <p>
            You can also check the IBC Media website for the program agenda
            which is normally shared 2 weeks prior to the event in that
            particular city.
          </p>
          <a
            href='https://ibc.media'
            className='text-white'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://ibc.media
          </a>
        </>
      )
    },
    {
      id: 7,
      question:
        'What are the benefits of participating in the Digital India ALT Hack?',
      answer: (
        <>
          <p>
            Participating in Digital India ALT Hack offers numerous benefits for
            student developers.
          </p>
          <ol>
            <li>
              A sure footing for participants to begin their Web3 journey{' '}
            </li>
            <li>
              Learn the foundations of blockchain and Web3 from expert trainers,
              online speaker sessions, panel discussions etc
            </li>
            <li> Ideate on Web3 ideas mentored by the best</li>
            <li>
              Opportunity to present Web3 ideas before an audience and be
              eligible to win IBC Media Best Ideas certificate
            </li>
            <li>
              Continue the Web3 journey with IBC CEP (Continuous Education
              Program) where you will deep dive into Web3 and blockchain
              technology taught by experts
            </li>
            <li>
              {' '}
              Participants can kick off their IBC Internship Program journey
              where they will be eligible for grants, rewards & recognition,
              opportunities to begin a startup company and open avenues for
              topline jobs and careers in global Web3 & blockchain companies.
            </li>
          </ol>
        </>
      )
    },
    {
      id: 8,
      question:
        'Is there any registration fee or cost associated with participating in Digital India ALT Hack?',
      answer: (
        <>
          <p>
            The IBC Outreach Team and Marketing Team will provide specific
            details regarding registration fees or costs, if any, during the
            registration process. Reviewing the registration guidelines and
            information provided to understand any associated fees is important.
          </p>
        </>
      )
    },
    {
      id: 9,
      question:
        'What resources or tools will be provided to participants during Digital India ALT Hack?',
      answer: (
        <>
          <p>
            During the Digital India ALT Hack, participants will be provided
            with the necessary resources and tools to support their project
            development. These may include access to development platforms,
            software tools, APIs, documentation, learning repository technical
            support. The IBC team will communicate the exact resources provided.
          </p>
        </>
      )
    },
    {
      id: 10,
      question:
        'Will there be mentorship or guidance available during the Digital India ALT Hack?',
      answer: (
        <>
          <p>
            Yes, mentorship and guidance will be available during Digital India
            ALT Hack. Experienced mentors and industry professionals will be
            assigned to assist participants, provide guidance, and help navigate
            technical challenges or project-related queries.
          </p>
        </>
      )
    },
    {
      id: 11,
      question:
        'How are the Web3 ideas generated judged, and what criteria are used for evaluation?',
      answer: (
        <>
          <p>
            The Web3 ideas will be judged based on predefined evaluation
            criteria, including innovation, use case relevance, go-to-market
            viability, technical implementation, problem-solving approach,
            usability, impact, and presentation. The judging process and
            criteria will be communicated to participants by the IBC team.
          </p>
        </>
      )
    },
    {
      id: 12,
      question: 'Are there any prizes or rewards for the winning teams?',
      answer: (
        <>
          <p>
            In every Digital India ALT Hack, 3 winning teams will be awarded IBC
            Best Ideas Certification on the last day (Day 8) of the program. One
            certificate is also awarded for the “Most Unique Idea
          </p>
        </>
      )
    },
    {
      id: 13,
      question:
        'Will participants receive certificates for their participation in Digital India ALT Hack?',
      answer: (
        <>
          <p>
            The Participation Certificates will be shared via the respective
            participating colleges.
          </p>
        </>
      )
    }
  ]

  return (
    <div
      className='faq-page'
      // style={{ marginTop: '-90px', paddingTop: '90px' }}
    >
      <div className='container'>
        <h1>FAQs</h1>

        <h3 className='text-center text-white pb-3'>
          Frequently Asked Questions
        </h3>
        <div className='accordion' id='accordionExample'>
          {faqs.map((faq, i) => {
            return (
              <div
                className='accordion-item'
                key={faq.id + i}
                onClick={() => setActiveFaq(i + 1)}
              >
                <h2 className='accordion-header'>
                  <button
                    className='accordion-button'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={`#collapse${faq.id}`}
                    aria-expanded={faq.id === activeFaq ? true : false}
                    aria-controls={`collapse${faq.id}`}
                  >
                    {faq.question}
                  </button>
                </h2>
                <div
                  id={`collapse${faq.id}`}
                  className={`accordion-collapse collapse ${
                    faq.id === activeFaq ? 'show' : ''
                  }`}
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body'>{faq.answer}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Faq
