import React from 'react'
import { Link } from 'react-router-dom'
import { images } from '../Pictures/images'
import { isRegistrationClosed } from '../utils/constants'

const RegistrationClosed = () => {
  if (isRegistrationClosed) {
    return (
      <div className='signup-page  box-shadow-grey d-flex justify-content-center align-items-center'>
        <div
          className='container'
          style={{
            maxWidth: '50rem',
            margin: 'auto'
          }}
        >
          <div className='card card-form-bg border-0'>
            <div className='card-body'>
              <Link to='/'>
                <img src={images.logoBlack} alt='logo' className='img-fluid' />
              </Link>

              <h2 className='text-center mt-4'>
                Registrations are currently closed.
              </h2>
              <div className='text-center mt-5 d-flex justify-content-center align-items-center'>
                <Link
                  to='/login'
                  className='btn btn-blue round-0 mb-0 mx-2 d-flex justify-content-center align-items-center'
                >
                  Login Now
                </Link>
                <Link
                  to='/'
                  className='btn btn-blue round- mb-0 mx-2 d-flex justify-content-center align-items-center'
                >
                  Go Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RegistrationClosed
