import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const FeedbackForm = () => {
  const location = useLocation()
  const [eventActive, setEventActive] = useState(
    location?.pathname?.includes('cep') ? 'cep' : 'alt-hack'
  )
  const feedbackForms = [
    {
      id: '1',
      name: 'Feedback 1',
      title: 'Avinash Pitti Digital india Alt Hack Delhi Learning Experience',
      link: 'https://docs.google.com/forms/d/16eo3Y3xy7g0U_upUFpxWy8rtNEA8XilUX-ua5JcJdWw/viewform'
    },
    {
      id: '2',
      name: 'Feedback 2',

      title: "Avinash Pitti's How to create a Wallet Delhi Learning Experience",
      link: 'https://docs.google.com/forms/d/1e_N123xjBQT6HcHbL5CynMICOOeScQyMVKCd3r9em2k/viewform'
    },
    {
      id: '3',
      name: 'Feedback 3',

      title: 'DIGITAL INDIA ALT HACK DELHI Feedback',
      link: 'https://docs.google.com/forms/d/1rnn020fAco7tJO3oZfsWqY4WdSlgErlrPtGtTjSS__A/viewform'
    },
    {
      id: '4',
      name: 'Feedback 4',

      title: 'Digital india Alt Hack Delhi Learning Experience',
      link: 'https://docs.google.com/forms/d/1uMYN_QfGc2blaohLQ1UZoaK9X2s9DyorEzIEpX4It1o/viewform'
    },
    {
      id: '5',
      name: 'Feedback 5',

      title: 'Digital India Alt Hack Delhi Testimonial videos',
      link: 'https://docs.google.com/forms/d/1gd9j6VKq6xvgur9nVeuRNg0FedXEsCj3Q7BZBYXmbws/viewform'
    },
    {
      id: '6',
      name: 'Feedback 6',

      title: 'Digital India Alt Hack Delhi Testimonials ',
      link: 'https://docs.google.com/forms/d/1Uk2YQVQ0TyHsi2oAF_dhb4WR4tHsdjL2HWFbZZpTx88/viewform'
    },
    {
      id: '7',
      name: 'Feedback 7',

      title: 'Olga Kamenskaya Digital india Alt Hack Delhi Learning Experience',
      link: 'https://docs.google.com/forms/d/1j08NeTmdKqJVczWQY3Q6v4j2ZyTojlfw7VE421RPRYs/viewform'
    },
    {
      id: '8',
      name: 'Feedback 8',

      title: "Praveen Thakur's Decentralised Finance Learning Experience",
      link: 'https://docs.google.com/forms/d/1gr9nuwfZby5aY870ghRXlIhUuHKSFkIwpwaddRRDZBM/viewform'
    },
    {
      id: '9',
      name: 'Feedback 9',

      title:
        "Praveen Thakur's Technical anatomy of Blockchain Learning Experience",
      link: 'https://docs.google.com/forms/d/1_ikwjwPYbhDEVOGotsN5mJ2fCmha0j9bAfhOxCWJluo/viewform'
    },
    {
      id: '10',
      name: 'Feedback 10',

      title: "Praveen Thakur's Introduction to Blockchain Learning Experience",
      link: 'https://docs.google.com/forms/d/1a2e4g31sUt9nzXT0Sxnh2kvqF25sCm5cMADQYfjs_v0/viewform'
    },
    {
      id: '11',
      name: 'Feedback 11',

      title: 'Prernaa Agarwal Digital india Alt Hack Delhi Learning Experience',
      link: 'https://docs.google.com/forms/d/1VjyBmey5R_I8dc_F6s8abCh3qEO7BNO3QfCK3-CpCBI/viewform'
    },
    {
      id: '12',
      name: 'Feedback 12',

      title: 'Pruthvi Raj Digital india Alt Hack Delhi Learning Experience',
      link: 'https://docs.google.com/forms/d/1d5rhy9eeEFxPJ06Xh8iSD1oKXFKATEYCC5acttVysYY/viewform'
    },
    {
      id: '13',
      name: 'Feedback 13',

      title:
        'Sameep Singhania Digital india Alt Hack Delhi Learning Experience',
      link: 'https://docs.google.com/forms/d/1r3kZR7tNq9xiSlkSb89EdvJyGkeX8unwOIcHhMFR538/viewform'
    },
    {
      id: '14',
      name: 'Feedback 14',

      title:
        "Sandhya Balakrishna's Digital india Alt Hack Delhi Learning Experience",
      link: 'https://docs.google.com/forms/d/16LvPmJrVhr7NrISDFGADr8wmNnOZil5yFjbrjtP6PVM/viewform'
    },
    {
      id: '15',
      name: 'Feedback 15',

      title: 'Sneha Bharti Digital india Alt Hack Delhi Learning Experience',
      link: 'https://docs.google.com/forms/d/1uFMx7Xv8Dk6BPqRRHM6BJrHPsgWYUyyKwxSo6V9W0lI/viewform'
    },
    {
      id: '16',
      name: 'Feedback 16',

      title: 'Trang Ha Nguyen Digital india Alt Hack Delhi Learning Experience',
      link: 'https://docs.google.com/forms/d/1WLvg-VqUQ3JVNat4pMUPXFTVugONCo-3KnkQ2QhsOQ0/viewform'
    },
    {
      id: '17',
      name: 'Feedback 17',

      title:
        'Urban Osvald Session Digital india Alt Hack Delhi Learning Experience',
      link: 'https://docs.google.com/forms/d/1UliLB1DgEKluPN7vT0M-wz0Qtu5F93TN_Cb4C5lmR44/viewform'
    }
  ]

  const feedbackFormsCep = [
    {
      id: '1',
      name: 'Feedback 1',
      title: "Avinash Pitti's web2 to web3 shift learning experience",
      link: 'https://forms.gle/pBs5qv2U5QuNBV4E7'
    },
    {
      id: '2',
      name: 'Rishin Introduction to Rust (Tech Session-1)',
      title: "Rishin's Introduction to Rust (Tech Session-1)",
      link: 'https://forms.gle/rs7Sdg7vFmycG8w58'
    },
    {
      id: '3',
      name: 'Rishin References & Borrowing (Tech Session-2)',
      title: "Rishin's References & Borrowing (Tech Session-2)",
      link: 'https://forms.gle/138oSnHrvbuePAGk6'
    },
    {
      id: '4',
      name: 'Rishin Structs & Enums (Tech Session-3)',
      title: "Rishin's Structs & Enums (Tech Session-3)",
      link: 'https://forms.gle/3guBfD1qWe71JkKH6'
    },
    {
      id: '5',
      name: 'Rishin Common Collections & Error Handling (Tech Session-4)',
      title: "Rishin's Common Collections & Error Handling (Tech Session-4)",
      link: 'https://forms.gle/EbHHbvQ7MMjrqxw66'
    },
    {
      id: '6',
      name: "Rishin's Generic Types & Traits (Tech Session-5)",
      title: "Rishin's Generic Types & Traits (Tech Session-5)",
      link: 'https://forms.gle/poFDs2cabcBS8HX9A'
    },
    {
      id: '7',
      name: 'Feedback 7',

      title: "Radha's Introduction to Polkadot learning experience",
      link: 'https://forms.gle/9rDCWti7imYYMbvu8'
    },
    {
      id: '8',
      name: 'Feedback 8',

      title: "Radha's Introduction to Substrate framework learning experience",
      link: 'https://forms.gle/yHqFob1CEYRyUuTW8'
    },
    {
      id: '9',
      name: 'Feedback 9',

      title:
        "Deepak's Introduction to Frame Pallet Library learning experience",
      link: 'https://forms.gle/qjdS2xoXKCCyz46Z6'
    },
    {
      id: '10',
      name: 'Feedback 10',

      title:
        "Deepak's Introduction on developing a custom pallet learning experience ",
      link: 'https://forms.gle/rcWgY6xF6jGdY6uv6'
    }
  ]

  const getCurrentEventFeedback = () => {
    switch (eventActive) {
      case 'alt-hack':
        return feedbackForms

      case 'cep':
        return feedbackFormsCep

      default:
        return feedbackForms
    }
  }

  return (
    <div className='py-3'>
      <div className='card'>
        <div className='card-boby px-3'>
          <h4 className='py-3'>Feedback Form</h4>
          {getCurrentEventFeedback().map((feedback, i) => {
            return (
              <Link
                to={feedback?.link}
                key={i}
                className={`d-block mb-3 `}
                target='blank'
                rel='no-referrer'
              >
                <p className='mb-0'>
                  <small>{`Feedback ${feedback.id}`}</small>
                </p>
                <div className={` py-2 quiz-page__card `}>
                  <div className='card p-2 quiz-page__card--title'>
                    <h6 className='mb-1'>{feedback.title}</h6>
                    <i className='fa fa-chevron-right'></i>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FeedbackForm
