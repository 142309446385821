import React from 'react'
import Sidenav from './Sidenav'
import { Outlet } from 'react-router'

const DashboardLayout = () => {
  return (
    <>
      <div className='dashboard PT_70PX'>
        <div className=' px-4 px-0-mobile'>
          <div className='row'>
            <div className='col-md-3 show-desktop-only'>
              <Sidenav />
            </div>
            <div className='col-md-12 col-lg-9'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardLayout
