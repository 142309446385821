import React, { useState } from 'react'

const CepTerms = ({ setOpenModal, enroll }) => {
  const [isTermChecked, setIsTermChecked] = useState(false)

  return (
    <>
      <div className='border-bottom mb-3 p-2'>
        <h6>Terms & conditions</h6>
      </div>
      <div className='cep-terms'>
        <div>
          <p>
            These terms and conditions ("Agreement") govern your use of our
            website or any other related services ("Service"). By accessing or
            using the Service, you agree to be bound by this Agreement. If you
            disagree with any part of the Agreement, you may not access the
            Service.
          </p>

          <p>
            <strong>1. Intellectual property </strong>
          </p>

          <p>
            All content provided on the Service, including text, graphics,
            images, logos, and software, is the property of the Service provider
            and protected by applicable intellectual property laws. You may not
            use, reproduce, modify, or distribute the content without prior
            written permission.
          </p>

          <p>
            <strong>User Conduct</strong>
          </p>
          <p>
            You agree to use the Service for lawful purposes only and to abide
            by all applicable laws and regulations. You are solely responsible
            for any content you post, upload, or transmit through the Service.
            Prohibited activities include but are not limited to:
          </p>
          <p className='indent'>
            a. Violating any laws or infringing upon the rights of others.{' '}
            <br />
            b. Uploading or distributing viruses, malware, or other harmful
            content. <br />
            c. Engaging in unauthorised access or attempting to interfere with
            the Service's functionality.
          </p>
          <p>
            <strong>2. Privacy Policy</strong>
          </p>
          <p>
            Your privacy is important to us. Please refer to our separate
            Privacy Policy document to understand how we collect, use, and
            disclose your personal information.
          </p>
          <p className='indent'>
            2.1 Third-Party Links The Service may contain links to third-party
            websites or services that are not owned or controlled by us. We have
            no control over and assume no responsibility for the content,
            privacy policies, or practices of any third-party websites or
            services. You acknowledge and agree that we shall not be responsible
            or liable for any damages or losses incurred from accessing or using
            such third-party websites or services.{' '}
          </p>
          <p className='indent'>
            2.2 Limitation of Liability To the fullest extent permitted by law,
            the Service provider and its affiliates shall not be liable for any
            direct, indirect, incidental, consequential, or special damages
            arising out of or in any way connected with the use or inability to
            use the Service.
          </p>
          <p className='indent'>
            2.3 Indemnification You agree to indemnify and hold the Service
            provider and its affiliates harmless from any claim or demand,
            including reasonable attorneys' fees, made by any third party due to
            or arising out of your breach of this Agreement or your violation of
            any law or the rights of a third party. Modifications to the Service
          </p>
        </div>
      </div>

      <div className='cep-footer'>
        <form onSubmit={enroll}>
          <div className='col-12 py-2 d-flex align-items-center'>
            <input
              type='checkbox'
              name='termsCheckbox'
              id=''
              className='me-2'
              onChange={() => setIsTermChecked(!isTermChecked)}
            />
            <label
              htmlFor='termsCheckbox'
              style={isTermChecked ? { color: 'green' } : { color: 'red' }}
            >
              I acknowledge all terms & conditions
            </label>
          </div>

          <div className='col-md-12'>
            <div className='d-flex pt-3'>
              <button
                onClick={() => setOpenModal(false)}
                type='cancel'
                className='btn btn-blue round-0 me-3 ms-auto'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='btn btn-blue round-0 '
                disabled={!isTermChecked}
                onClick={(e) => {
                  e.preventDefault()
                  enroll()
                }}
              >
                Accept
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default CepTerms

