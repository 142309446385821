import React from 'react'
import moment from 'moment'
import { useEvents } from '../hooks/useEvents'
import { useParams } from 'react-router'

const AltHackScheduleByHook = () => {
  const params = useParams()

  const { tabActive, setTabActive, eventDays, eventSchedule } =
    useEvents(params)

  return (
    <>
      <div className='alt-hack-schedule py-3'>
        <div className='card'>
          <div className='day__cards py-2 px-3 btn-scrolls'>
            {eventDays &&
              eventDays.map((day, index) => {
                let date = moment(day.date)

                return (
                  <div
                    className={`day__border day__card  ${
                      tabActive === index ? 'active' : ''
                    }`}
                    key={index}
                    onClick={() => setTabActive(index)}
                  >
                    <h6 className='card-title text-left'>{day.day}</h6>

                    {tabActive === index && (
                      <div className='text-end mb-0'>
                        <small style={{ fontSize: '0.75rem' }}>
                          {date.format('Do MMMM')}
                        </small>
                      </div>
                    )}
                  </div>
                )
              })}
          </div>

          {/* Tab Conetnt */}

          <div className='p-3 day__content'>
            {eventSchedule &&
              eventSchedule.map((schedule) => {
                const { startTime, endTime } = schedule
                let start = moment(startTime, 'HH:mm:ss')
                let end = moment(endTime, 'HH:mm:ss')

                return (
                  <div className='col-12 alt-hack-schedule-col p-0'>
                    <div className='row m-0 mb-3 d-flex align-items-center alt-hack-schedule-inner px-0'>
                      <div className='col-12 col-md-2 p-0'>
                        <p
                          className='m-0 d-flex align-items-center px-2'
                          style={{
                            border: '1px solid #11142D',
                            borderRadius: '4px',
                            height: '100%'
                          }}
                        >
                          {start.format('HH:mm')} - {end.format('HH:mm')}
                        </p>
                      </div>
                      <div className='col-12 col-md-7'>
                        <p className='m-0 text-left d-flex align-items-center'>
                          {schedule?.activity || ''}
                        </p>
                      </div>

                      <div className='col-12 col-md-3 p-0'>
                        <p
                          className='m-0 d-flex align-items-center px-2'
                          style={{
                            border: '1px solid #11142D',
                            borderRadius: '4px',
                            height: '100%'
                          }}
                        >
                          {schedule?.speaker || ''}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default AltHackScheduleByHook
