import { useEffect, useState } from 'react'
import { getEventActivities, getEvents } from '../API/eventsAPI'

export const useEvents = (params) => {
  const [tabActive, setTabActive] = useState(0)
  const [eventData, setEventData] = useState(null)

  const [eventDays, setEventDays] = useState([])
  const [eventSchedule, setEventSchedule] = useState([])

  // console.log(eventSchedule)
  // console.log(eventDays)
  // const params = useParams()
  // console.log(params, 'from events')

  const fetchEvent = async (id) => {
    try {
      const res = await getEvents(id, 'Hackathon')
      const { data } = res
      setEventDays({ ...data?.data[0] })

      const { numberOfDays, startDate } = data?.data[0]

      let temp = []
      Array.from(Array(numberOfDays).keys()).map((i) => {
        let date = new Date(startDate)
        date.setDate(date.getDate() + i)
        temp.push({
          day: `Day ${i + 1}`,
          date: date.toISOString().split('T')[0]
        })
      })

      setEventDays([...temp])
    } catch (error) {
      debugger
    }
  }

  const fetchEventSchedule = async (id) => {
    try {
      const res = await getEventActivities(id, 'Hackathon')
      const { data } = res
      setEventData([...data?.data])
    } catch (error) {
      debugger
    }
  }

  useEffect(() => {
    const { id } = params
    fetchEvent(id)
  }, [])

  useEffect(() => {
    if (eventDays) {
      const { id } = params
      fetchEventSchedule(id)
    }
  }, [eventDays])

  useEffect(() => {
    if (eventData && eventData !== null) {
      makeSchedule(tabActive)
    }
  }, [eventData, tabActive])

  const makeSchedule = (tabActive) => {
    let temp = []
    eventData.map((event) => {
      if (event?.eventDate === eventDays[tabActive]?.date) {
        temp.push(event)
      }
    })

    setEventSchedule([...temp])
  }

  return {
    tabActive,
    setTabActive,
    eventData,
    setEventData,
    eventDays,
    setEventDays,
    eventSchedule,
    setEventSchedule
  }
}

