import React, { useEffect, useState } from 'react'
import Education from './views/ProfileSettings/Education'
import PersnolInfo from './views/ProfileSettings/PersnolInfo'
import Skills from './views/ProfileSettings/Skills'
import WorkExperience from './views/ProfileSettings/WorkExperience'
import { getProfilePercent } from '../API/userAPI'

const ProfileSetting = ({ getPercentage, percent }) => {
  const [tabActice, setTabActive] = useState(0)

  useEffect(() => {
    getPercentage()
  }, [])

  const profileTabs = [
    {
      id: 1,
      title: 'Personal Information',
      page: (
        <PersnolInfo
          setTabActive={setTabActive}
          getPercentage={getPercentage}
        />
      )
    },
    {
      id: 2,
      title: 'Work Experience (optional)',
      page: (
        <WorkExperience
          setTabActive={setTabActive}
          getPercentage={getPercentage}
        />
      )
    },
    {
      id: 3,
      title: 'Education',
      page: (
        <Education setTabActive={setTabActive} getPercentage={getPercentage} />
      )
    },
    {
      id: 4,
      title: 'Skills',
      page: <Skills setTabActive={setTabActive} getPercentage={getPercentage} />
    }
  ]

  // const getFillingPercentage = () => {
  //   let __percentage = (stepsFilled * 100) / profileTabs.length
  //   // console.log('Percentage', __percentage)
  //   return __percentage
  // }

  // useEffect(() => {
  //   getFillingPercentage()
  // }, [stepsFilled])
  return (
    <div className='profile-setting'>
      <div className='card p-4'>
        <div className='card-body'>
          <h4>Profile Settings </h4>

          {/* Progress bar */}
          <div className='progress-bar '>
            <meter
              id='fuel'
              min='0'
              max='100'
              optimum='80'
              value={percent}
              className=''
            >
              {percent}%
            </meter>
            <small>{percent}%</small>
          </div>

          {/* Profile Tabs */}

          <div className='profile-tabs py-3'>
            <div className='profile-tabs__bar'>
              <div className='button-mobile-nav'>
                <div className='btn-scrolls'>
                  {profileTabs.map((tab, i) => {
                    return (
                      <button
                        className={`btn btn-link tabs-btn ${
                          tabActice === i ? 'active' : ''
                        }`}
                        key={i}
                        onClick={() => setTabActive(i)}
                      >
                        {tab.title}
                      </button>
                    )
                  })}
                </div>
              </div>
            </div>
            {/* Profile tab component */}
            {profileTabs[tabActice].page}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileSetting

