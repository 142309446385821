/* eslint-disable no-use-before-define */
import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import swal from 'sweetalert'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import AsyncSelect from 'react-select/async'
import { Tooltip } from 'react-tooltip'

import PopupEditTeam from './PopupEditTeam'

import { logOut } from '../../../redux/loginLogout'

import {
  acceptTeamInvite,
  exitFromTeam,
  getTeam,
  getTeamInvites,
  rejectTeamInvite,
  sendTeamInvite
} from '../../../API/teamAPI'
import { searchIBCUser } from '../../../API/userAPI'
import { infoIcon } from '../../../assets/svg'

const TeamFormation = ({ setTabActive }) => {
  const [openModal, setOpenModal] = useState(false)
  const [isTeamCreated, setCreatedTeam] = useState(false)
  const [teamData, setTeamData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [selectedUser, setSelectedUser] = useState('')

  const [options, setOptions] = useState([]) // [{ value: 'chocolate', label: 'Chocolate' }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isUpdate, setIsUpdate] = useState(false)

  const [users, setUsers] = useState([])

  const [invites, setInvites] = useState([])
  const params = useParams()
  const { id } = params

  const location = useLocation()

  useEffect(() => {
    fetchTeam()

    const getTeamInterval = setInterval(() => {
      fetchTeam()
    }, 5000)

    return () => clearInterval(getTeamInterval)
  }, [])

  useEffect(() => {
    const inviteInterval = setInterval(() => {
      getInvites()
    }, 5000)

    return () => clearInterval(inviteInterval)
  }, [])

  const searchUser = async (searchTxt, callback) => {
    if (searchTxt === '') {
      return false
    }
    try {
      const res = await searchIBCUser(searchTxt)

      const { data } = res

      if (data?.data?.length !== 0) {
        const loadedOptions = data?.data?.map((user) => {
          return {
            label: `${user?.firstName} ${
              user?.lastName === null ? '' : user.lastName
            } (${user.ibcUserId})`,
            value: user?.ibcUserId
          }
        })

        callback(loadedOptions)
      } else {
        callback([])
      }
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        setOpenModal(false)
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  const debouncedLoadOptions = debounce(searchUser, 100)

  const getInvites = async () => {
    try {
      const res = await getTeamInvites(id)
      const { data } = res

      setInvites([...data?.data])
    } catch (error) {
      debugger
    }
  }

  const fetchTeam = async () => {
    try {
      const res = await getTeam(params?.id)
      if (res?.data?.data.length === 0) {
        setCreatedTeam(false)
        setIsUpdate(false)
        setTeamData(null)
      } else {
        const { data } = res

        setTeamData(data?.data[0])
        setCreatedTeam(true)
        setIsUpdate(true)
      }
    } catch (error) {
      debugger
    }
  }

  const acceptIcon = (
    <i className='fa-solid fa-check' style={{ color: '#000' }}></i>
  )
  const denyIcon = <i className='fa-solid fa-xmark'></i>
  const exitIcon = <i className='fa-solid fa-right-from-bracket'></i>
  const editIcon = <i className='fa-solid fa-pen-to-square'></i>

  const handleInputChange = (inputValue, { action }) => {
    setSearchText(inputValue)

    debouncedLoadOptions(inputValue, (options) => {
      // Callback from debounced loadOptions
      // Update the options in the AsyncSelect component
      setOptions(options)
    })
  }

  const exitTeam = async (ibcUserId, teamUniqueId) => {
    try {
      const res = await exitFromTeam(ibcUserId, teamUniqueId)
      swal(
        `${res?.data?.message || 'exit from team successful.'}`,
        '',
        'success'
      )
      fetchTeam()
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        setOpenModal(false)
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  const acceptInvite = async (invitedByUserId) => {
    try {
      const res = await acceptTeamInvite(invitedByUserId, params?.id)
      swal(
        `${res?.data?.message || 'Invites accepted successfully.'}`,
        '',
        'success'
      )
      fetchTeam()
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        setOpenModal(false)
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  const rejectInvite = async (invitedByUserId) => {
    try {
      const res = await rejectTeamInvite(invitedByUserId, params?.id)

      swal(`${res?.data?.message || 'Invites rejected.'}`, '', 'success')
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        setOpenModal(false)
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  const sendInvite = async () => {
    try {
      const res = await sendTeamInvite({
        eventId: params?.id,
        ibcUserId: selectedUser?.value
      })

      swal(
        `${res?.data?.message || 'Invite send successfully.'}`,
        '',
        'success'
      )
      setSelectedUser('')
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        setOpenModal(false)
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  const tableHeadsYourInvitation = ['Name', 'Unique ID', 'Accept or Deny']
  const tableHeadsTeamMembers = ['Name', 'Unique ID', '']

  const teamLastIndex = tableHeadsTeamMembers.length - 1
  const invitationLastIndex = tableHeadsYourInvitation.length - 1

  return (
    <>
      <div className='create-team py-3'>
        {/*popup for edit team name  */}
        {openModal && (
          <PopupEditTeam
            openModal={openModal}
            setOpenModal={setOpenModal}
            fetchTeam={fetchTeam}
            isUpdate={isUpdate}
            team={teamData?.teamName || ''}
          />
        )}

        <div className=''>
          <div className='card  card-form-bg border-0 shadow-none'>
            {isTeamCreated ? (
              <>
                <div className='d-flex'>
                  <h1 className='fw-bolder text-black text-capitalize'>
                    {teamData?.teamName || ''}
                  </h1>
                  <button
                    className='btn btn-edit ms-2'
                    title='Edit Team Name'
                    onClick={() => {
                      setOpenModal(true)
                    }}
                  >
                    {editIcon}
                  </button>
                </div>
                {/*If Limit to add members  */}
                <div className='py-4'>
                  <form>
                    <div className='col-12'>
                      <label
                        htmlFor='validationCustom01'
                        className='form-label d-flex align-items-center justify-content-start'
                      >
                        <div className='d-flex align-items-start justify-content-start'>
                          <h6 className='mb-0'>Add Team Member</h6>
                          <div className='tool-tip ms-2 d-flex align-items-center'>
                            <div
                              className='tooltip-icon'
                              id='add-member-tooltip'
                            >
                              {infoIcon}
                            </div>
                          </div>
                        </div>

                        {/* <div className='tool-tip ms-2 d-flex align-items-center'>
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id='custom-tooltip'>
                              You can only add two other team members. (Making a
                              maximum team size of 3)
                            </Tooltip>
                          }
                          style={{
                            width: '100%',
                            backgroundColor: 'white'
                          }}
                        >
                          <button
                            className='bg-transparent border-0 p-0 d-flex d-flex align-items-center'
                            type='button'
                          >
                            {infoIcon}
                          </button>
                        </OverlayTrigger>
                      </div> */}
                      </label>
                      <div className='d-flex align-items-center justify-content-center'>
                        <AsyncSelect
                          //isLoading={isLoading}
                          defaultOptions
                          inputValue={searchText}
                          loadOptions={debouncedLoadOptions}
                          onInputChange={handleInputChange}
                          options={options}
                          onChange={(e) => {
                            setSelectedUser(e)
                          }}
                          className='async-select-search w-100'
                          value={selectedUser}
                          placeholder='Search with the full name Or unique ID'
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused ? '#ccc' : '#ccc',
                              borderBottomRightRadius: '0px',
                              borderTopRightRadius: '0px',
                              borderWidth: '0.5px'
                            })
                          }}
                        />
                        <button
                          className='btn btn-icon'
                          type='button'
                          style={{
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                            border: '0.5px solid #ccc',
                            borderLeft: 'none',
                            padding: '14px 18px'
                          }}
                          onClick={() => {
                            sendInvite()
                          }}
                        >
                          <i className='fa-solid fa-plus'></i>
                        </button>
                      </div>

                      {/* <input
                        type='text'
                        className='form-control'
                        placeholder='Search by name, email or unique ID'
                        required
                        value={searchText}
                        onChange={handleSearch}
                        id='exampleDataList'
                        list='datalistOptions'
                      />
                      
                      <button className='btn btn-icon' type='button'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                      </button> */}

                      <datalist id='datalistOptions'>
                        {users &&
                          users.length > 0 &&
                          users.map((user, index) => {
                            return (
                              <option value={user.ibcUserId} key={index}>
                                {user.firstName}
                              </option>
                            )
                          })}
                      </datalist>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <div className='create-team-btn my-4'>
                <button className='btn ' onClick={() => setOpenModal(true)}>
                  <p className='mb-0'>
                    Create team <span>+</span>
                  </p>
                </button>
              </div>
            )}

            <div className='card shadw border-0 py-2 my-2'>
              <div className='card-body'>
                <h5 className='head-h6 d-flex align-items-center justify-content-start'>
                  Invitations Received{' '}
                  <div className='tool-tip ms-2 d-flex align-items-center'>
                    <div className='tooltip-icon' id='invite-tooltip'>
                      {infoIcon}
                    </div>
                  </div>
                </h5>

                <div className='responsivee__table py-2'>
                  <table className='table  table-bordered'>
                    <thead>
                      <tr>
                        {tableHeadsYourInvitation.map((data, i) => {
                          ;<th
                            key={i}
                            scope='col'
                            className={
                              i === invitationLastIndex ? 'text-center' : null
                            }
                          >
                            {data}
                          </th>
                        })}
                      </tr>
                    </thead>
                    {invites && invites.length !== 0 ? (
                      <tbody>
                        {invites.map((invite) => {
                          if (invite?.isRequestAccepted === false) {
                            return (
                              <tr>
                                <td data-label={tableHeadsYourInvitation[0]}>
                                  {invite?.invitedByUserName || ''}
                                </td>
                                <td data-label={tableHeadsYourInvitation[1]}>
                                  {invite?.invitedByIbcUserId || ''}
                                </td>
                                <td
                                  className='text-center'
                                  data-label={tableHeadsYourInvitation[2]}
                                >
                                  <div className='d-flex align-items-center justify-content-center'>
                                    <button
                                      className='accept-button me-3 border-0 rounded-1'
                                      title='Accept Invite'
                                      onClick={() => {
                                        acceptInvite(invite?.invitedByUserId)
                                      }}
                                    >
                                      {acceptIcon}
                                    </button>
                                    <button
                                      className='deny-button border-0 rounded-1'
                                      title='Delete Invite'
                                      onClick={() => {
                                        rejectInvite(invite?.invitedByUserId)
                                      }}
                                    >
                                      {denyIcon}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </tbody>
                    ) : (
                      <td colSpan='3' className='pt-2 pb-4'>
                        <p className='text-center mt-3 mb-0'>
                          No Invitations Found !
                        </p>
                      </td>
                    )}
                  </table>
                </div>
              </div>
            </div>

            {/* Team MemberTable */}
            <div className='card-body'>
              <h5
                className='head-h6'
                style={{
                  width: 'auto'
                }}
              >
                Your Team Members
              </h5>
              <div className='responsivee__table py-2'>
                {teamData &&
                teamData?.teamMembers &&
                teamData?.teamMembers?.length > 0 ? (
                  <table className='table  table-bordered'>
                    <thead>
                      <tr>
                        {tableHeadsTeamMembers.map((data, i) => {
                          return (
                            <th
                              key={i}
                              scope='col'
                              className={
                                i === teamLastIndex ? 'text-center' : null
                              }
                            >
                              {data}
                            </th>
                          )
                        })}
                        {/* <th scope='col'>Name</th>
                      <th scope='col'>Unique ID</th>
                      <th className='text-end' scope='col'></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {teamData?.teamMembers?.map((member, index) => {
                        let isHost =
                          member.userId === teamData.teamCreatedByUserId
                            ? true
                            : false

                        return (
                          <tr key={index}>
                            <td
                              className=''
                              data-label={tableHeadsTeamMembers[0]}
                            >
                              {member.name}{' '}
                              <span
                                className=''
                                style={{
                                  fontSize: '14px'
                                }}
                              >
                                {isHost ? '(Host)' : ''}
                              </span>
                            </td>

                            <td data-label={tableHeadsTeamMembers[1]}>
                              {member.ibcUserId}
                            </td>

                            <td
                              className='text-center'
                              data-label={tableHeadsTeamMembers[2]}
                            >
                              <button
                                className='exit-leave-button btn'
                                title='Exit from team'
                                onClick={(e) => {
                                  e.preventDefault()
                                  exitTeam(
                                    member.ibcUserId,
                                    teamData?.teamUniqueId
                                  )
                                }}
                              >
                                {exitIcon}
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p className='text-center mt-3 mb-0'>No Team Members found</p>
                )}
              </div>
            </div>

            <div className='col-md-12 text-end'>
              <button
                type='submit'
                className='btn btn-blue round-0 ms-2'
                onClick={() => {
                  setTabActive(location?.pathname?.includes('cep') ? 1 : 2)
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <Tooltip
        anchorSelect='#invite-tooltip'
        place='right'
        content='You can accept only one team invitation, so choose carefully.'
        className='custom-tooltip'
        style={{
          background: '#f5F5F5',
          boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)',
          color: '#11142D',
          fontSize: '14px',
          borderRadius: '4px',
          padding: '10px 20px'
        }}
      />
      <Tooltip
        anchorSelect='#add-member-tooltip'
        place='right'
        content='You can only add two other team members. (Making a maximum team size of 3)'
        className='custom-tooltip'
        style={{
          background: '#f5F5F5',
          boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)',
          color: '#11142D',
          fontSize: '14px',
          borderRadius: '4px',
          padding: '10px 20px'
        }}
      />
    </>
  )
}

export default TeamFormation

