import React, { useState, useRef, createRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import { images } from '../Pictures/images'
import { uploadIcon } from '../assets/svg'
import { getPersonalInfo, uploadProfilePic } from '../API/userAPI'
import { logOut, setUserProfilePic } from '../redux/loginLogout'
import { getUserData } from '../utils/helper'
import { useNavigate } from 'react-router'

const ProfileCard = ({ type = 'white', getPercentage }) => {
  const userData = useSelector((state) => state.loginLogout.userData)

  const toastID = createRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedFile, setSelectedFile] = useState(null)
  const fileInputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (selectedFile) {
      uploadFile()
    }
  }, [selectedFile])

  const handleUploadClick = () => {
    fileInputRef.current.click()
  }

  const handleFileSelected = (event) => {
    if (event.target.files[0].size >= 1000000 * 10) {
      debugger
      toast.error('Max allowed file size is 10MB .')
    } else {
      debugger
      setSelectedFile(event.target.files[0])
    }
  }

  const uploadFile = async () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('profilePicture', selectedFile)
    let uploadToast = toastID

    try {
      const res = await uploadProfilePic(userData.id, formData, uploadToast)
      setSelectedFile(null)

      toast.success('Profile picture updated successfully.')
      getPercentage()
      getUserData(dispatch)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)

      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      toast.error(
        error?.response?.data?.message || 'Unable to upload profile image.'
      )
      toastID.current = null
    }
  }

  return (
    <div
      style={{ background: type }}
      className={`${
        type === 'white' ? 'card' : ''
      } p-2 text-center profile-card`}
    >
      {userData?.profilePicture ? (
        <div className='profile-card__img'>
          <img
            src={userData?.profilePicture}
            className='card-img-top img-profile'
            alt='...'
          />

          {isLoading ? (
            <div
              className='overlay-upload'
              style={{
                zIndex: 1,
                opacity: 1,
                cursor: 'not-allowed'
              }}
            >
              <div className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='overlay-upload' onClick={handleUploadClick}>
              {uploadIcon}
            </div>
          )}
        </div>
      ) : (
        <div className='upload-div' onClick={handleUploadClick}>
          {uploadIcon}
        </div>
      )}

      <input
        type='file'
        ref={fileInputRef}
        accept='.png, .jpg'
        onChange={handleFileSelected}
        style={{
          display: 'none'
        }}
      />

      <div className='card-body'>
        <h5 className='card-title'>
          {userData?.firstName} {userData?.lastName}
        </h5>
        <p className='card-text'>{userData?.email}</p>
      </div>
    </div>
  )
}

export default ProfileCard
