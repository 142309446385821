import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

export const loginLogout = createSlice({
  name: 'loginLogout',
  initialState: {
    token: '',
    isLoggedIn: false,
    userData: {}
  },
  reducers: {
    login: (state, action) => {
      const { payload } = action
      const { data } = payload

      const { token } = data
      delete data.token

      return {
        ...state,
        isLoggedIn: true,
        userData: { ...data },
        token: token
      }
    },
    logOut: (state) => {
      return {
        ...state,
        token: '',
        isLoggedIn: false,
        userData: {}
      }
    },
    setUserData: (state, action) => {
      const { payload } = action
      const { data } = payload

      return {
        ...state,
        userData: {
          token: state?.userData?.token,
          ...data
        }
      }
    },
    setUserProfilePic: (state, action) => {
      const { payload } = action
      const { picUrl } = payload

      return {
        ...state,
        userData: {
          ...state.userData,
          profilePicture: picUrl
        }
      }
    },
    checkData: (state, action) => {
      let token = Cookies.get('alt-hack-user')
        ? JSON.parse(Cookies.get('alt-hack-user')).token
        : ''
      let loggedStatus = Cookies.get('isUserLoggedIn') ? true : false
      let userData = Cookies.get('alt-hack-user')
        ? JSON.parse(Cookies.get('alt-hack-user'))
        : {}

      return {
        ...state,
        token: token,
        isLoggedIn: loggedStatus,
        userData: { ...userData }
      }
    }
  }
})

export const { login, logOut, checkData, setUserData, setUserProfilePic } =
  loginLogout.actions

export default loginLogout.reducer

