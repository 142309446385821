import React, { useState } from 'react'
import { Outlet } from 'react-router'

const Learning = () => {
  return (
    <div className='alt-hack-landing py-3'>
      <div className='card px-4 py-4 px-0-mobile bg-transparent border-0'>
        <Outlet />
      </div>
    </div>
  )
}

export default Learning
