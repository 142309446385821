import React from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { useSelector } from 'react-redux'

const QrCard = () => {
  const userData = useSelector((state) => state.loginLogout.userData)

  return (
    <div className='card p-2 text-center qr-card'>
      <div className='qr-card__img'>
        {/* <img
          src={images.qr}
          className='card-img-top mx-auto rounded qr-image'
          alt='Qr Code'
        /> */}
        <QRCodeSVG value={userData?.ibcUserId} className='user-qr-code' />
      </div>
      <div className='card-body'>
        <p className='card-text'>{userData?.ibcUserId}</p>
      </div>
    </div>
  )
}

export default QrCard

