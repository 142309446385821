import React,{ useState } from 'react'
import { images } from '../Pictures/images'
import Slider from 'react-slick';

const AltSpeakers = () => {
    const [tabActice] = useState(0);

    const slideData = [
      {
      speakers:[
        {
        id: 1,
        link:'https://www.linkedin.com/in/urbanosvald/',
        images:images.speaker1,
        speaker: 'Urban Osvald',
        designation: 'Developer Relations, Engagement Team Lead, Parity Technologies',
      },
      {
        id: 2,
        link:'http://linkedin.com/in/claratsao',
        images:images.speaker10,
        speaker: 'Clara Tsao',
        designation: 'Founding Officer, Filecoin',
      },
      {
        id: 3,
        link:'https://www.linkedin.com/in/vilmamattila/',
        images:images.speaker7,
        speaker: 'Vilma Mattila',
        designation: 'Co-Founder, 5ire Chain',
      },
      {
        id: 4,
        speaker: 'Olga Kamenskaya',
        images:images.speaker9,
        link:'https://www.linkedin.com/in/olga-kamenskaya-3b78491/',
        designation: 'Chief Marketing Officer, W3DNA',
      },
      {
        id: 5,
        speaker: 'Abhishek Pitti',
        images:images.speaker14,
        link:'https://www.linkedin.com/in/abhishekpitti/',
        designation: 'CEO, IBC Media',
      }
      ,
      {
        id: 6,
        speaker: 'Praveen Thakur',
        images:images.speaker15,
        link:'https://www.linkedin.com/in/thakurpraveen',
        designation: 'COO, IBC Media',
      },
      {
        id: 7,
        link:'https://www.linkedin.com/in/ovia-seshadri-14b75124',
        images:images.speaker13,
        speaker: 'Ovia Seshadri',
        designation: 'Research Engineer, CoinDCX',
      }
      ,
      {
        id: 8,
        link:'https://www.linkedin.com/in/prernaa-agarwal-a8955540/',
        images:images.speaker4,
        speaker: 'Prernaa Agarwal',
        designation: 'Product Owner, Envision Blockchain Solutions',
      },
      {
        id: 9,
        link:'https://www.linkedin.com/in/avinash-pitti-09330933/',
        images:images.speaker3,
        speaker: 'Avinash Pitti',
        designation: 'Head of Technology, IBC Media',
      } ,
      {
        id: 10,
        link:'https://www.linkedin.com/in/sandhya-balakrishna-2ba89417/',
        images:images.speaker5,
        speaker: 'Sandhya Balakrishna',
        designation: 'Marketing Director, IBC Media',
      } ,
      {
        id: 11,
        link:'https://www.linkedin.com/in/sameepsi/',
        images:images.speaker12,
        speaker: 'Sameep Singhania',
        designation: 'Co-Founder, Quickswap',
      },
      {
        id: 12,
        link:'https://www.linkedin.com/in/cspr/',
        images:images.speaker8,
        speaker: 'Pruthvi Raj',
        designation: 'Lead Product Manager, IBC Media',
      },
      {
        id: 13,
        link:'https://www.linkedin.com/in/trang-ha-nguyen/',
        images:images.speaker11,
        speaker: 'Trang Ha Nguyen',
        designation: 'Research Lead, SubWallet',
      }
    ]
  }
    ]
    const settings = {
      
      
      slidesToShow: 4,
      speed: 500,
      slidesToScroll: 3,
      arrows: true,
      dots: false,
      navs: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows:false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows:false
          }
        },
      ],
    };
  return (
    <Slider {...settings}>
    {slideData[tabActice].speakers.map((speake, i) => {
      return (
        <div  key={i}>
          <a href={speake?.link} target='_blank'><img src={speake?.images} className='img-fluid' alt='Speaker-Img'/></a>
            <h6 className='h6-text'>{speake?.speaker}</h6>
            <p className='p-text'>{speake?.designation}</p>
        </div>
      )
    })}
        </Slider>
  )
}

export default AltSpeakers