import Cookies from 'js-cookie'
import { getRequest, postRequest } from '.'

const enrollInCep = async () => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = await postRequest('/cep/enroll', {
    userId: user.id
  })
  return res
}

const checkCepEnrollment = async () => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await getRequest(`/cep/checkEnrollmentStatus/${user.id}`)
  return res
}

export { enrollInCep, checkCepEnrollment }

