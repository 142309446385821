//import * as yup from 'yup'

export const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY
//disable registration
export const isRegistrationClosed = true
export const countries = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
]

export const STRINGS = {
  FORM_ERROR: 'Input value(s) are not valid. Please check.',
  POPUP_ERROR_TITLE: 'Error!',
  POPUP_SUCCESS_TITLE: 'Success!',
  SESSION_EXPIRED_MSG: 'Session Expired! Please login again',
  REQUIRED_ERROR: 'Please fill this field',
  EMAIL_ERROR: 'Email should be valid one!',
  PASSWORD_CONTAIN_SPACE_ERROR: 'Password should not contain space',
  PASSWORD_LENGTH_ERROR:
    'Password should contain minimum 6 and maximum 15 characters',
  CONFIRM_PASSWORD_ERROR: 'Passwords must match',
  FIRSTNAME_ERROR: 'First name should be valid',
  LASTNAME_ERROR: 'Last name should be valid',
  COUNTRY_ERROR: 'Please choose a valid country',
  ROLE_ERROR: 'Please choose a valid role',
  LOGGED_OUT: 'You are logged out successfully'
}

// export const VALIDATION_SCHEMA = {
//   email: yup
//     .string()
//     .required(STRINGS.REQUIRED_ERROR)
//     .email(STRINGS.EMAIL_ERROR),
//   password: yup
//     .string()
//     .required(STRINGS.REQUIRED_ERROR)
//     .test({
//       name: 'space',
//       exclusive: true,
//       message: STRINGS.PASSWORD_CONTAIN_SPACE_ERROR,
//       test: (value) => !value.includes(' '),
//     })
//     .min(6, STRINGS.PASSWORD_LENGTH_ERROR)
//     .max(15, STRINGS.PASSWORD_LENGTH_ERROR),
//   firstName: yup
//     .string()
//     .required(STRINGS.REQUIRED_ERROR)
//     .min(2)
//     .matches(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/, STRINGS.FIRSTNAME_ERROR),
//   lastName: yup
//     .string()
//     .required(STRINGS.REQUIRED_ERROR)
//     .min(2)
//     .matches(/^([A-Za-z]+)+[A-Za-z]+$|^[A-Za-z]+$/, STRINGS.LASTNAME_ERROR),
//   country: yup
//     .string()
//     .required(STRINGS.REQUIRED_ERROR)
//     .oneOf(
//       countries.map((country) => country.name),
//       STRINGS.COUNTRY_ERROR
//     ),
//   token: yup.string().required(),
//   role: yup.number().min(1, STRINGS.ROLE_ERROR),
// }

export const roleOptions = [
  {
    label: 'Student',
    value: '1'
  },
  {
    label: 'Developer',
    value: '2'
  },
  {
    label: 'Enterpreneur',
    value: '3'
  }
]

export const mentors = [
  {
    Name: 'Radhakrishna Dasari',
    LinkedIn: 'https://www.linkedin.com/in/radhakrishnadasari',
    'Image Link': 'https://ibc.media/assets/images/mentor/radhakrisna.jpg'
  },
  {
    Name: 'Anushank Dagar',
    LinkedIn: 'https://www.linkedin.com/in/anushank-dagar-a59460a8/',
    'Image Link': 'https://ibc.media/assets/images/mentor/anushank.jpg'
  },
  {
    Name: 'Gautam Dhameja',
    LinkedIn: 'https://www.linkedin.com/in/gautamdhameja/',
    'Image Link': 'https://ibc.media/assets/images/mentor/gautham.jpeg'
  },
  {
    Name: 'Omri Lachman',
    LinkedIn: 'https://www.linkedin.com/in/omrilachman/',
    'Image Link': 'https://ibc.media/assets/images/mentors-2.png'
  },
  {
    Name: 'Ashok Ranadive',
    LinkedIn: 'https://www.linkedin.com/in/ashoksranadive/',
    'Image Link': 'https://ibc.media/assets/images/mentor-6.png'
  },
  {
    Name: 'Steve Degosserie',
    LinkedIn: 'https://www.linkedin.com/in/stevedegosserie/',
    'Image Link': 'https://ibc.media/assets/images/mentor/steve.jpg'
  },
  {
    Name: 'Nikolaos K.',
    LinkedIn: 'https://www.linkedin.com/in/nikolaoskontakis/',
    'Image Link': 'https://ibc.media/assets/images/mentor/nikolas.jpg'
  },
  {
    Name: 'Pavel Hilman',
    LinkedIn: 'https://www.linkedin.com/in/pavelhilman/',
    'Image Link': 'https://ibc.media/assets/images/mentors-1.png'
  },
  {
    Name: 'Andrey Nayman',
    LinkedIn: 'https://www.linkedin.com/in/nayco/',
    'Image Link': 'https://ibc.media/assets/images/mentors-5.png'
  },
  {
    Name: 'Uri Ferruccio',
    LinkedIn: 'https://www.linkedin.com/in/uriferruccio/',
    'Image Link': 'https://ibc.media/assets/images/mentor-7.png'
  },
  {
    Name: 'Hartej Sawhney',
    LinkedIn: 'https://www.linkedin.com/in/hartej/',
    'Image Link': 'https://ibc.media/assets/images/mentor-8.png'
  },
  {
    Name: 'H.E. Gabriel Abed',
    LinkedIn: 'https://www.linkedin.com/in/gabrielabed/',
    'Image Link': 'https://ibc.media/assets/images/mentor-10.png'
  },
  {
    Name: 'Chennakesavan Selvaraju',
    LinkedIn: 'https://www.linkedin.com/in/chennakesavan-selvaraju-b6bb4bbb/',
    'Image Link': 'https://ibc.media/assets/images/mentor/chennakesavan.png'
  },
  {
    Name: 'Sugandh Rakha',
    LinkedIn: 'https://www.linkedin.com/in/sugandh/',
    'Image Link': 'https://ibc.media/assets/images/mentor/sugandhrakha.png'
  },
  {
    Name: 'Dharmen Dhulla',
    LinkedIn: 'https://www.linkedin.com/in/dharmen-dhulla-4109332/',
    'Image Link': 'https://ibc.media/assets/images/mentor/dharmendhulla.png'
  },
  {
    Name: 'Chris Lavery',
    LinkedIn: 'https://www.linkedin.com/in/lavery/',
    'Image Link': 'https://ibc.media/assets/images/mentor-9.png'
  },
  {
    Name: 'Jagadeesh Prasad Vegi',
    LinkedIn:
      'https://www.linkedin.com/in/jagadeesh-blockchain-engineer/?originalSubdomain=in',
    'Image Link': 'https://ibc.media/assets/images/mentor/jagdeeshprasad.png'
  },
  {
    Name: 'Vijay Bawra',
    LinkedIn: 'https://www.linkedin.com/in/vijaybawra/',
    'Image Link': 'https://ibc.media/assets/images/mentor/vijaybharwa.png'
  },
  {
    Name: 'Kartikey Kashyap',
    LinkedIn: 'https://www.linkedin.com/in/kartikey-kashyap-4431a550/',
    'Image Link': 'https://ibc.media/assets/images/mentor/kartikeykashyap.png'
  },
  {
    Name: 'Shveta Verma Nagraj',
    LinkedIn: 'https://www.linkedin.com/in/shveta-verma-nagraj-00b3844/',
    'Image Link': 'https://ibc.media/assets/images/mentor/ShvetaVerma.png'
  },
  {
    Name: 'Prasanna Lohar',
    LinkedIn: 'https://www.linkedin.com/in/prasannalohar/',
    'Image Link': 'https://ibc.media/assets/images/mentor/prasannalohar.png'
  },
  {
    Name: 'Sharat Chandra',
    LinkedIn: 'https://www.linkedin.com/in/sharatchandratechevangelist/',
    'Image Link': 'https://ibc.media/assets/images/mentor/sharatchandra.png'
  },
  {
    Name: 'Avneesh Bhatnagar',
    LinkedIn: 'https://www.linkedin.com/in/avneeshb/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Avneesh-Bhatnagar.png'
  },
  {
    Name: 'Mukesh Marodia',
    LinkedIn: 'https://www.linkedin.com/in/marodia/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Mukesh-Marodia.png'
  },
  {
    Name: 'Rachit Srivastava ',
    LinkedIn: 'https://www.linkedin.com/in/rachit-anand-srivastava-345307173/',
    'Image Link':
      'https://ibc.media/assets/images/mentor/Rachit-Anand-Srivastava.png'
  },
  {
    Name: 'Col Inderjeet Singh',
    LinkedIn: 'https://www.linkedin.com/in/inderbarara/',
    'Image Link':
      'https://ibc.media/assets/images/mentor/Col-(Dr.)-Inderjeet-Singh.png'
  },
  {
    Name: 'Srinivas Mahankali',
    LinkedIn: 'https://www.linkedin.com/in/srinivasindia/',
    'Image Link':
      'https://ibc.media/assets/images/mentor/Srinivas-Mahankali.png'
  },
  {
    Name: 'Ramesh Swaminathan',
    LinkedIn: 'https://www.linkedin.com/in/msramesh/',
    'Image Link':
      'https://ibc.media/assets/images/mentor/Ramesh-Swaminathan.png'
  },
  {
    Name: 'Laura Weindorf',
    LinkedIn: 'https://www.linkedin.com/in/lauraweindorf/',
    'Image Link': 'https://ibc.media/assets/images/mentor/weindorf.png'
  },
  {
    Name: 'Simon Bogdanowicz   ',
    LinkedIn: 'https://www.linkedin.com/in/Simonbogdanowicz/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Simon-Bogdanowicz.png'
  },
  {
    Name: 'Jeffrey Elliott   ',
    LinkedIn: 'https://www.linkedin.com/in/jeffrey-elliott-626427122/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Jeffrey-Elliott.png'
  },
  {
    Name: 'Sumit Manocha',
    LinkedIn: 'https://www.linkedin.com/in/manochasumit/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Sumit-Manocha.png'
  },
  {
    Name: 'Yara Elder',
    LinkedIn: 'https://www.linkedin.com/in/yara-elder/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Yara-Elder.png'
  },
  {
    Name: 'Zach Miller',
    LinkedIn: 'https://www.linkedin.com/in/zachary-miller-mu/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Zach-Miller.png'
  },
  {
    Name: 'Kim Savul',
    LinkedIn: 'https://www.linkedin.com/in/kim-savul-a1417716/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Kim-Savul.png'
  },
  {
    Name: 'Jeff J Hunter',
    LinkedIn: 'https://www.linkedin.com/in/jeffjhunter/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Jeff-J-Hunter.png'
  },
  {
    Name: 'Vladimir Velmeshev',
    LinkedIn: 'https://www.linkedin.com/in/velmeshev/',
    'Image Link':
      'https://ibc.media/assets/images/mentor/Vladimir-Velmeshev.png'
  },
  {
    Name: 'Prasanth Kalangi',
    LinkedIn: 'https://www.linkedin.com/in/kalangi/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Prasanth-Kalangi.png'
  },
  {
    Name: 'Maud Honner',
    LinkedIn: 'https://www.linkedin.com/in/maudhonner/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Maud-Honner.png'
  },
  {
    Name: 'Sasha Tsiatsera',
    LinkedIn: 'https://www.linkedin.com/in/sasha-tsiatsera/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Sasha-Tsiatsera.png'
  },
  {
    Name: 'Bryce Gilleland',
    LinkedIn: 'https://www.linkedin.com/in/bryce-gilleland-574b74a/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Bryce-Gilleland.png'
  },
  {
    Name: 'Tim Martinez',
    LinkedIn: 'https://www.linkedin.com/in/theinsideman/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Tim-Martinez.png'
  },
  {
    Name: 'Jack B.',
    LinkedIn: 'https://www.linkedin.com/in/jack-b-a0b53b64/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Jack-B.png'
  },
  {
    Name: 'Lisa Rubin',
    LinkedIn: 'https://www.linkedin.com/in/lerubin/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Lisa-Rubin.png'
  },
  {
    Name: 'Anitha Vadavatha',
    LinkedIn: 'https://www.linkedin.com/in/anithavadavatha/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Anitha-Vadavatha.png'
  },
  {
    Name: 'Delz Erinle',
    LinkedIn: 'https://www.linkedin.com/in/delzerinle/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Delz-Erinle.png'
  },
  {
    Name: 'Igor Bershadsky',
    LinkedIn: 'https://www.linkedin.com/in/igorbershadsky/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Igor-Bershadsky.png'
  },
  {
    Name: 'Oliver Gilbey',
    LinkedIn: 'https://www.linkedin.com/in/olivergilbey/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Oliver-Gilbey.png'
  },
  {
    Name: 'Alex Rusnak',
    LinkedIn: 'https://www.linkedin.com/in/alex-rusnak-147437a/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Alex-Rusnak.png'
  },
  {
    Name: 'CJ Hetherington',
    LinkedIn: 'https://www.linkedin.com/in/cjhweb3/',
    'Image Link': 'https://ibc.media/assets/images/mentor/CJ-Hetherington.png'
  },
  {
    Name: 'Conrad Brown',
    LinkedIn: 'https://www.linkedin.com/in/conradkbrown/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Conrad-Brown.png'
  },
  {
    Name: 'Samir Bandali',
    LinkedIn: 'https://www.linkedin.com/in/samirjafferbandali/',
    'Image Link': 'https://ibc.media/assets/images/mentor/samirbandali.png'
  },
  {
    Name: 'Abhilash Inumella',
    LinkedIn: 'https://www.linkedin.com/in/abhilash-inumella-89a92421/',
    'Image Link': 'https://ibc.media/assets/images/mentor/abhilash.jpg'
  },
  {
    Name: 'Madhavanmalolan .eth',
    LinkedIn: 'https://www.linkedin.com/in/madhavanmalolan/',
    'Image Link': 'https://ibc.media/assets/images/mentor/madhavan.jpg'
  },
  {
    Name: 'Constantin Kogan',
    LinkedIn: 'https://www.linkedin.com/in/constantin-kogan/',
    'Image Link': 'https://ibc.media/assets/images/mentor/Constantin-Kogan.png'
  },
  {
    Name: 'Subhash Karri',
    LinkedIn: 'https://www.linkedin.com/in/subhash-karri/',
    'Image Link': 'https://ibc.media/assets/images/mentor/subhash.jpg'
  },
  {
    Name: 'Sriharsha Karamchati',
    LinkedIn: 'https://www.linkedin.com/in/sriharsha-karamchati-0730947b/',
    'Image Link': 'https://ibc.media/assets/images/mentor/sriharsha.jpeg'
  },
  {
    Name: 'Sergey Kozlov',
    'Image Link': 'https://ibc.media/assets/images/mentor/kozlov.png'
  },
  {
    Name: 'Sergey Okishev',
    LinkedIn: 'https://www.linkedin.com/in/sergey-okishev-55a400159/',
    'Image Link': 'https://ibc.media/assets/images/mentor/sergey-1.png'
  },
  {
    Name: 'Nikolay Pasynkov',
    LinkedIn: 'https://www.linkedin.com/in/npasynkov/',
    'Image Link': 'https://ibc.media/assets/images/mentor/pasynkov.png'
  }
]

export const sponsers = [
  {
    'sponsors & partners': 'polkadot',
    links: 'https://polkadot.network/',
    'image link': 'https://ibc.media/assets/images/clients/polkadot.jpg'
  },
  {
    'sponsors & partners': 'chingari',
    links: 'https://chingari.io/post/latest',
    'image link': 'https://ibc.media/assets/images/clients/chingari.png'
  },
  {
    'sponsors & partners': 'fantom',
    links: 'https://fantom.foundation/',
    'image link': 'https://ibc.media/assets/images/clients/fantom-logo.png'
  },
  {
    'sponsors & partners': 'moralis',
    links: 'https://moralis.io/',
    'image link': 'https://ibc.media/assets/images/clients/moralis-logo.png'
  },
  {
    'sponsors & partners': 'nitro network',
    links: 'https://nitro.network/',
    'image link': 'https://ibc.media/assets/images/clients/nitro.png'
  },
  {
    'sponsors & partners': 'blockchain founders fund',
    links: 'https://blockchainff.com/',
    'image link': 'https://ibc.media/assets/images/clients/intro_pic.png'
  },
  {
    'sponsors & partners': 'tech mahindra',
    links: 'https://www.techmahindra.com/en-in/?f=587288891',
    'image link': 'https://ibc.media/assets/images/clients/tech.png'
  },
  {
    'sponsors & partners': 'federal',
    links: 'https://federal.money/',
    'image link': 'https://ibc.media/assets/images/clients/federal.png'
  },
  {
    'sponsors & partners': 'magic square',
    links: 'https://magicsquare.io/',
    'image link': 'https://ibc.media/assets/images/clients/magic-square.png'
  },
  {
    'sponsors & partners': 'kosmos',
    links: 'https://kosmos.vc/',
    'image link': 'https://ibc.media/assets/images/clients/kosmo.png'
  },
  {
    'sponsors & partners': 'robonomics',
    links: 'https://robonomics.network/',
    'image link': 'https://ibc.media/assets/images/clients/robonomics-logo1.png'
  },
  {
    'sponsors & partners': 'unbounded',
    links: 'https://unbounded.network/',
    'image link': 'https://ibc.media/assets/images/clients/unbounded.png'
  },
  {
    'sponsors & partners': 'op crypto',
    links: 'https://www.opcrypto.vc/',
    'image link': 'https://ibc.media/assets/images/clients/op.png'
  },
  {
    'sponsors & partners': 'unique',
    links: 'https://unique.network/',
    'image link': 'https://ibc.media/assets/images/logos/un_logo.png'
  },
  {
    'sponsors & partners': 'subsocial',
    links: 'https://subsocial.network/',
    'image link': 'https://ibc.media/assets/images/logos/Subsocial_Logo.jpg'
  },
  {
    'sponsors & partners': 'sinofy',
    links: 'https://sinofy.group/',
    'image link': 'https://ibc.media/assets/images/logos/sinofy.png'
  },
  {
    'sponsors & partners': 'phala',
    links: 'https://www.phala.network/en/',
    'image link': 'https://ibc.media/assets/images/logos/phalanetwork.png'
  },
  {
    'sponsors & partners': 'rmrk',
    links: 'https://www.rmrk.app/',
    'image link': 'https://ibc.media/assets/images/logos/rmrk.png'
  },
  {
    'sponsors & partners': 'dtc group',
    links: 'https://dtcgroup.io/',
    'image link': 'https://ibc.media/assets/images/logos/dtcgroup.png'
  }
]

export const genders = ['Male', 'Female']

export const codingSkills = [
  "Can't code",
  'Can code a bit',
  'Can code very fairly',
  'Can code very well'
]

export const technologyOption = [
  'Solidity',
  'Rust',
  'C++',
  'C',
  'JavaScript',
  'TypeScript',
  'Python',
  'Node JS',
  'C#',
  'SQL',
  'Assembly Language',
  'Haskell',
  'R',
  '.Net',
  'MATLAB',
  'Others'
]

export const describeOptions = [
  'Solo Hacker, who doesnt need a team',
  'Solo Hacker looking for a team',
  'Hacker with a team',
  'Hacker with a team recruiting more members'
]

export const learningData = [
  //Material = topic (in sheet)
  {
    courseTitle: 'Web3 Technology',
    courseId: 1,
    courseDescription:
      "Dive into the transformative realm of Web3 with our comprehensive course: Uncover the evolution of money, explore Bitcoin's mechanics, journey through the internet's evolution, and grasp blockchain's core concepts to navigate the decentralized landscape of emerging technologies.",
    courseSubjects: [
      {
        subjectId: 1,
        subjectTitle: 'Web3 Genesis',
        subjectDescription:
          'Explore the transformative Web3 landscape and its impact on emerging technologies, delving into the evolution of money, the mechanics of Bitcoin, and the paradigm shift from Web2 to Web3, unlocking the decentralized innovations that define our digital future.',
        subjectMaterial: [
          {
            materialTitle: 'The Birth of Financial Institutions (cont..)',
            materialId: 1,
            materialLink: 'https://youtube.com/embed/8a6JiADuAhc',
            quizLink: 'https://forms.gle/zGSf3AmzZaV9BHLS7'
          },

          {
            materialTitle: 'How Bitcoin works',
            materialId: 2,
            materialLink: 'https://youtube.com/embed/8DhLJ7CmBmg',
            quizLink:
              'https://docs.google.com/forms/d/e/1FAIpQLSfjBtjz_g0-_CYqER1KnQ2_FEeTY_ctBV-2Bx8fMKEvheoGPw/closedform'
          },
          {
            materialTitle: 'Web Journey since last 25 years',
            materialId: 3,
            materialLink: 'https://youtube.com/embed/wy3HcFyz2G4',
            quizLink: 'https://forms.gle/bW2bThAJhPQ2jP9MA'
          }
        ],
        subjectQuiz: [
          {
            quizLink: 'https://forms.gle/rh9kEvg3Q6VgG6eu8'
          }
        ]
      },
      {
        subjectId: 2,
        subjectTitle: 'Blockchain Anatomy',

        subjectDescription:
          'Our comprehensive course offers an insightful overview of blockchain and the technology that drives this disruption. Equip yourself with the knowledge about fundamentals of decentralized , intricacies of consensus mechanism, and the inner workings of blockchain technology in these viewer-friendly, specially curated learning sessions.',
        subjectMaterial: [
          {
            materialTitle: 'Blockchain: An Overview',
            materialId: 1,
            materialLink: 'https://www.youtube.com/embed/Tjq3pD1qhn4',
            quizLink: 'https://forms.gle/f16ZNNkQAchKBLxz8'
          },
          {
            materialTitle: 'Blockchain: How it works',
            materialId: 2,
            materialLink: 'https://www.youtube.com/embed/wWFxiYx5IPA',
            quizLink: 'https://forms.gle/V7ZEnyhHxXmVBJ7o7'
          }
        ],
        subjectQuiz: [
          {
            quizLink: 'https://forms.gle/RJQ4wqoyyp71t3EV8'
          }
        ]
      }
    ]
  },
  {
    courseTitle: 'Web3 Ecosystem',
    courseId: 2,
    courseDescription:
      "Master Web3 Dynamics: From Polkadot's Interoperability to DeFi Revolution and Oracles' Data Bridges - Revolutionize your understanding of blockchain's next evolution in this comprehensive course.",

    courseSubjects: [
      {
        subjectId: 1,
        subjectTitle: 'Polkadot Ecosystem',
        subjectDescription:
          'Dive into the dynamic world of Polkadot, learn about its path-breaking features. Equip yourself with an understanding of this next-gen blockchain platform enabling pioneering innovations such as cross-chain communication, interoperability and many more.',
        subjectMaterial: [
          {
            materialTitle: 'Introduction',
            materialId: 1,
            materialLink: 'https://www.youtube.com/embed/5m5fyK0AE9E'
          },
          {
            materialTitle: 'Substrate & Polkadot',
            materialId: 2,
            materialLink: 'https://www.youtube.com/embed/cs1o2nVfe7Q',
            quizLink: 'https://forms.gle/ebT8pnNezxDHarYM8'
          },
          {
            materialTitle: 'How to build a substrate chain',
            materialId: 3,
            materialLink: 'https://www.youtube.com/embed/RuxNtk6rro4'
          },
          {
            materialTitle: 'Ecosystem support',
            materialId: 4,
            materialLink: 'https://www.youtube.com/embed/1ov1DO-q1lA'
          },
          {
            materialTitle: 'Visit Dotinsights',
            materialId: 5,
            materialLink: 'https://www.youtube.com/embed/NuZr1303SGM'
          }
        ]
      },
      {
        subjectId: 2,
        subjectTitle: 'DeFi',
        subjectDescription:
          'Learn about Decentralized Finance (DeFi) where traditional financial services merge with decentralized blockchain technology. Explore DeFi protocols that are revolutionizing lending, trading and asset management through our clearly articulated expert sessions.',
        subjectMaterial: [
          {
            materialTitle: 'DeFi: An Introduction',
            materialId: 1,
            materialLink: 'https://www.youtube.com/embed/toy_YSxobBs'
          }
        ]
      },
      {
        subjectId: 3,
        subjectTitle: 'Oracles',
        subjectDescription:
          'Discover how oracles function as crucial data bridges, seamlessly connecting real-world information with smart contracts. Learn about the crucial role of oracles in enabling dynamic, real-time interactions between decentralized systems and external data sources.',
        subjectMaterial: [
          {
            materialTitle: 'Oracles: An Introduction',
            materialId: 1,
            materialLink: 'https://www.youtube.com/embed/bEG3CdHdErA'
          }
        ]
      }
    ]
  }
  // {
  //   courseTitle: 'Programming',
  //   courseId: 3,
  //   courseDescription:
  //     'Equip yourself with the rocket-fuel for your Web3 journey through an introduction to Rust Programming. Get an exposure to fundamentals of Rust concepts that will equip you with the skills to build robust and efficient decentralized applications.',
  //   courseSubjects: [
  //     {
  //       subjectId: 1,
  //       subjectTitle: 'Rust Programming',
  //       subjectDescription:
  //         'Dive into the world of Rust programming with our comprehensive introduction course. Learn the fundamental concepts and best practices of Rust, while building a strong foundation for developing safe, efficient, and robust software solutions.',
  //       subjectMaterial: [
  //         {
  //           materialTitle: 'Introduction to Rust Programming',
  //           materialId: 1,
  //           materialLink: ''
  //         }
  //       ]
  //     }
  //   ]
  // }
]
