import React from 'react'
import ProfileCard from '../components/ProfileCard'
import ProfileNavMobile from '../components/ProfileNavMobile'
import QuizAssesment from '../components/QuizAssesment'

const QuizAssementPage = () => {
  return (
    <div className='py-3'>
      <ProfileCard type='transparent' />

      <ProfileNavMobile />

      <QuizAssesment />
    </div>
  )
}

export default QuizAssementPage
