import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router'
import swal from 'sweetalert'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'

import { createTeam, updateTeam } from '../../../API/teamAPI'
import { logOut } from '../../../redux/loginLogout'

const PopupEditTeam = ({
  openModal,
  setOpenModal,
  fetchTeam,
  isUpdate,
  team = ''
}) => {
  const [teamName, setTeamName] = useState(team)
  const formRef = useRef(null)
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    // Loop over them and prevent submission

    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  const addTeam = async (e) => {
    e.preventDefault()

    try {
      const res = await createTeam({
        eventId: params?.id,
        teamName
      })
      swal('Team Created successfully.', '', 'success')
      fetchTeam()
      setOpenModal(false)
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        setOpenModal(false)
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  const updateTeamData = async (e) => {
    e.preventDefault()

    try {
      const res = await updateTeam({
        eventId: params?.id,
        teamName
      })
      swal('Team updated successfully.', '', 'success')
      fetchTeam()
      setOpenModal(false)
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        setOpenModal(false)
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  return (
    <div className={`popup ${openModal ? 'show' : ''}`}>
      <div className='popup--body'>
        <div className='card card-form-bg border-0 shadow-none'>
          <div className='card-body'>
            <h6 className='head-h6'>
              {isUpdate ? 'Edit Team' : 'Create Team'}
            </h6>
            <form
              className='row pt-3 pb-3 needs-validation'
              ref={formRef}
              onSubmit={isUpdate ? updateTeamData : addTeam}
              noValidate
            >
              <div className='col-12'>
                <label htmlFor='validationCustom01' className='form-label'>
                  {isUpdate ? 'Edit Team Name' : 'Add Team Name'}
                </label>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control'
                    id='validationCustom01'
                    placeholder='Type your team name here'
                    required
                    value={teamName}
                    onChange={(e) => {
                      setTeamName(e.target.value)
                    }}
                  />
                </div>
                <div className='invalid-feedback'>Please input Team Name!</div>
              </div>

              <div className='col-md-12'>
                <div className='d-flex align-items-center justify-content-center mt-5 pt-3'>
                  <button
                    onClick={() => setOpenModal(false)}
                    type='cancel'
                    className='btn btn-blue round-0 me-3'
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-blue round-0 '>
                    {isUpdate ? 'Update' : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupEditTeam

