import { getRequest, putRequest } from '.'
import Cookies from 'js-cookie'

const getEvents = async (eventId = '', eventType) => {
  const res = getRequest(`/getEvents?eventId=${eventId}&type=${eventType}`)
  return res
}

const getEventActivities = async (eventId, eventType) => {
  const res = getRequest(
    `/getEventActivities?eventId=${eventId}&type=${eventType}`
  )
  return res
}

const getEventTracks = async (eventId) => {
  const res = getRequest(`/eventTracks/${eventId}`)
  return res
}

const getEventThemes = async (trackId) => {
  const res = getRequest(`/eventThemes/${trackId}`)
  return res
}

const getEventPrograms = async (eventId) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = getRequest(`programSelection/${user.id}/${eventId}`)
  return res
}

const updateProgramSelection = async (body) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = await putRequest(`/programSelection/${user.id}`, body)
  return res
}

const updateIdea = async (body) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = await putRequest(`/ideaSubmission/${user.id}`, body)
  return res
}

const getIdea = async (eventId) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = getRequest(`ideaSubmission/${user.id}/${eventId}`)
  return res
}

export {
  getEvents,
  getEventActivities,
  getEventPrograms,
  getEventTracks,
  getEventThemes,
  updateProgramSelection,
  updateIdea,
  getIdea
}

