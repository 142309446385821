import React from 'react'
import { useSelector } from 'react-redux'

import SideNavLinks from '../components/SideNavLinks'

const Sidenav = () => {
  const userData = useSelector((state) => state.loginLogout.userData)

  return (
    <div className='sidenav d-flex flex-column justify-content-space-between'>
      <div className='card card-side-bg'>
        <div className='card-body'>
          <h3 className='text-center'>
            Hello! {userData?.firstName} {userData?.lastName}
          </h3>
        </div>
      </div>

      <div className='card card-side-bg-2 side-nav-links mt-3 position-relative'>
        <div className='card-body px-2 '>
          <SideNavLinks />
        </div>
      </div>
    </div>
  )
}

export default Sidenav

