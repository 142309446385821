import React from 'react'
import swal from 'sweetalert'
// import axios from 'axios'
// import qs from 'qs'
import moment from 'moment'
// import { useLocation } from 'react-router'

const Quizzes = () => {
  // const location = useLocation()

  //////Google Sheet API code for future//////
  // useEffect(() => {
  //   fetchDataFromSheet()
  // }, [])

  // const fetchDataFromSheet = async () => {
  //   console.log('google api key', process.env.REACT_APP_GOOGLE_SHEET_API_KEY)

  //   const params = {
  //     ranges: ['Sheet1'], // Replace with the names of your sheets
  //     key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY // Replace with your Google Sheets API key
  //   }

  //   try {
  //     const response = await axios.get(
  //       `https://docs.google.com/spreadsheets/d/e/2PACX-1vQsPkEndqVTLpW1wvGjenEkSG5yke5kJs6EgQ7WrulZdCUE4UFdnZsD-k_KwoUNYYVfLJdxAAnaGPib/pubhtml`,
  //       {
  //         paramsSerializer: (params) =>
  //           qs.stringify(params, { arrayFormat: 'repeat' }),
  //         params
  //       }
  //     )
  //     debugger

  //     const data = response.data.valueRanges.flatMap(
  //       (valueRange) => valueRange.values
  //     )
  //     return data
  //   } catch (error) {
  //     console.error('Error fetching data from Google Sheet:', error)
  //     debugger
  //     return null
  //   }
  // }

  const tableHeadsQuizzes = [
    'Topic Name',
    'Start Date & Time',
    'End Date & Time',
    'Quiz Link'
  ]

  // for ALT Hack Program
  const tableDataQuizzes = [
    {
      id: 1,
      topic: 'Alt Hack Delhi Quiz',
      startdate: '20/06/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink:
        'https://docs.google.com/forms/d/1BLiyo5PXlHUEGv2uUwoZktT1sOy0t3HXAB2W-GRO6UM/viewform'
    },
    {
      id: 2,
      topic: 'Alt Hack Delhi Quiz',
      startdate: '21/06/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink:
        'https://docs.google.com/forms/d/1akNZ3BmpTnnwEFNEgFCSzke5_LQQ1VA_tqRg3L1QlMg/viewform'
    },
    {
      id: 3,
      topic: 'Alt Hack Delhi Quiz',
      startdate: '22/06/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink:
        'https://docs.google.com/forms/d/1cUpgGEzlZgwT1EgT-qh1UwR-tpD1bwZnBe5ZZOtN2pU/viewform'
    },
    {
      id: 4,
      topic: 'Alt Hack Delhi Quiz',
      startdate: '23/06/2023',
      startime: '',
      enddate: '',
      endtime: '',
      quizLink:
        'https://docs.google.com/forms/d/1zzsu0q_qDQJuOZeU2D1GzngTk_94QipS_szwr7hDzg8/viewform'
    }
  ]

  const handleQuizLink = (quizLink) => {
    swal({
      text: 'Start your quiz to complete for a seat at IBC Alt Hack Delhi'
    }).then(function () {
      window.open(quizLink, '_blank')
    })
  }
  return (
    <div>
      <div className='quiz-page py-3'>
        <div className='card'>
          <div className='card-body px-3'>
            <div className='quiz-page__table'>
              <table className=''>
                <thead>
                  <tr>
                    {tableHeadsQuizzes.map((data, i) => {
                      return (
                        <th key={i} scope='col' className='right'>
                          {data}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableDataQuizzes.map((data, i) => {
                    const date = moment(data.startdate, 'DD/MM/YYYY')
                    const currentDate = moment()

                    const isMatchingDate = date.isSame(currentDate, 'day')
                    return (
                      <tr key={i}>
                        <td className='' data-label={tableHeadsQuizzes[0]}>
                          {data.topic}
                        </td>

                        <td
                          className=' right overflow-text'
                          data-label={tableHeadsQuizzes[1]}
                        >
                          <span className='trx-id'>
                            {data.startdate} {data.startime}
                          </span>
                        </td>

                        <td
                          className=' right overflow-text'
                          data-label={tableHeadsQuizzes[2]}
                        >
                          <span className='trx-id'>
                            {data.enddate} {data.endtime}
                          </span>
                        </td>

                        <td
                          className='right max-time'
                          data-label={tableHeadsQuizzes[3]}
                        >
                          {/* <a
                            target='_blank'
                            rel='noreferrer'
                            to={data.quizLink}
                            className='time-font'
                          >
                            Click Here
                          </a> */}

                          <p
                            className='time-font text-underline click-link'
                            onClick={() => {
                              if (isMatchingDate) {
                                handleQuizLink(data.quizLink)
                              }
                            }}
                            style={
                              isMatchingDate
                                ? { cursor: 'pointer' }
                                : { cursor: 'not-allowed' }
                            }
                          >
                            Click Here
                          </p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quizzes
