import React from 'react'
import { useNavigate } from 'react-router-dom'

const CepSuccessModal = ({ setIsSuccessModal }) => {
  const navigate = useNavigate()

  return (
    <div>
      <h1 className='text-center p-2 fs-2'>Enrolled Successfully.</h1>
      <p className='fs-6'>
        Your pre-learning repository has arrived! Check your email inbox
      </p>

      <div className='dfcenter'>
        <button
          className='btn btn-blue round-0 my-4 mx-auto w-100'
          onClick={() => {
            setIsSuccessModal(false)
            navigate('/dashboard/cep')
          }}
        >
          Proceed
        </button>
      </div>
    </div>
  )
}

export default CepSuccessModal

