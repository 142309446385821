import React from 'react'

const QuizModal = () => {
  return (
    <div className='modal quiz-modal' id='quiz-modal'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button type='button' className='close' data-dismiss='modal'>
              &times;
            </button>
          </div>
          <div className='modal-body'>
            <p>Start your quiz to compete for a seat at IBC Alt Hack Delhi.</p>
          </div>
          <div className='modal-footer'>
            <div className='d-flex align-items-center justify-content-center'>
              <button
                type='submit'
                className='btn btn-blue rounded-0 mt-3 mb-3 w-50'
              >
                Start Quiz
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuizModal
