import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { learningData } from '../../../utils/constants'

import img1 from '../../../assets/images/CEP-1.png'
import img2 from '../../../assets/images/CEP-2.png'
import img3 from '../../../assets/images/CEP-3.png'
import Topics from './Topics'
import { Accordion } from 'react-bootstrap'

const Subjects = () => {
  const location = useLocation()
  const params = useParams()

  console.log('params', params)
  const navigate = useNavigate()

  const imgs = [img1, img2, img3]

  const [isLoading, setIsLoading] = useState(true)
  const [subjects, setSubjects] = useState([])
  const [courseTitle, setCourseTitle] = useState('')

  useEffect(() => {
    if (params?.courseId) {
      const { courseId } = params
      const courseSubs = learningData.filter(
        (data) => data?.courseId === parseInt(courseId)
      )
      setSubjects(courseSubs[0]?.courseSubjects || [])
      setCourseTitle(courseSubs[0]?.courseTitle)
    }
  }, [])

  return (
    <div className='learning-page'>
      <h3
        className='fw-semibold fs-4 mb-4 ms-2'
        style={{
          color: '#121212'
        }}
      >
        {courseTitle}
      </h3>
      <div className=' m-0 p-0 course-overflow-div'>
        <Accordion defaultActiveKey={0} className='chapter-accordion'>
          {subjects?.map((data, index) => {
            return (
              <Accordion.Item
                eventKey={index}
                key={index}
                className='bg-white mb-4'
                style={{
                  border: '1px solid #E4E4E4'
                }}
              >
                <Accordion.Header className='bg-white chapter-accordion-header'>
                  <div className='d-flex align-items-center justify-content-between w-100'>
                    <p className='chapter-name fs-6 mb-0'>
                      Chapter: {data?.subjectId} : {data?.subjectTitle}
                    </p>
                    <div className='icon'>
                      <i className='fa-solid fa-angle-right'></i>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <p
                    className='px-4 py-2 mb-0 fs-14'
                    style={{
                      border: '1.25px solid #E4E4E4',
                      borderRight: 'none',
                      borderLeft: 'none'
                    }}
                  >
                    {data?.subjectDescription}
                  </p>
                  <div className='chapter-topics'>
                    <Topics
                      material={data?.subjectMaterial || []}
                      subjectId={data?.subjectId}
                      subjectQuiz={data?.subjectQuiz || []}
                      subjectCourseTitle={data?.subjectTitle}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}

export default Subjects
