import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { checkCepEnrollment } from '../API/cepAPI'
import { logOut } from '../redux/loginLogout'

export function useCepEnrollStatus() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isCepEnrolled, setIsCepEnrolled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getCepEnrollmentStatus = async () => {
    try {
      setIsLoading(true)
      const res = await checkCepEnrollment()
      const { data } = res

      setIsCepEnrolled(data?.isEnrolled)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token' ||
        error?.response?.data?.message ===
          'Issue with longer idle session or missing token, Please sign back in. Thank you.'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())
        navigate('/login')
      }
    }
  }

  useEffect(() => {
    getCepEnrollmentStatus()
  }, [])
  return {
    isCepEnrolled,
    isLoading,
    setIsCepEnrolled,
    getCepEnrollmentStatus
  }
}
