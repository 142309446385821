import React from 'react'
import { images } from '../Pictures/images'
import Agenda from '../components/Agenda'
import AltSpeakers from '../components/AltSpeakers'
import { Link } from 'react-router-dom'
import federalLogo from '../assets/images/federalLogo.png'
import nitroLogo from '../assets/images/nitroLogo.png'
import Footer from '../components/Footer'
import AltHackCarousel from '../components/AltHackCarousel'
import WhyAttendCarousel from '../components/views/WhyAttendCarousel'
import { isRegistrationClosed } from '../utils/constants'

const Home = () => {
  return (
    <>
      <div className='homepage'>
        {/* Banner section*/}
        <div className='banner'>
          <div className=' banner__grid'>
            <div className='banner__text'>
              <div className=''>
                <img
                  src={images.bannerText}
                  alt='Digital India Alt hack'
                  className='img-fluid'
                />
              </div>
              <p className='text-date'>19TH - 26TH JUNE 2023</p>

              <div className='mar-to mt-3 btn-mobile flex-column-mobile'>
                {isRegistrationClosed ? (
                  <button
                    type='button'
                    className='btn btn-light style-disabled'
                    disabled={isRegistrationClosed}
                  >
                    {!isRegistrationClosed
                      ? 'Register'
                      : 'Registrations closed'}
                  </button>
                ) : (
                  <Link
                    to='signup'
                    className='btn btn-light'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {!isRegistrationClosed
                      ? 'Register'
                      : 'Registrations closed'}
                  </Link>
                )}
              </div>
            </div>
            <div className='banner__img'>
              <img
                src={images.banner_svg}
                alt='Banner Person Working on desk'
              />
            </div>
          </div>
        </div>

        {/*Alt Hack Venue  */}
        <div className='alt-hack-venue '>
          <div className='alt-hack-venue__text '>
            <div className='text-end'>
              <p>19TH - 26TH JUNE 2023</p>
              <h2>VENUE FOR ALT HACK DELHI</h2>
            </div>

            <div className='venue-logo'>
              <img src={images.iitDelhiLogo} alt='IIT DELHI LOGO' />
              <h1>IIT DELHI</h1>
            </div>
          </div>
          <div className='alt-hack-venue__image '>
            <img src={images.iitDelhi} alt='IIT DELHI ' />
          </div>
        </div>

        <div className='container py-5'>
          <div className='border-top mx-auto'></div>
        </div>

        {/* Stats */}
        <div className='container expect'>
          <div className='row py-5 text-center'>
            <div className='col-lg-3 col-md-6 col-sm-12 py-3 '>
              <img src={images.ellipse1} alt='sponsor' className='img-fluid' />
              <h6>600+ Colleges</h6>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 py-3'>
              <img src={images.ellipse2} alt='sponsor' className='img-fluid' />
              <h6>40000+ Students</h6>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 py-3'>
              <img src={images.ellipse3} alt='sponsor' className='img-fluid' />
              <h6>50+ Thought Leaders</h6>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 py-3'>
              <img src={images.ellipse4} alt='sponsor' className='img-fluid' />
              <h6>30+ Partners</h6>
            </div>
          </div>
        </div>

        {/* What to expect */}
        <div className='container expect'>
          <div className='row py-5 '>
            <div className='col-lg-5 col-md-12'>
              <h4 className='display-text-mobile'>What to expect?</h4>
              <img
                src={images.eventImg}
                alt='event-img'
                className='img-fluid'
                style={{ width: '100%' }}
              />
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-6 col-md-12 d-flex align-items-center justify-content-center'>
              <div>
                <h4 className='display-text-desktop'>What to expect?</h4>
                <p className='pt-2'>
                  Digital India Alt Hack Delhi, is the fourth of Digital India
                  Alt Hack series, a full-cycle free Web3 education boot camp
                  designed exclusively for students.
                </p>
                 
                <p>
                  Digital India Alt Hack Delhi is the first step in your Web3
                  journey. Expect to learn, interact and get your foundational
                  Web3 knowledge from the industry’s best. This is the beginning
                  of IBC Media’s Internship Program which has multiple benefits
                  for students such as grants, take ideas to fruition and begin
                  your own startup and get recruited by topline global Web3 and
                  blockchain companies.
                </p>
              </div>
            </div>
          </div>

          <div className='row py-5 event mx-auto'>
            <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
              <div className='d-flex align-items-center justify-content-center'>
                <img
                  src={images.home_orientation}
                  loading='lazy'
                  alt='sponsor'
                  className='img-fluid '
                />

                <div className='ms-3'>
                  <h6>Orientation</h6>
                  <p>Web3 foundational learning </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
              <div className='d-flex align-items-center justify-content-center'>
                <img
                  src={images.home_education}
                  loading='lazy'
                  alt='Education'
                  className='img-fluid '
                />

                <div className='ms-3'>
                  <h6>Education</h6>
                  <p>Learn Web3 & blockchain concepts</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
              <div className='d-flex align-items-center justify-content-center'>
                <img
                  src={images.home_exploration}
                  loading='lazy'
                  alt='Exploration'
                  className='img-fluid '
                />

                <div className='ms-3'>
                  <h6>Exploration</h6>
                  <p>3 day immersive ideation on Web3 concepts</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
              <div className='d-flex align-items-center justify-content-center'>
                <img
                  src={images.home_panelDiscussions}
                  loading='lazy'
                  alt='Panel Discussion'
                  className='img-fluid '
                />

                <div className='ms-3'>
                  <h6>Panel Discussions</h6>
                  <p>Hear experts on the future of Web3</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
              <div className='d-flex align-items-center justify-content-center'>
                <img
                  src={images.home_ideation}
                  loading='lazy'
                  alt='ideation'
                  className='img-fluid pt-4'
                />

                <div className='ms-3'>
                  <h6>Ideation</h6>
                  <p>Explore your Web3 ideas on global tracks</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
              <div className='d-flex align-items-center justify-content-center'>
                <img
                  src={images.home_certification}
                  alt='sponsor'
                  className='img-fluid '
                />

                <div className='ms-3'>
                  <h6>Certification</h6>
                  <p>Best Ideas certification from IBC for 3 winning teams</p>
                </div>
              </div>
            </div>
          </div>

          <div className='row text-center py-5 '>
            <div className='col-md-12 py-3'>
              <a
                href='https://remarkable-team-111.notion.site/IBC-CONTINUOUS-EDUCATION-PROGRAM-IBC-CEP-7393642000a24825af0ad79a43330961'
                target='_blank'
                rel='noreferrer'
                className='btn btn-pink'
              >
                Access Repository
              </a>
            </div>
          </div>
        </div>

        {/*Speakers*/}
        <div className='container-fluid expect'>
          <div className='border-top mx-auto'></div>
          <div className='row py-5'>
            <div className='card speakers-bg rounded-0'>
              <div className='card-body'>
                <div className='container '>
                  <div>
                    <h3 className='mb-5 text-white'>Speakers</h3>
                    <AltSpeakers />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Why Attend */}
        <div className='container expect'>
          <div className='row py-5'>
            <h5>Why Attend?</h5>

            <WhyAttendCarousel />

            <div className='row py-5 event mx-auto'>
              <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    src={images.home_upskill}
                    alt='sponsor'
                    className='img-fluid '
                  />

                  <div className='ms-3'>
                    <p>Upskill in emerging technologies</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    src={images.home_web3developer}
                    loading='lazy'
                    alt='Web # developer'
                    className='img-fluid '
                  />

                  <div className='ms-3'>
                    <p>Become industry-ready Web3 developer</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    src={images.home_network}
                    alt='network expert'
                    loading='lazy'
                    className='img-fluid '
                  />

                  <div className='ms-3'>
                    <p>Network with experts & thought leaders</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    src={images.home_web3ideas}
                    loading='lazy'
                    alt='Web 3 ideas'
                    className='img-fluid '
                  />

                  <div className='ms-3'>
                    <p>A platform for your Web3 ideas</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    src={images.home_certified}
                    loading='lazy'
                    alt='get Certified'
                    className='img-fluid '
                  />

                  <div className='ms-3'>
                    <p>
                      Get Certified for Best Ideas from IBC Media and Govt,
                      Skill Board
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12 py-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    src={images.home_mentorship}
                    loading='lazy'
                    alt='Expert mentorship'
                    className='img-fluid '
                  />

                  <div className='ms-3'>
                    <p>Expert mentorship and guidance</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row text-center py-5'>
              <div className='col-md-12 py-3'>
                <p>19th June - 26th June 2023, @ IIT Delhi campus</p>

                {isRegistrationClosed ? (
                  <button
                    type='button'
                    className='btn btn-light style-disabled'
                    disabled={isRegistrationClosed}
                  >
                    {!isRegistrationClosed
                      ? 'Register'
                      : 'Registrations closed'}
                  </button>
                ) : (
                  <Link
                    to='signup'
                    className='btn btn-pink'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {!isRegistrationClosed
                      ? 'Register Now'
                      : 'Registrations closed'}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Agenda*/}
        <div className='expect agenda-section'>
          <div className='border-top mx-auto'></div>
          <div className='row py-5'>
            <h3 className='mb-5 text-center text-white'>Agenda</h3>
            <div className='col-lg-12 col-sm-12 col-md-12'>
              <Agenda />
            </div>
          </div>
        </div>

        {/* Sponsers nad Partners*/}
        <div className='container expect'>
          <div className='border-top mx-auto'></div>
          <div className='row py-5'>
            <div className='col-md-6'>
              <h3 className='mb-4 text-center'>Headline Sponsor</h3>
              {/* Sponsors */}
              <div className='col-md-12 py-3'>
                <div className='text-center circle-img d-flex flex-md-column flex-lg-row align-items-center justify-content-around'>
                  <img
                    src='https://ibc.media/assets/images/clients/polkadot.jpg'
                    alt='sponsor'
                    className='img-fluid mb-3'
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <h3 className='mb-4 text-center'>Sponsors & Partners</h3>
              {/* Sponsors */}
              <div className='col-md-12 py-3'>
                <div className='text-center circle-img d-flex flex-md-column flex-lg-row align-items-center justify-content-around'>
                  <img
                    src={federalLogo}
                    alt='sponsor'
                    className='img-fluid mt-1 mb-3'
                  />
                  <img
                    src={nitroLogo}
                    alt='sponsor'
                    className='img-fluid mt-3 '
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* AltHack Success Stories*/}
        <div className='border-top mx-auto'></div>
        <div className='container expect py-5'>
          <h3 className='text-center pt-3'>AltHack Success Stories</h3>
          <AltHackCarousel />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  )
}

export default Home
