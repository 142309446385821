import React, { useRef, useState, useEffect } from 'react'
import { Country, State, City } from 'country-state-city'
import swal from 'sweetalert'
import { toast } from 'react-toastify'
import { getWorkExperience, updateWorkExperience } from '../../../API/userAPI'
import Cookies from 'js-cookie'
import { logOut } from '../../../redux/loginLogout'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

const WorkExperience = ({ setTabActive, getPercentage }) => {
  const formRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [country, setCountry] = useState('')
  const [countryState, setCountryState] = useState('')
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [touched, setTouched] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)

  const [formValues, setFormValues] = useState({
    designation: '',
    sector: '',
    organization: '',
    fromYear: '',
    toYear: '',
    country: '',
    state: '',
    city: ''
  })

  const countries = Country.getAllCountries().map((val) =>
    Object.assign({}, { label: val.name, value: val.isoCode })
  )

  useEffect(() => {
    fetchWorkExp()
  }, [])

  const fetchWorkExp = async () => {
    try {
      const res = await getWorkExperience()
      const { data } = res

      if (data?.data && data?.data.length !== 0) {
        setFormValues({
          ...formValues,
          ...data?.data
        })
      } else {
        setIsEmpty(true)
      }
    } catch (error) {
      debugger
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }
    }
  }

  useEffect(() => {
    // Loop over them and prevent submission

    if (formRef) {
      formRef?.current?.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  useEffect(() => {
    if (formValues.country && formValues.country !== '') {
      if (!country) {
        let cntry = countries.filter(
          (country) => formValues.country === country.label
        )

        setCountry(cntry[0].value)
      }

      let temp = []

      State.getStatesOfCountry(country).forEach((val) => {
        temp.push({ label: val.name, value: val.isoCode })
      })

      setStates([...temp])
    }
  }, [formValues.country, country])

  useEffect(() => {
    if (!countryState) {
      let states = []

      State.getStatesOfCountry(country).forEach((val) => {
        states.push({ label: val.name, value: val.isoCode })
      })

      let state = states.filter((sta) => sta.label === formValues.state)

      setCountryState(state[0]?.value)
    }

    if (countryState && country) {
      let temp = []

      City.getCitiesOfState(country, countryState).forEach((val) => {
        temp.push({ label: val.name, value: val.name })
      })

      setCities([...temp])
    }
  }, [formValues.state, countryState, country])

  const handleChange = (e) => {
    setTouched(true)
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const updateWorkExp = async (e) => {
    e.preventDefault()

    const fromYear = parseInt(formValues?.fromYear)
    const toYear = parseInt(formValues?.toYear)

    if (!isEmpty) {
      if (!touched) {
        setTabActive(2)
        return false
      }

      if (fromYear > toYear) {
        swal('From year should be less than to year.', '', 'warning')
        return false
      }
    }

    try {
      const res = await updateWorkExperience({ ...formValues })

      swal('Work experience updated.', '', 'success')
      setTabActive(2)
      getPercentage()
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
        return false
      }

      const { response } = error

      swal(`${response?.data?.error?.message || 'Update failed.'}`, '', 'error')
    }
  }

  return (
    <div className='multistep-form'>
      <div className='container py-2'>
        <div className='card card-form-bg shadow-none border-0'>
          <div className='card-body'>
            <form
              className='row g-3 needs-validation'
              noValidate
              ref={formRef}
              onSubmit={updateWorkExp}
            >
              <div className='col-md-6'>
                <label htmlFor='validationCustom01' className='form-label'>
                  Designation
                </label>

                <input
                  type='text'
                  className='form-control'
                  id='validationCustom01'
                  name='designation'
                  value={formValues.designation}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input Designation!
                </div>
              </div>
              <div className='col-md-6'>
                <label htmlFor='validationCustom02' className='form-label'>
                  Sector
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='validationCustom02'
                  name='sector'
                  value={formValues.sector}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your Sector!
                </div>
              </div>
              <div className='col-md-12'>
                <label htmlFor='validationCustom03' className='form-label'>
                  Organization
                </label>

                <input
                  type='text'
                  className='form-control'
                  id='validationCustom03'
                  name='organization'
                  value={formValues.organization}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your Organization!
                </div>
              </div>
              <div className='col-md-6'>
                <label htmlFor='validationCustom04' className='form-label'>
                  From Year
                </label>
                <input
                  type='number'
                  className='form-control'
                  id='validationCustom03'
                  name='fromYear'
                  value={formValues.fromYear}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your From Year!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='validationCustom05' className='form-label'>
                  To Year
                </label>
                <input
                  type='number'
                  className='form-control'
                  id='validationCustom05'
                  name='toYear'
                  value={formValues.toYear}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your To Year!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='country' className='form-label'>
                  Country
                </label>
                <select
                  className='form-select mb-2 form-control'
                  id='country'
                  value={formValues?.country}
                  defaultValue={country}
                  onChange={(e) => {
                    e.preventDefault()
                    setTouched(true)
                    const selectedOption =
                      e.target.options[e.target.selectedIndex]
                    const selectedCountryCode =
                      selectedOption.getAttribute('data-country-code')

                    setCountry(selectedCountryCode)
                    setFormValues((prev) => ({
                      ...prev,
                      country: e.target.value,
                      state: '',
                      city: ''
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    Select Country
                  </option>
                  {countries.map((cntry, i) => (
                    <option
                      key={i}
                      value={cntry.label}
                      data-country-code={cntry?.value}
                      selected={formValues.country === cntry.label}
                    >
                      {cntry.label}
                    </option>
                  ))}
                </select>
                <div className='invalid-feedback'>
                  Please input your Country Name!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='state' className='form-label'>
                  State
                </label>
                <select
                  className='form-select form-select-lg mb-2 form-control'
                  aria-label='.form-select-lg'
                  id='state'
                  value={formValues.state}
                  defaultValue={formValues?.state}
                  onChange={(e) => {
                    e.preventDefault()
                    setTouched(true)
                    const selectedOption =
                      e.target.options[e.target.selectedIndex]
                    const selectedStateCode =
                      selectedOption.getAttribute('data-state-code')
                    setCountryState(selectedStateCode)
                    setFormValues((prev) => ({
                      ...prev,
                      state: e.target.value,
                      city: ''
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    State
                  </option>

                  {states.map((state, i) => (
                    <option
                      key={i}
                      value={state.label}
                      data-state-code={state.value}
                    >
                      {state.label}
                    </option>
                  ))}
                </select>
                <div className='invalid-feedback'>
                  Please input your State Name!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='city' className='form-label'>
                  City
                </label>

                <select
                  className='form-select form-select-lg mb-2 form-control'
                  aria-label='.form-select-lg example'
                  id='city'
                  value={formValues.city}
                  defaultValue={formValues?.city}
                  name='city'
                  onChange={(e) => {
                    e.preventDefault()
                    setTouched(true)
                    setFormValues((prev) => ({
                      ...prev,
                      city: e.target.value
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    City
                  </option>

                  {cities.map((city, i) => (
                    <option key={i} value={city.label}>
                      {city.label}
                    </option>
                  ))}
                </select>
                <div className='invalid-feedback'>
                  Please input your City Name!
                </div>
              </div>
              <div className='col-md-12'>
                <button
                  type='submit'
                  className='btn btn-blue round-0 float-end'
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkExperience

