import React, { createRef, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import swal from 'sweetalert'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'

import { images } from '../Pictures/images'
import { userLogin, verifyUser } from '../API/authAPI'
import { login, setUserData } from '../redux/loginLogout'
import { isRegistrationClosed } from '../utils/constants'

const Login = () => {
  const captchaRef = useRef()
  const formRef = useRef(null)
  const passwordEyeRef = useRef(null)
  const passwordInputRef = useRef(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [catpchaErr, setCatchaErr] = useState('')
  const location = useLocation()

  useEffect(() => {
    if (captchaRef) {
      captchaRef?.current?.props?.grecaptcha?.reset()
    }
  }, [captchaRef])

  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    verify()

    // setTimeout(() => {
    //   window.grecaptcha.render('recaptcha-contact', {
    //     sitekey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
    //     callback: function (resp) {}
    //   })
    // }, 300)
  }, [])

  const verify = async () => {
    const url = new URL(window?.location?.href || location?.href)

    let searchParamns = url.searchParams

    if (searchParamns.has('token')) {
      try {
        const res = await verifyUser(searchParamns?.get('token'))

        swal('User Verified', '', 'success')
      } catch (err) {
        swal(
          `${err?.response?.data?.error?.message || 'Unable to verify.'}`,
          '',
          'error'
        )
      }
    }
  }

  useEffect(() => {
    // Loop over them and prevent submission

    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (
            !formRef.current.checkValidity() ||
            !captchaRef?.current?.getValue()
          ) {
            event.preventDefault()
            event.stopPropagation()
            captchaRef?.current?.props?.grecaptcha?.reset()
          }

          if (!captchaRef?.current?.getValue()) {
            setCatchaErr('Catpcha Required!')
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    token: ''
  })

  const handleChange = (e) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [e.target.name]: e.target.value
    }))

    if (formRef.current.checkValidity()) {
      setIsDisabled(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (formValues.password.length < 6) {
      swal('Password must be at least 6 characters', '', 'warning')
      return false
    }

    if (formValues.password.length > 15) {
      swal('Password must be at less than 15 characters', '', 'warning')
      return false
    }

    try {
      const res = await userLogin({
        ...formValues
      })

      const { data } = res

      dispatch(login({ data: { ...data?.data } }))

      //dispatch(setUserData({ data: { ...data?.data } }))
      let userData = JSON.stringify(data.data)
      Cookies.set('alt-hack-user', userData, { expires: 2 / 24 })
      Cookies.set('isUserLoggedIn', true, {
        expires: 2 / 24
      })

      swal('Login successful', '', 'success')
      navigate('/dashboard/profile-settings')
    } catch (err) {
      swal(`${err?.response?.data?.message || 'Unable to login.'}`, '', 'error')
      captchaRef?.current?.props?.grecaptcha?.reset()
    }
  }

  const changeType = () => {
    if (passwordInputRef.current.type === 'password') {
      passwordInputRef.current.type = 'text'
      let eye = passwordEyeRef.current

      eye.classList.remove('fa-eye-slash')
      eye.classList.add('fa-eye')
    } else {
      passwordInputRef.current.type = 'password'

      let eye = passwordEyeRef.current
      eye.classList.remove('fa-eye')
      eye.classList.add('fa-eye-slash')
    }
  }

  return (
    <>
      <div className='login-page d-flex justify-content-center align-items-center'>
        <div className='container py-5'>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='card card-form-bg border-0'>
                  <div className='card-body'>
                    <Link to='/'>
                      <img
                        src={images.logoBlack}
                        alt='logo'
                        className='img-fluid'
                      />
                    </Link>
                    <h2 className='text-center my-4'>Sign In</h2>
                    <form
                      className='row g-3 needs-validation px-4'
                      onSubmit={handleSubmit}
                      noValidate
                      ref={formRef}
                    >
                      <div className='col-12 input-group mb-3'>
                        <label
                          htmlFor='validationCustom01'
                          className='form-label'
                        ></label>

                        <input
                          type='email'
                          className='form-control'
                          placeholder='Email'
                          id='validationCustom01'
                          required
                          name='email'
                          onChange={handleChange}
                          value={formValues.email}
                        />
                        <button className='btn btn-icon' type='button'>
                          {/* <img
                            src={images.envolopeIcon}
                            alt='icon'
                            className='img-fluid'
                          /> */}
                          <i className='fa-regular fa-envelope'></i>
                        </button>

                        <div className='invalid-feedback'>
                          Please input your Email Id!
                        </div>
                      </div>
                      <div className='col-12 input-group mb-3'>
                        <label
                          htmlFor='validationCustom02'
                          className='form-label'
                        ></label>
                        <input
                          type='password'
                          ref={passwordInputRef}
                          className='form-control'
                          placeholder='Password'
                          id='validationCustom02'
                          required
                          name='password'
                          onChange={handleChange}
                          value={formValues.password}
                        />
                        <button
                          className='btn btn-icon'
                          type='button'
                          onClick={changeType}
                        >
                          <i
                            className='fa-solid fa-eye-slash'
                            ref={passwordEyeRef}
                          ></i>
                        </button>
                        <div className='invalid-feedback'>
                          Please input your Password!
                        </div>
                      </div>
                      <div className='col-12 input-group'>
                        <div className='d-flex align-items-center justify-content-center mt-3 w-100'>
                          <label
                            htmlFor='validationCustom02'
                            className='form-label'
                          ></label>

                          <ReCAPTCHA
                            ref={captchaRef}
                            sitekey={captchaSiteKey}
                            onChange={(token) => {
                              setCatchaErr('')
                              setFormValues((prevFormValues) => ({
                                ...prevFormValues,
                                token
                              }))
                            }}
                            className='form_recaptcha '
                          />
                        </div>
                        {catpchaErr ? (
                          <p
                            className='text-red text-center w-100 mt-2'
                            style={{
                              color: '#dc3545'
                            }}
                          >
                            Invalid Catpcha
                          </p>
                        ) : null}
                      </div>

                      <div className='col-12 text-center'>
                        <button
                          type='submit'
                          className='btn btn-blue rounded-0 mt-3 mb-3 w-50'
                          disabled={isDisabled}
                        >
                          Sign In
                        </button>
                      </div>

                      {!isRegistrationClosed && (
                        <div className='col-12'>
                          <h6 className='text-center'>
                            Don’t have an account?{' '}
                            <Link
                              to='/signup'
                              className='link-black text-underline'
                            >
                              Sign Up here
                            </Link>
                          </h6>
                        </div>
                      )}
                      <div className='col-12'>
                        <h6 className='text-center'>
                          <Link
                            to='/forget-password'
                            className='link-black text-underline'
                          >
                            Forgot Password
                          </Link>
                        </h6>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3'></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
