import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'

import { images } from '../Pictures/images'
import { sendForgetEmail } from '../API/authAPI'

const ForgetPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const formRef = useRef(null)

  useEffect(() => {
    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await sendForgetEmail({
        email
      })

      swal('A verification link has been sent.', '', 'success')
      navigate('/login')
    } catch (error) {
      const { response } = error

      swal(
        `${response?.data?.message || 'Unable to send verification link.'}`,
        '',
        'error'
      )
    }
  }

  return (
    <>
      <div className='login-page d-flex justify-content-center align-items-center'>
        <div className='container py-5'>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='card card-form-bg border-0'>
                  <div className='card-body'>
                    <Link to='/'>
                      <img
                        src={images.logoBlack}
                        alt='logo'
                        className='img-fluid'
                      />
                    </Link>
                    <h2 className='text-center my-4'>
                      Enter email to reset your password
                    </h2>
                    <form
                      className='row g-3 needs-validation px-4'
                      onSubmit={handleSubmit}
                      noValidate
                      ref={formRef}
                    >
                      <div className='col-12 input-group mb-3'>
                        <label
                          htmlFor='validationCustom01'
                          className='form-label'
                        ></label>

                        <input
                          type='email'
                          className='form-control'
                          placeholder='Email'
                          id='validationCustom01'
                          required
                          name='email'
                          onChange={(e) => {
                            setEmail(e.target.value)
                          }}
                          value={email}
                        />
                        <button className='btn btn-icon' type='button'>
                          {/* <img
                            src={images.envolopeIcon}
                            alt='icon'
                            className='img-fluid'
                          /> */}
                          <i className='fa-regular fa-envelope'></i>
                        </button>

                        <div className='invalid-feedback'>
                          Please input your Email Id!
                        </div>
                      </div>

                      <div className='col-12 text-center'>
                        <button
                          type='submit'
                          className='btn btn-blue rounded-0 mt-3 mb-3 w-50'
                          //disabled={isDisabled}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3'></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPassword

