import React, { useRef, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import swal from 'sweetalert'

import { images } from '../Pictures/images'
import { updateForgetPassword, verifyForgetToken } from '../API/authAPI'

const ResetPassword = () => {
  const params = useParams()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const [userID, setUserID] = useState(null)
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: ''
  })

  useEffect(() => {
    if (params?.id && params?.id !== '') {
      verify()
    } else {
      navigate('/login')
    }
  }, [params])

  const verify = async () => {
    try {
      const res = await verifyForgetToken(params?.id || '')
      const { data } = res
      setUserID(data?.data?.id)

      swal(`Email verified successfully`, '', 'success')
    } catch (err) {
      const { response } = err

      swal(
        `${response?.data?.error?.message || 'Unable to verify'}`,
        '',
        'error'
      )
      navigate('/login')
    }
  }

  useEffect(() => {
    const eyeIcons = document.getElementsByClassName('password-eye')

    const handleClick = (e) => {
      e.preventDefault()

      let refInput = document.getElementById(
        e.currentTarget.attributes['data-input'].value
      )
      let refEye = document.getElementById(
        e.currentTarget.attributes['data-eye'].value
      )

      if (refInput.type === 'password') {
        refInput.type = 'text'
        refEye.classList.remove('fa-eye-slash')
        refEye.classList.add('fa-eye')
      } else {
        refInput.type = 'password'
        refEye.classList.remove('fa-eye')
        refEye.classList.add('fa-eye-slash')
      }
    }

    for (let i = 0; i < eyeIcons.length; i++) {
      eyeIcons[i].addEventListener('click', handleClick)
    }

    return () => {
      for (let i = 0; i < eyeIcons.length; i++) {
        eyeIcons[i].removeEventListener('click', handleClick)
      }
    }
  }, [])

  useEffect(() => {
    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  const handlechange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (formValues.password !== formValues.confirmPassword) {
      swal(`Password and Confirm Password should be same`, '', 'error')
      return false
    }
    if (
      formValues.password.length < 6 ||
      formValues.confirmPassword.length < 6
    ) {
      swal('Password must be at least 6 characters', '', 'warning')
      return false
    }

    if (
      formValues.password.length > 15 ||
      formValues.confirmPassword.length > 15
    ) {
      swal('Password must be at less than 15 characters', '', 'warning')
      return false
    }

    try {
      const res = await updateForgetPassword(userID, params?.id, {
        password: formValues.password
      })
      const { data } = res

      swal(`${data?.message || 'Password Updated'}`, '', 'success')
      navigate('/login')
    } catch (err) {
      const { response } = err

      if (err?.response?.data?.errors?.length) {
        swal(
          `${err?.response?.data?.errors[0]?.message || 'Unable to update.'}`,
          '',
          'error'
        )
        return false
      }

      swal(
        `${response?.data?.error?.message || 'Unable to update password.'}`,
        '',
        'error'
      )
    }
  }

  return (
    <>
      <div className='login-page d-flex justify-content-center align-items-center'>
        <div className='container py-5'>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='card card-form-bg border-0'>
                  <div className='card-body'>
                    <Link to='/'>
                      <img
                        src={images.logoBlack}
                        alt='logo'
                        className='img-fluid'
                      />
                    </Link>
                    <h2 className='text-center my-4'>Reset Password</h2>
                    <form
                      className='row g-3 needs-validation px-4'
                      onSubmit={handleSubmit}
                      noValidate
                      ref={formRef}
                    >
                      <div className='col-md-12'>
                        <div className='input-group mt-4'>
                          <label
                            htmlFor='validationCustom01'
                            className='form-label'
                          ></label>
                          <input
                            type='password'
                            //ref={passwordInputRef}
                            className='form-control'
                            placeholder='Password*'
                            id='password'
                            required
                            name='password'
                            value={formValues.password}
                            onChange={handlechange}
                          />
                          <button
                            className='btn btn-icon password-eye'
                            type='button'
                            data-input='password'
                            data-eye='password-eye'
                            //onClick={changeType}
                          >
                            <i
                              className='fa-solid fa-eye-slash'
                              id='password-eye'
                            ></i>
                          </button>
                        </div>
                        <div className='invalid-feedback'>Required!</div>
                      </div>
                      <div className='col-md-12'>
                        <div className='input-group mt-4'>
                          <label
                            htmlFor='validationCustom02'
                            className='form-label'
                          ></label>
                          <input
                            type='password'
                            className='form-control'
                            placeholder='Confirm Password*'
                            id='confirm-password'
                            required
                            name='confirmPassword'
                            value={formValues.confirmPassword}
                            onChange={handlechange}
                          />
                          <button
                            className='btn btn-icon password-eye'
                            data-input='confirm-password'
                            type='button'
                            data-eye='confirm-pasword-eye'
                            //onClick={changeType}
                          >
                            <i
                              className='fa-solid fa-eye-slash'
                              id='confirm-pasword-eye'
                            ></i>
                          </button>
                        </div>

                        <div className='invalid-feedback'>
                          Confirm Your Password!
                        </div>
                      </div>

                      <div className='col-12 text-center'>
                        <button
                          type='submit'
                          className='btn btn-blue rounded-0 mt-3 mb-3 w-50'
                          //disabled={isDisabled}
                        >
                          Submit
                        </button>
                      </div>
                      <div className='col-12'>
                        <h6 className='text-center'>
                          Already have an account?{' '}
                          <Link
                            to='/login'
                            className='link-black text-underline'
                          >
                            Login here
                          </Link>
                        </h6>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3'></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword

