import React from 'react'
import { NavLink } from 'react-router-dom'

const ProfileNavMobile = () => {
  return (
    <div className='buttons my-4'>
      <NavLink to='/dashboard/profile-settings' className='btn btn-blue'>
        Edit Profile
      </NavLink>
      <NavLink to='/dashboard/qrcode' className='btn btn-blue '>
        QR Code
      </NavLink>
      <NavLink to='/dashboard/assesment' className='btn btn-blue '>
        CEP
      </NavLink>
    </div>
  )
}

export default ProfileNavMobile
