import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { getEvents } from '../API/eventsAPI'
import Popup from '../components/Popup'
import CepSuccessModal from '../components/views/Cep/CepSuccessModal'
import CepTerms from '../components/views/Cep/CepTerms'
import { usePopup } from '../hooks/usePopup'
import { enrollInCep } from '../API/cepAPI'

import cepImg from '../assets/images/cepImage.png'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { logOut } from '../redux/loginLogout'
import { useCepEnrollStatus } from '../hooks/useCepEnroll'

const CepLanding = () => {
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isWarning, setIsWarning] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // const [isEnrolled, setIsEnrolled] = useState(false)
  //We need this status at other places so custom hook
  const { isCepEnrolled, setIsCepEnrolled, getCepEnrollmentStatus } =
    useCepEnrollStatus()

  const { isShowing: isTermsShowing, setIsShowing: setIsTermsShowing } =
    usePopup()
  const { isShowing: isSuccessModal, setIsShowing: setIsSuccessModal } =
    usePopup()

  useEffect(() => {
    getIBCEvents()
    // getCepEnrollmentStatus()
  }, [])

  const handleEnrolling = () => {
    setIsTermsShowing(true)
  }

  const getIBCEvents = async () => {
    try {
      setIsLoading(true)
      const res = await getEvents('', 'CEP')
      const { data } = res
      setEvents([...data?.data])
      setIsLoading(false)
      getCepEnrollmentStatus()
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setIsWarning(true)
    }
  }

  const enroll = async () => {
    try {
      const res = await enrollInCep()
      // setIsEnrolled(true)
      setIsCepEnrolled(true)
      setIsTermsShowing(false)
      setIsSuccessModal(true)
    } catch (error) {
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token' ||
        error?.response?.data?.message ===
          'Issue with longer idle session or missing token, Please sign back in. Thank you.'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())
        navigate('/login')
      }
    }
  }

  return (
    <div className='alt-hack-landing py-3'>
      {/* It Shows Terms Popup */}
      {isTermsShowing && (
        <Popup openModal={isTermsShowing} setOpenModal={setIsTermsShowing}>
          <CepTerms setOpenModal={setIsTermsShowing} enroll={enroll} />
        </Popup>
      )}

      {/*  Its Show Enrolled Success Popup */}
      {isSuccessModal && (
        <Popup openModal={isSuccessModal} setOpenModal={setIsSuccessModal}>
          <CepSuccessModal setIsSuccessModal={setIsSuccessModal} />
        </Popup>
      )}

      <div className='card px-4 py-4 px-0-mobile'>
        {isLoading ? (
          <div className='loading-div'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : null}

        {/* <div className='alt-hack-landing__boxes'>
          <>
            <div
              className='alt-hack-landing__box rounded-3'

              // console.log('obj alt hack', obj)
            >
              <div className='img-background rounded-3'>
                <img
                  src={cepImg}
                  className='w-100 d-block'
                  alt='alt-hack-img'
                />
              </div>
              <div className='alt-hack-landing__text rounded-3'>
                <h1>Continuous Education Program 4 </h1>
                <p className='text-white mt-3 mb-0 fs-4 text-center'>
                  (23.July.2023)
                </p>
                {isEnrolled ? (
                  <div className='buttons px-2 pb-2 position-relative'>
                    <button
                      className='btn-purple'
                      // style={{ flex: '1' }}
                      onClick={() => {
                        navigate(`/dashboard/cep/1/schedule`)
                        setIsEnrolled(false)
                      }}
                    >
                      Schedule
                    </button>
                    <button
                      className='btn-purple'
                      disabled={true}
                      // style={{
                      //   flex: '3'
                      // }}
                      //onClick={() => navigate(`/dashboard/cep/1/create-team`)}
                    >
                      Team - Program Selection -
                      <br />
                      Idea Submission ( Coming Soon)
                    </button>
                  </div>
                ) : (
                  <div className='buttons px-2 pb-2 position-relative'>
                    <button className='btn-purple' onClick={handleEnrolling}>
                      Enroll Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        </div> */}

        {events && events.length && !isLoading > 0 ? (
          <>
            <div className='alt-hack-landing__boxes'>
              <>
                {events.map((obj, index) => {
                  const dateParts = obj?.startDate.split('-')
                  const formattedDate = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`
                  return (
                    <div
                      className='alt-hack-landing__box rounded-3'
                      key={index}
                      // console.log('obj alt hack', obj)
                    >
                      <div className='img-background rounded-3'>
                        <img
                          src={obj?.image || cepImg}
                          className='w-100 d-block'
                          alt='alt-hack-img'
                        />
                      </div>
                      <div className='alt-hack-landing__text rounded-3'>
                        <h1>{obj?.name}</h1>
                        <p className='text-white mt-3 mb-0 fs-4 text-center'>
                          ({formattedDate})
                        </p>

                        {isCepEnrolled ? (
                          <div className='buttons px-2 pb-2 position-relative'>
                            <button
                              className='btn-purple'
                              // style={{ flex: '1' }}
                              onClick={() => {
                                navigate(`/dashboard/cep/${obj?.id}/schedule`)
                                // setIsEnrolled(false)
                              }}
                            >
                              Schedule
                            </button>
                            <button
                              className='btn-purple'
                              // style={{
                              //   flex: '3'
                              // }}
                              onClick={() =>
                                navigate(
                                  `/dashboard/cep/${obj?.id}/create-team`
                                )
                              }
                            >
                              Team - Program Selection -
                              <br />
                              Idea Submission
                            </button>
                          </div>
                        ) : (
                          <div className='buttons px-2 pb-2 position-relative'>
                            <button
                              className='btn-purple'
                              onClick={handleEnrolling}
                            >
                              Enroll Now
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </>
            </div>
          </>
        ) : isWarning ? (
          <div className='warning-banner d-flex flex-column'>
            No events found
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CepLanding
