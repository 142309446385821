let deepak = 'Deepak Singh'
let praveen = 'Praveen Thakur'
let deepakC = 'Deepak C'

export const agendaData = [
  {
    id: 1,
    day: 'Day 1',
    date: '19th June',
    agenda: [
      {
        time: 'Time',
        text: 'Day1 : Registration, Program Kickoff',
        speakers: 'Speakers'
      }
    ],
    schedules: [
      {
        time: '8:30 - 9:30',
        program: 'Student arrival & registration',
        name: 'Archana'
      },
      {
        time: '9:30 - 10:00',
        program: 'Student Orientation',
        name: praveen
      },
      {
        time: '10:00 - 10:30',
        program: 'SPOCs Orientation',
        name: praveen
      },
      {
        time: '10:30 - 10:40',
        program: 'Welcoming and Lamp lighting ceremony',
        name: deepak
      },
      {
        time: '10:40 - 10:45',
        program: 'Welcome Address',
        name: 'Abhishek Pitti, CEO, IBC Media'
      },
      {
        time: '11:00 - 11:10',
        program: 'Dignitary 1',
        name: 'Director, IIT Delhi '
      },
      {
        time: '11:10 - 11:20',
        program: 'Dignitary 2',
        name: 'TBD'
      },
      {
        time: '11:20 - 11:30',
        program: 'Dignitary 3',
        name: 'TBD'
      },
      {
        time: '11:30 - 11:40',
        program: 'Dignitary 4',
        name: 'TBD '
      },
      {
        time: '11:40 - 11:50',
        program: 'Dignitary 5',
        name: 'TBD'
      },
      {
        time: '11:50 - 12:00',
        program: 'Digitary 6',
        name: 'TBD'
      },
      {
        time: '12:00 - 12:45',
        program: 'A panel discussion with Guests and Dignitaries ',
        name: 'TBD'
      },
      {
        time: '12:45 - 13:15',
        program:
          'Vote of thanks to the chief guests & Introduction to IBC vision, track record and roadmap',
        name: 'Abhishek Pitti'
      },
      {
        time: '13:15 - 14:00',
        program:
          'Lunch Break + Announcement of team formation with google form',
        name: 'Deepak Singh'
      },
      {
        time: '14:00 - 14:05',
        program: 'EMCEE welcomes audience back and welcomes the next speaker',
        name: 'Deepak Singh'
      },
      {
        time: '14:05 - 14:15',
        program: 'Stage setup',
        name: 'Deepak Gudem'
      },
      {
        time: '14:15 - 15:00',
        program: 'Online Panel on Women in new.0',
        name: '4-5 panelists + 1 moderator'
      },
      {
        time: '15:00 - 15:15',
        program: 'Infra setup for next speaker’s talk',
        name: 'Deepak Gudem'
      },
      {
        time: '15:15 - 15:30',
        program: 'Keynote by Web3 Foundation',
        name: 'Representative from Web3 Foundation'
      },
      {
        time: '15:30 - 15:45',
        program: 'Talk from 5ire Chain',
        name: '-'
      },
      {
        time: '15:45 - 16:15',
        program: 'Talk from IIT Delhi/CDEP',
        name: '-'
      },
      {
        time: '16:15 - 17:15',
        program:
          'Intro to the program, Outcomes, Participation guidelines to maximize gains, Career prospects, Incentives etc',
        name: praveen
      },
      {
        time: '17:15 - 17:45',
        program:
          "Day 1 summarisation, Sponsor's introduction and closing session",
        name: deepak
      }
    ]
  },
  {
    id: 2,
    day: 'Day 2',
    date: '20th June ',
    agenda: [
      {
        time: 'Time',
        text: 'Day2 : Getting started with Blockchain Technology',
        speakers: 'Speakers'
      }
    ],
    schedules: [
      {
        time: '10:00 - 10:15',
        program: 'EMCEE welcomes everyone',
        name: deepak
      },
      {
        time: '10:15 - 10:30',
        program:
          'Recap session + Team formation + Sponsor track Introduction review',
        name: 'Praveen Thakur '
      },
      {
        time: '10:30 - 11:15',
        program:
          'Why Blockchain ? Relevance of Blockchain in contemporary world & Introduction to Blockchain ecosystem',
        name: 'Praveen Thakur '
      },
      {
        time: '11:15 - 11:45',
        program: 'Fireside chat: Infrastructure in Web3',
        name: 'Web3 experts (1 in number) + Moderator from IBC Media'
      },
      {
        time: '11:45 - 12:00',
        program: 'Tea Break',
        name: '-'
      },
      {
        time: '12:00 - 13:00',
        program: 'Blockchain technology anatomy - part I',
        name: praveen
      },
      {
        time: '13:00 - 14:00',
        program: 'Lunch Break',
        name: '-'
      },
      {
        time: '14:00 - 14:30',
        program: 'Fireside chat: Infrastructure in Web3',
        name: praveen
      },
      {
        time: '14:30 - 15:30',
        program: 'Blockchain technology anatomy - part II',
        name: praveen
      },
      {
        time: '15:30 - 15:45',
        program: 'Tea Break',
        name: '-'
      },
      {
        time: '15:45 - 16:45',
        program: 'Journey of a Web3 developer',
        name: 'Prernaa Agarwal from Nitro Network'
      },
      {
        time: '16:45 - 17:15',
        program: 'Day Quiz',
        name: '_'
      },
      {
        time: '17:15 - 17:30',
        program: 'Q/A and Day closing session',
        name: deepak
      }
    ]
  },
  {
    id: 3,
    day: 'Day 3',
    date: '21st June',
    agenda: [
      {
        time: 'Time',
        text: 'Day3 : Education & Training - Polkadot ecosystem',
        speakers: 'Speakers'
      }
    ],
    schedules: [
      {
        time: '10:00 - 10:10',
        program: 'EMCEE welcomes everyone + Leaderboard display',
        name: deepak
      },
      {
        time: '10:10 - 11:00',
        program: 'Programmability in Blockchain',
        name: 'Praveen Thakur from IBC Media'
      },
      {
        time: '11:00 - 11:20',
        program: 'Leadership Skill Building',
        name: 'Praveen Thakur / Abhishek'
      },
      {
        time: '11:20 - 11:35',
        program: 'Tea Break',
        name: '-'
      },
      {
        time: '11:45 - 12:45',
        program: 'Business applications of Blockchain',
        name: praveen
      },
      {
        time: '12:45 - 13:45',
        program: 'Lunch Break',
        name: '-'
      },
      {
        time: '13:45 - 14:05',
        program: 'Magical Extravaganza ',
        name: 'Rahul Krishnan from IBC Media'
      },
      {
        time: '14:05 - 14:10',
        program: 'EMCEE announces next speaker',
        name: deepak
      },
      {
        time: '14:10 - 15:00',
        program: 'Polkadot functional ecosystem',
        name: 'Representative from Parity'
      },
      {
        time: '15:00 - 15:10',
        program: 'Tea Break',
        name: '-'
      },
      {
        time: '15:10 - 15:50',
        program: 'Deep diving into NFT based Metaverse world',
        name: 'TBD'
      },
      {
        time: '15:50 - 16:10',
        program: 'Reserve Time',
        name: '-'
      },
      {
        time: '16:10 - 17:00',
        program: 'Deep diving into Decentralized Storage & Oracles',
        name: 'TBD'
      },
      {
        time: '17:00 - 17:20',
        program: 'Day Quiz',
        name: deepak
      },
      {
        time: '17:20 - 17:30',
        program: 'Day closing session',
        name: deepak
      }
    ]
  },
  {
    id: 4,
    day: 'Day 4',
    date: '22nd June',
    agenda: [
      {
        time: 'Time',
        text: 'Day4 : Developer Centric Web3 Product Building',
        speakers: 'Speakers'
      }
    ],
    schedules: [
      {
        time: '10:00 - 10:10',
        program: 'EMCEE welcomes everyone',
        name: deepak
      },
      {
        time: '10:10 - 11:00',
        program: 'Team ideas brainstorming session',
        name: praveen
      },
      {
        time: '11:00 - 11:05',
        program: 'EMCEE welcomes next speaker',
        name: deepak
      },
      {
        time: '11:05 - 11:50',
        program: 'The art of product management (in Blockchain)',
        name: 'Pruthvi Raj from Nitro Network'
      },
      {
        time: '11:50 - 12:00',
        program: 'Tea Break',
        name: '-'
      },
      {
        time: '12:00 - 12:10',
        program: 'EMCEE announces the student panel',
        name: deepak
      },
      {
        time: '12:10 - 13:00',
        program:
          'Group discussion by shortlisted participants on "The awareness of Crypto and Blockchain in genz community"',
        name: '5 students + 1 Moderator(Praveen)'
      },
      {
        time: '13:00 - 14:00',
        program: 'Lunch Break',
        name: '-'
      },
      {
        time: '14:00 - 14:10',
        program: 'EMCEE welcomes next speaker',
        name: deepak
      },
      {
        time: '14:10 - 15:00',
        program: 'Tokenomics Brief Introduction',
        name: 'Sneha Bharti from Nitro Network'
      },
      {
        time: '15:00 - 15:10',
        program: 'EMCEE welcomes next speaker',
        name: deepak
      },
      {
        time: '15:15 - 16:00',
        program: 'Power of Media and Marketing in the Web 3 World',
        name: 'Sandhya Balakrishna from IBC Media'
      },
      {
        time: '16:00 to 16:15',
        program: 'Tea Break',
        name: '-'
      },
      {
        time: '16:15 to 16:20',
        program: 'EMCEE welcomes next speaker',
        name: deepak
      },
      {
        time: '16:20 - 16:45',
        program: 'How to create a wallet & Intro to product suites',
        name: 'Avinash Pitti '
      },
      {
        time: '16:50 - 16:55',
        program: 'EMCEE announces the Open House with IBC',
        name: deepak
      },
      {
        time: '17:00 - 17:15',
        program: 'Presentation of leaderboard + Open House with IBC Leadership',
        name: deepak
      },
      {
        time: '17:15 - 17:35',
        program: 'Day Quiz',
        name: deepak
      },
      {
        time: '17:35 - 17:50',
        program: 'Display of QR codes for Reference Links and PPT Repository',
        name: deepak
      },
      {
        time: '17:50 - 18:00',
        program: 'Day Closing Session',
        name: deepak
      }
    ]
  },
  {
    id: 5,
    day: 'Day 5',
    date: '23rd June',
    agenda: [
      {
        time: 'Time',
        text: 'Day5 : Ideation Day - I',
        speakers: 'Speakers'
      }
    ],
    schedules: [
      {
        time: '10:00 - 10:10',
        program: 'EMCEE welcomes everyone',
        name: deepak
      },
      {
        time: '10:10 - 10:30',
        program: 'Sponsor track introduction',
        name: praveen
      },

      {
        time: '10:30 - 11:30',
        program: 'Introduction to RUST programming language',
        name: 'TBD'
      },
      {
        time: '11:30 - 11:45',
        program: 'Tea Break',
        name: '-'
      },
      {
        time: '11:45 - 13:00',
        program: 'Polkadot Substrate framework',
        name: 'TBD'
      },
      {
        time: '13:00 - 14:00',
        program: 'Lunch Break',
        name: '-'
      },
      {
        time: '14:10 - 14:40',
        program: 'Pallets & their utilities',
        name: 'TBD'
      },
      {
        time: '14:50 - 17:30',
        program: 'Substrate installation working session',
        name: deepakC
      },
      {
        time: '15:30 - 15:45',
        program: 'Day Quiz',
        name: '-'
      },
      {
        time: '17:45 - 18:00',
        program: 'Day closing session',
        name: deepakC
      }
    ]
  },
  {
    id: 6,
    day: 'Day 6',
    date: '24th June',
    agenda: [
      {
        time: 'Time',
        text: 'Day6 :  Ideation Day - II',
        speakers: 'Speakers'
      }
    ],
    schedules: [
      {
        time: '10:00 - 10:10',
        program: 'EMCEE welcomes everyone',
        name: deepak
      },
      {
        time: '10:10 - 13:10',

        program: 'Guided building session on Polkadot',
        name: deepakC
      },
      {
        time: '13:10 - 14:10',
        program: 'Lunch Break',
        name: '-'
      },
      {
        time: '14:10 - 14:40',
        program:
          'AskPlato: Enhancing Search & Social media experience using ChatGPT',
        name: 'Abhishek Pitti + Avinash Pitti'
      },
      {
        time: '14:40 - 16:00',
        program:
          'Idea deck submission to IBC (contd) + Discussion with IBC Media experts',
        name: deepak
      },
      {
        time: '16:00 - 16:15',
        program: 'Break',
        name: '-'
      },
      {
        time: '16:15 - 17:00',
        program: 'Declaration of top teams for presentation on the final day',
        name: deepak
      },
      {
        time: '17:00 - 17:15',
        program: 'Day closing session',
        name: deepak
      }
    ]
  },
  {
    id: 7,
    day: 'Day 7',
    date: '25th June',
    agenda: [
      {
        time: '-',
        text: 'Day7 : Break Day',
        speakers: '-'
      }
    ],
    schedules: []
  },

  {
    id: 8,
    day: 'Day 8',
    date: '26th June',
    agenda: [
      {
        time: 'Time',
        text: 'DAY 8 : Closing Day',
        speakers: 'Speakers'
      }
    ],
    schedules: [
      {
        time: '10:00 - 10:10',
        program: 'EMCEE welcomes everyone',
        name: deepak
      },
      {
        time: '10:10 - 10:30',
        program: 'Reserve Time',
        name: '-'
      },
      {
        time: '10:30 - 10:35',
        program: 'EMCEE welcomes next speaker',
        name: deepak
      },
      {
        time: '10:35 - 11:00',
        program: 'Introduction of Judges',
        name: deepak
      },
      {
        time: '11:00 - 13:00',
        program: '10 minutes  presentation by 15 shortlisted on product Ideas',
        name: 'Shortlisted students'
      },
      {
        time: '13:00 - 14:00',
        program: 'Working Lunch Break (presentations to continue)',
        name: '-'
      },
      {
        time: '14:00 - 15:45',
        program:
          '10 minutes presentation by 15 shortlisted on product Ideas (contd) ',
        name: ' Shortlisted students'
      },
      {
        time: '15:45 - 15:55',
        program: 'EMCEE welcomes next speaker',
        name: deepak
      },
      {
        time: '16:00 - 16:30',
        program: 'Talk from sponsor speaker',
        name: 'Leadership from Parity'
      },
      {
        time: '16:30 - 17:00',
        program:
          'Shortlisted students to present their experience and what they learnt about Blockchain during ALT HACK Chennai',
        name: '5 students + Praveen Thakur'
      },

      {
        time: '17:00 - 17:20',
        program: '8 days events summary presentation',
        name: praveen
      },
      {
        time: '17:20 - 17:30',
        program:
          'Vote of Thanks and the Journey Roadmap (Display of previous videos)',
        name: 'Abhishek Pitti'
      },
      {
        time: '17:30 - 17:40',
        program: 'Talk by Chief Guest',
        name: 'Chief Guest'
      },
      {
        time: '17:40 - 18:10',
        program:
          'Recognition of the "Top 3 Most Promising Ideas" awarded by the Chief Guests & other recognitions',
        name: 'Chief Guest'
      },
      {
        time: '18:10 - 18:30',
        program: 'Day closing and DJ',
        name: deepak
      }
    ]
  }
]
