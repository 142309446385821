import React from 'react'
import { useNavigate } from 'react-router'
import { useCepEnrollStatus } from '../hooks/useCepEnroll'

const QuizAssesment = ({ type = 'white' }) => {
  const navigate = useNavigate()

  const { isLoading, isCepEnrolled } = useCepEnrollStatus()

  // console.log(isCepEnrolled)

  return (
    <div
      style={{ background: type }}
      className={`${
        type === 'white' ? 'card' : ''
      } p-2 text-center profile-card`}
    >
      <div className='card-body quiz-assement'>
        <h5 className='card-title'>Ready to join CEP?</h5>
        <div className='text-center'>
          <button
            onClick={() => {
              navigate('/dashboard/cep')
            }}
            className='btn btn-blue'
            disabled={isCepEnrolled}
          >
            {isLoading ? (
              <span className='lds-dual-ring'></span>
            ) : (
              <>{isCepEnrolled ? 'Already Enrolled' : 'Enroll Now'}</>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default QuizAssesment
