import React from 'react'
import ReactDOM from 'react-dom'
const Popup = ({ openModal, setOpenModal, children }) =>
  openModal
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className={`popup ${openModal ? 'show' : ''}`}>
            <div className='popup--body'>
              <div className='card card-form-bg border-0 shadow-none'>
                <div className='card-body'>{children}</div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.getElementById('modal')
      )
    : null

export default Popup
