import React from 'react'

const Support = () => {
  return (
    <div className='py-3 h-100'>
      <div className='card'>
        <div className='card-boby px-3'>
          {/* <h4 className='py-3'>Support</h4> */}
          <div
            style={{ minHeight: '86vh' }}
            className='h-100 d-flex justify-content-center flex-column align-items-center'
          >
            <h4 className='py-3'>Support</h4>

            <p className='text-center'>
              For any technical support or help, please drop us a query on
              Discord and we'll assist you promptly!
            </p>
            <p className='text-center'>
              Here’s your exclusive Discord invite: {'  '}
              <a
                href='https://discord.gg/zSWaTrZtwP'
                target='_blank'
                rel='noreferrer'
                className='text-link'
              >
                https://discord.gg/zSWaTrZtwP
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Support
