import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { images } from '../Pictures/images'
import { Link, useLocation } from 'react-router-dom'
import SideNavLinks from './SideNavLinks'

const Header = () => {
  const [hideHeader, setHideHeader] = useState(false)
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [showMobileLandingNav, setShowMobileLandingNav] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [isDashboardHeader, setIsDashboardHeader] = useState(false)

  const isLoggedIn = useSelector((state) => state.loginLogout.isLoggedIn)
  const userData = useSelector((state) => state.loginLogout.userData)

  // console.log('isLogged in header', isLoggedIn)

  // const [isLoggedIn, setIsLoggedIn] = useState(false)
  // const isLoggedIn = true
  const location = useLocation()

  useEffect(() => {
    //to hide header on login/signup page
    if (location.pathname === '/login' || location.pathname === '/signup') {
      setHideHeader(true)
    } else {
      setHideHeader(false)
    }

    //to make changes based on landing page header or dashboard
    if (location.pathname === '/' || location.pathname === '/faq') {
      setIsDashboardHeader(false)
    } else {
      setIsDashboardHeader(true)
    }

    setShowMobileNav(false)
  }, [location.pathname])

  //
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 70)
    })
  }, [])

  if (hideHeader) return

  return (
    <>
      <div
        className={`header ${scroll ? 'bg-blue' : ''}`}
        style={
          !isDashboardHeader
            ? { background: 'transparent' }
            : { background: '#11142d' }
        }
      >
        <nav className='navbar navbar-light'>
          <div className='container-fluid px-5 py-2 px-0-mobile'>
            <a
              href='https://ibc.media'
              // target='_blank'
              className='navbar-brand'
            >
              <img
                src={images.logoWhite}
                alt='logo'
                className='img-fluid nav-logo'
              />
            </a>

            {/* Show in desktop onnly */}
            <div className='show-in-desktop '>
              {isLoggedIn ? (
                <>
                  <div className='d-flex align-items-center'>
                    {/* <QuizNotification /> */}
                    <Link
                      to='/faq'
                      className='d-inline-block me-3 text-no-decoration text-white'
                    >
                      FAQ
                    </Link>
                    <Link
                      to='/dashboard/profile-settings'
                      className='d-flex align-items-center justify-content-center logged-in-profile'
                    >
                      {/* <img
                    src={images.notificationIcon}
                    alt='icon'
                    className='img-fluid'
                  /> */}
                      <div className='emailer-name '>
                        <div className='d-flex align-items-center justify-content-center gap-3'>
                          <h4 className='text-bg'>{userData?.firstName[0]}</h4>
                          <h6>
                            {userData?.firstName} {userData?.lastName}
                          </h6>
                          <p className='me-2'>
                            <small>{userData?.ibcUserId}</small>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <nav className='navbar navbar-expand-lg navbar-light'>
                    {/* For Later Use */}

                    {/* <div className='collapse navbar-collapse menu-pad' id='navbarNav'>
                    <ul className='navbar-nav me-auto'>
                      <li className='nav-item nav-pad'>
                        <a className='nav-link active' aria-current='page' href='#'>
                          Home
                        </a>
                      </li>
                      <li className='nav-item nav-pad'>
                        <a className='nav-link' href='#'>
                          Events
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link' href='#'>
                          <img
                            src={images.althacklogo}
                            className='img-fluid'
                            alt='ALthack-logo'
                            style={{ width: '150px', margin: '0px' }}
                          />
                        </a>
                      </li>
                      <li className='nav-item nav-pad'>
                        <a className='nav-link' href='#'>
                          Courses
                        </a>
                      </li>
                      <li className='nav-item nav-pad'>
                        <a className='nav-link' href='#'>
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div> */}
                    <div>
                      <Link
                        to='/faq'
                        className='d-inline-block me-3 text-no-decoration text-white'
                      >
                        FAQ
                      </Link>
                      <Link
                        to='https://ibc.media/'
                        className='link btn btn-header-width me-3'
                      >
                        Explore IBC Media
                      </Link>
                      <Link to='/login' className='link btn btn-header-pg'>
                        Login
                      </Link>
                    </div>
                  </nav>
                </>
              )}
            </div>
            {/* Show in Mobile only */}
            <div className='show-in-mobile'>
              {!isLoggedIn ? (
                <div className='d-flex align-items-center'>
                  {/* <Link
                    to='/faq'
                    className='d-inline-block  text-no-decoration text-white nav-item'
                  >
                    FAQ
                  </Link>
                  <Link
                    to='https://ibc.media/'
                    className='link btn btn-header-width  nav-item'
                  >
                    Explore IBC Media
                  </Link> */}
                  <Link
                    to='/login'
                    className='link btn btn-header-pg me-3 nav-item'
                  >
                    Login
                  </Link>

                  {showMobileNav ? (
                    <i
                      className='fa-solid fa-xmark'
                      onClick={() => {
                        setShowMobileNav(false)
                      }}
                    ></i>
                  ) : (
                    <i
                      className='fa-solid fa-bars'
                      onClick={() => {
                        setShowMobileNav(true)
                      }}
                    ></i>
                  )}
                </div>
              ) : (
                <div className='d-flex align-items-center'>
                  <Link
                    to='/faq'
                    className='d-inline-block me-3 text-no-decoration text-white'
                  >
                    FAQ
                  </Link>
                  <Link
                    to='/dashboard/profile-settings'
                    className=' logged-in-profile'
                  >
                    <div className='d-flex align-items-center justify-content-center me-3'>
                      <h4 className='text-bg'>{userData?.firstName[0]}</h4>
                    </div>
                  </Link>
                  {showMobileNav ? (
                    <i
                      className='fa-solid fa-xmark'
                      onClick={() => {
                        setShowMobileNav(false)
                      }}
                    ></i>
                  ) : (
                    <i
                      className='fa-solid fa-bars'
                      onClick={() => {
                        setShowMobileNav(true)
                      }}
                    ></i>
                  )}
                </div>
              )}
            </div>
          </div>
        </nav>

        {/* Mobile UI */}
        {showMobileNav && (
          <div className='header__mobile'>
            {!isLoggedIn ? (
              <>
                {/* <div className='d-flex'>
                  <Link to='/signup' className='link btn btn-header-pg'>
                    Login
                  </Link>
                </div> */}
                <div className='header__mobile--menu'>
                  <div className='mt-4'>
                    <Link
                      to='/faq'
                      className='link btn btn-header-pg d-block w-100 mb-3  text-no-decoration text-white nav-item'
                    >
                      FAQ
                    </Link>
                    <Link
                      to='https://ibc.media/'
                      className='link btn btn-header-width  nav-item d-block w-100 mb-3'
                    >
                      Explore IBC Media
                    </Link>
                    <Link
                      to='/login'
                      className='link btn btn-header-pg nav-item d-block  w-100 mb-3'
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <div className='header__mobile--menu'>
                <div className='mt-4'>
                  <SideNavLinks />
                </div>
              </div>
            )}
          </div>
        )}
        {/* Mobile View Quiz Notification */}
        {/* 
        <div className='show-in-mobile '>
          {isLoggedIn && <QuizNotification type='marquee' />}
        </div> */}
      </div>
    </>
  )
}

export default Header
