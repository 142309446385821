import Cookies from 'js-cookie'
import { getPersonalInfo } from '../API/userAPI'
import { logOut, setUserData } from '../redux/loginLogout'

export const getUserData = async (dispatch) => {
  try {
    const res = await getPersonalInfo()
    const { data } = res

    dispatch(setUserData({ data: { ...data?.data[0] } }))

    let cookiesData = JSON.parse(Cookies.get('alt-hack-user'))

    cookiesData = {
      ...cookiesData,
      profilePicture: data?.data[0]?.profilePicture
    }

    Cookies.set('alt-hack-user', JSON.stringify(cookiesData), {
      expires: 2 / 24
    })
  } catch (error) {
    if (
      error?.response?.data?.message ===
        'Your session has expired. Please relogin.' ||
      error?.response?.data?.message === 'Invalid Token'
    ) {
      Cookies.remove('alt-hack-user')
      Cookies.remove('isUserLoggedIn')
      dispatch(logOut())

      window.location.href = '/login'
    }
  }
}

