import React from 'react'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import swal from 'sweetalert'

import { images } from '../Pictures/images'
import { logOut } from '../redux/loginLogout'
import { logoutUser } from '../API/userAPI'
import { learningIcon } from '../assets/svg'

const SideNavLinks = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logout = async (e) => {
    e.preventDefault()

    try {
      const res = await logoutUser()

      Cookies.remove('alt-hack-user')
      Cookies.remove('isUserLoggedIn')
      dispatch(logOut())
      swal('Logged out successfully', '', 'success')
      navigate('/login')
    } catch (error) {
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error
      navigate('/login')
      swal(
        `${response?.data?.error?.message || 'Unable to logout.'}`,
        '',
        'error'
      )
    }
  }

  const sidebarLinks = [
    // {
    //   id: 1,
    //   title: 'Courses',
    //   route: '/dashboard/course',
    //   icon: images.onlineLearnig
    // },
    {
      id: 2,
      title: 'ALT HACK',
      route: '/dashboard/alt-hack',
      icon: images.indiaGate,
      iconType: 'img'
    },
    {
      id: 3,
      title: 'CEP',
      route: '/dashboard/cep',
      icon: images.cep,
      iconType: 'img'
    },
    {
      id: 4,
      title: 'Courses',
      route: '/dashboard/learn/courses',
      icon: learningIcon
      //iconType: 'img'
    },
    // {
    //   id: 4,
    //   title: 'Certificates',
    //   route: '/dashboard/certificates',
    //   icon: images.certification,
    //   iconType: 'img',
    //   comingSoon: true
    // },
    {
      id: 5,
      title: 'Support',
      route: '/dashboard/support',
      icon: images.supportIcon,
      iconType: 'img'
    },
    {
      id: 6,
      title: 'Settings',
      route: '/dashboard/profile-settings',
      icon: images.settings,
      iconType: 'img'
    }
  ]
  return (
    <div className='sidenav__links'>
      {sidebarLinks.map((link) => {
        return (
          <React.Fragment key={link.id}>
            {!link.comingSoon ? (
              <NavLink
                to={link.route}
                key={link.id}
                className='d-flex align-items-center w-100  gap-2 text-decoration-none'
              >
                <h4>
                  {link?.iconType === 'img' ? (
                    <img
                      src={link.icon}
                      alt=''
                      className='img-fluid  me-2 d-inline-block'
                      style={{
                        width: '1.3rem',
                        height: 'auto'
                      }}
                    />
                  ) : (
                    <span className='me-2'>{link?.icon}</span>
                  )}
                  <span className='w-100'>{link.title}</span>
                </h4>
              </NavLink>
            ) : (
              <div
                to={link.route}
                key={link.id}
                className='d-flex align-items-center w-100  gap-2 my-2 text-decoration-none'
              >
                <h4>
                  {link?.iconType === 'img' ? (
                    <img
                      src={link.icon}
                      alt=''
                      className='img-fluid  me-2 d-inline-block'
                      style={{
                        width: '1.3rem',
                        height: 'auto'
                      }}
                    />
                  ) : (
                    <span>{link?.icon}</span>
                  )}
                  <span className='w-100'>{link.title} (coming soon!)</span>
                </h4>
              </div>
            )}
          </React.Fragment>
        )
      })}

      <button
        className='logout-btn border-0 bg-black fw-semibold mt-4 position-absolute text-white py-3 rounded-3 mx-auto px-5 d-flex'
        onClick={logout}
      >
        <span className='icon me-2'>
          <i
            className='fa-solid fa-arrow-right-to-bracket text-white'
            style={{
              transform: 'rotateY(180deg)',
              color: '#000'
            }}
          ></i>
        </span>
        Logout
      </button>
    </div>
  )
}

export default SideNavLinks

