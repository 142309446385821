import moment from 'moment'
import React, { useState } from 'react'
import { useEvents } from '../hooks/useEvents'
import { agendaData } from '../utils/agendaData'
const Agenda = () => {
  //to change for next hackathon
  const params = { id: '1' }
  const { tabActive, setTabActive, eventDays, eventSchedule } =
    useEvents(params)

  // console.log('event', eventSchedule)
  return (
    <div className='agenda py-2'>
      <div className='agenda-destop'>
        <div className='card card-bg'>
          <div className='card-body '>
            {/* Tab Buttons */}
            <div className='day__cards py-2 btn-scrolls'>
              {eventDays &&
                eventDays.map((day, index) => {
                  return (
                    <div
                      className={`day__border day__card ${
                        tabActive === index ? 'active' : ''
                      }`}
                      key={index}
                      onClick={() => setTabActive(index)}
                    >
                      <h6 className='card-title text-center'>{day.day}</h6>
                    </div>
                  )
                })}
            </div>

            {/* Tab Heads */}
            <div className='agenda__Grid py-3'>
              {agendaData[tabActive].agenda.map((agenda, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className='agenda__card'>
                      <div className='card card-pink-bg'>
                        <div className='card-body'>
                          <h6>{agenda?.time}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='agenda__card'>
                      <div className='card card-pink-bg'>
                        <div className='card-body'>
                          <h6 className='my-4'>{agenda?.text}</h6>
                        </div>
                      </div>
                    </div>
                    <div className='agenda__card'>
                      <div className='card card-pink-bg'>
                        <div className='card-body'>
                          <h6>{agenda?.speakers}</h6>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>

            {/* Tab Conetnt */}
            <div className=''>
              {eventSchedule &&
                eventSchedule.map((schedule, i) => {
                  const { startTime, endTime } = schedule
                  let start = moment(startTime, 'HH:mm:ss')
                  let end = moment(endTime, 'HH:mm:ss')

                  return (
                    <React.Fragment key={i}>
                      <div className='agenda__Grid py-2' key={i}>
                        <div className='agenda__card--schedule agenda-time'>
                          <p className='my-4'>
                            {start.format('HH:mm')} - {end.format('HH:mm')}
                          </p>
                        </div>

                        <div className='agenda__card--schedule agenda-program'>
                          <p className='my-4'>{schedule?.activity || ''}</p>
                        </div>
                        <div className='agenda__card--schedule agenda-name'>
                          <p className='my-4'>{schedule?.speaker || ''}</p>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
              {/* {agendaData[tabActice].schedules.length > 0 ? (
                <>
                  {agendaData[tabActice].schedules.map((schedule, i) => {
                    return (
                      <div className='agenda__Grid py-2' key={i}>
                        <div className='agenda__card--schedule agenda-time'>
                          <p className='my-4'>{schedule?.time}</p>
                        </div>

                        <div className='agenda__card--schedule agenda-program'>
                          <p className='my-4'>{schedule?.program}</p>
                        </div>
                        <div className='agenda__card--schedule agenda-name'>
                          <p className='my-4'>{schedule?.name}</p>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <div>{ <h1>Coming Soon</h1>}</div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Agenda
