import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import moment from 'moment'

import { getEventActivities, getEvents } from '../API/eventsAPI'

const AltHackSchedule = () => {
  const [tabActive, setTabActive] = useState(0)
  const [eventData, setEventData] = useState(null)

  const [eventDays, setEventDays] = useState([])
  const [eventSchedule, setEventSchedule] = useState([])

  const params = useParams()

  console.log(eventSchedule)
  console.log(eventDays)

  useEffect(() => {
    const { id } = params
    fetchEvent(id)
  }, [])

  useEffect(() => {
    if (eventDays) {
      const { id } = params
      fetchEventSchedule(id)
    }
  }, [eventDays])

  useEffect(() => {
    if (eventData && eventData !== null) {
      makeSchedule(tabActive)
    }
  }, [eventData, tabActive])

  const fetchEvent = async (id) => {
    try {
      const res = await getEvents(id)
      const { data } = res
      setEventDays({ ...data?.data[0] })

      const { numberOfDays, startDate } = data?.data[0]

      let temp = []
      Array.from(Array(numberOfDays).keys()).map((i) => {
        let date = new Date(startDate)
        date.setDate(date.getDate() + i)
        temp.push({
          day: `Day ${i + 1}`,
          date: date.toISOString().split('T')[0]
        })
      })

      setEventDays([...temp])
    } catch (error) {
      debugger
    }
  }

  const fetchEventSchedule = async (id) => {
    try {
      const res = await getEventActivities(id)
      const { data } = res
      setEventData([...data?.data])
    } catch (error) {
      debugger
    }
  }

  const makeSchedule = (tabActive) => {
    let temp = []
    eventData.map((event) => {
      if (event?.eventDate === eventDays[tabActive]?.date) {
        temp.push(event)
      }
    })

    setEventSchedule([...temp])
  }

  return (
    <div className='alt-hack-schedule py-3'>
      <div className='card'>
        <div className='py-2 px-3 btn-scrolls row'>
          {eventDays &&
            eventDays.map((day, index) => {
              let date = moment(day.date)

              return (
                <div className='col-12 col-md-2 col-lg-2 mb-2'>
                  <div
                    className={`day__border day__card  ${
                      tabActive === index ? 'active' : ''
                    }`}
                    key={index}
                    onClick={() => setTabActive(index)}
                  >
                    <h6 className='card-title text-left'>{day.day}</h6>

                    {tabActive === index && (
                      <div className='text-end mb-0'>
                        <small style={{ fontSize: '0.75rem' }}>
                          {date.format('Do MMMM')}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
        </div>

        {/* Tab Conetnt */}

        <div className='p-3 m-0 row alt-hack-schedule-outer'>
          {eventSchedule &&
            eventSchedule.map((schedule) => {
              const { startTime, endTime } = schedule
              let start = moment(startTime, 'HH:mm:ss')
              let end = moment(endTime, 'HH:mm:ss')

              return (
                <div className='col-12 alt-hack-schedule-col p-0'>
                  <div className='row m-0 mb-3 d-flex align-items-center alt-hack-schedule-inner px-0'>
                    <div className='col-12 col-md-2 p-0'>
                      <p
                        className='m-0 d-flex align-items-center px-2'
                        style={{
                          border: '1px solid #11142D',
                          borderRadius: '4px',
                          height: '100%'
                        }}
                      >
                        {start.format('HH:mm')} - {end.format('HH:mm')}
                      </p>
                    </div>
                    <div className='col-12 col-md-7'>
                      <p className='m-0 text-left d-flex align-items-center'>
                        {schedule?.activity || ''}
                      </p>
                    </div>

                    <div className='col-12 col-md-3 p-0'>
                      <p
                        className='m-0 d-flex align-items-center px-2'
                        style={{
                          border: '1px solid #11142D',
                          borderRadius: '4px',
                          height: '100%'
                        }}
                      >
                        {schedule?.speaker || ''}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default AltHackSchedule
