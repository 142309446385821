import { deleteRequest, getRequest, postRequest, putRequest } from '.'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

const getRoles = async () => {
  const res = await getRequest('/getRoles')
  return res
}

const getPersonalInfo = async () => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await getRequest(`/personalInfo/${user.id}`)
  return res
}

const updatePersonalInfo = async (body) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await putRequest(`/personalInfo/${user.id}`, body)
  return res
}

const getEducationDetails = async () => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await getRequest(`/educationDetails/${user.id}`)
  return res
}

const updateEducationDetails = async (body) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await putRequest(`/educationDetails/${user.id}`, body)
  return res
}

const getWorkExperience = async () => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await getRequest(`/workExperience/${user.id}`)
  return res
}

const updateWorkExperience = async (body) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await putRequest(`/workExperience/${user.id}`, body)
  return res
}

const getUserSkills = async () => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await getRequest(`/skills/${user.id}`)
  return res
}

const updateUserSkills = async (body) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await putRequest(`/skills/${user.id}`, body)
  return res
}

const uploadProfilePic = async (userId, body, toastID) => {
  const uploader = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent
      let progress = loaded / total

      if (toastID.current === null) {
        console.log('Toast if', toastID)
        toastID.current = toast('Upload in Progress', {
          progress: progress,
          className: 'uploadToast',
          closeButton: true
        })
      } else {
        console.log('Toast else', toastID)
        toast.update(toastID.current, {
          progress: progress,
          className: 'uploadToast',
          closeButton: true
        })
      }
    }
  }
  const res = await putRequest(
    `/uploadProfilePicture/${userId}`,
    body,
    uploader
  )
  return res
}

const logoutUser = async () => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const res = await deleteRequest(`logout/${user.id}`)
  return res
}

const getProfilePercent = async () => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = getRequest(`profileCompletionPercentage/${user.id}`)
  return res
}

const getUserAttendance = async (eventId) => {
  const user = JSON.parse(Cookies.get('alt-hack-user'))
  const res = getRequest(`/getUserAttendance/${user.id}?eventId${eventId}`)
}

const getcolleges = async () => {
  const res = await getRequest('/getColleges')
  return res
}
const searchIBCUser = async (searchText) => {
  const res = await getRequest(`/searchIbcUsers/${searchText}`)
  return res
}

export {
  getRoles,
  getPersonalInfo,
  getWorkExperience,
  getUserSkills,
  getEducationDetails,
  updatePersonalInfo,
  updateWorkExperience,
  updateUserSkills,
  updateEducationDetails,
  uploadProfilePic,
  logoutUser,
  getProfilePercent,
  getUserAttendance,
  getcolleges,
  searchIBCUser
}

