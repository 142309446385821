import React from 'react'

const CoursesGrid = ({ text, courseImg, click, ...rest }) => {
  return (
    <div className='learn-div' {...rest} onClick={click}>
      <div className='course-preview-img'>
        <img src={courseImg} alt='' />
      </div>
      <div className='course-desc-div bg-white'>
        <p className='fs-14 mb-0' style={{ color: '#121212' }}>
          {text?.length > 36 ? ` ${text?.substring(0, 33)}...` : text}
          {/* <span className='d-block mt-2'>CEP</span> */}
        </p>
      </div>
    </div>
  )
}

export default CoursesGrid
