import React, { useEffect, useRef, useState } from 'react'
import {
  getEventPrograms,
  getEventThemes,
  getEventTracks,
  updateProgramSelection
} from '../../../API/eventsAPI'
import { useLocation, useNavigate, useParams } from 'react-router'
import swal from 'sweetalert'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { logOut } from '../../../redux/loginLogout'
import { Tooltip } from 'react-tooltip'
import { infoIcon } from '../../../assets/svg'

const ProgramSelection = ({ setTabActive }) => {
  const params = useParams()
  const { id } = params
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState({
    track: '',
    theme: '',
    problemStatement: ''
  })

  const [programs, setPrograms] = useState([])
  const [tracks, setTracks] = useState([])
  const [themes, setThemes] = useState([])
  const [touched, setTouched] = useState(false)

  const location = useLocation()

  const formRef = useRef(null)

  useEffect(() => {
    // Loop over them and prevent submission

    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  useEffect(() => {
    getTracks(id)
  }, [])

  useEffect(() => {
    if (tracks.length !== 0) {
      getPrograms(id)
    }
  }, [tracks])

  useEffect(() => {
    if (formValues.track !== '') {
      getThemes()
    }
  }, [formValues.track])

  const getTracks = async (id) => {
    try {
      const res = await getEventTracks(id)
      setTracks([...res?.data?.data])
    } catch (error) {}
  }

  const getPrograms = async (id) => {
    try {
      const res = await getEventPrograms(id)
      const { data } = res

      setFormValues({
        track: tracks.filter(
          (track) => data?.data?.[0].track === track.track
        )[0].trackId,
        theme: data?.data?.[0].theme,
        problemStatement: data?.data?.[0].problemStatement
      })
    } catch (error) {}
  }

  const getThemes = async () => {
    try {
      const res = await getEventThemes(formValues.track)

      setThemes([...res?.data?.data])
    } catch (error) {}
  }

  const handleChange = (e) => {
    setTouched(true)
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const updateProgram = async (event, type) => {
    event.preventDefault()

    if (touched === false) {
      setTabActive(location?.pathname.includes('cep') ? 2 : 3)
      return false
    }
    let body = {
      ...formValues
    }
    body.eventId = id
    let track = tracks.filter(
      (track) => track.trackId === parseInt(formValues.track)
    )

    body.track = track[0].track

    try {
      const res = await updateProgramSelection(body)
      swal(
        `${res?.data?.message || 'Program selection updated.'}`,
        '',
        'success'
      )
      setTabActive(location?.pathname.includes('cep') ? 2 : 3)
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        debugger
        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  return (
    <div className='team-formation create-team py-3'>
      <div className=''>
        <div className='card card-form-bg border-0 shadow-none'>
          <div className='card-body'>
            <div className='d-flex align-items-start justify-content-start'>
              <h6 className='head-h6'>Program Selection</h6>
              <div className='tool-tip ms-2 d-flex align-items-center'>
                <div className='tooltip-icon' id='program-tooltip'>
                  {infoIcon}
                </div>
              </div>
            </div>

            <form
              className='row g-3 needs-validation'
              noValidate
              ref={formRef}
              onSubmit={updateProgram}
            >
              <div className='col-md-6'>
                <label htmlFor='validationCustom05' className='form-label'>
                  Choose Track *
                </label>
                <select
                  className='form-select form-select-lg mb-2'
                  aria-label='.form-select-lg example'
                  id='validationCustom05'
                  required
                  name='track'
                  value={formValues.track}
                  onChange={handleChange}
                >
                  <option disabled value=''>
                    Select track*
                  </option>
                  {tracks.length !== 0 &&
                    tracks.map((track, i) => {
                      return (
                        <option value={track.trackId} key={i}>
                          {track?.track || ''}
                        </option>
                      )
                    })}
                </select>
                <div className='invalid-feedback'>Please select Track!</div>
              </div>
              <div className='col-md-6'></div>

              <div className='col-md-6'>
                <label htmlFor='validationCustom02' className='form-label'>
                  Theme/Reference Problem *
                </label>

                <select
                  className='form-select form-select-lg mb-2'
                  aria-label='.form-select-lg example'
                  id='validationCustom05'
                  required
                  name='theme'
                  value={formValues.theme}
                  onChange={handleChange}
                >
                  <option disabled value=''>
                    Select theme*
                  </option>
                  {themes.length !== 0 &&
                    themes.map((theme, i) => {
                      return (
                        <option value={theme.theme} key={i}>
                          {theme.theme}
                        </option>
                      )
                    })}
                </select>
                <div className='invalid-feedback'>Please select theme!</div>
              </div>
              <div className='col-md-6'></div>

              <div className='col-md-12'>
                <label htmlFor='validationCustom10' className='form-label'>
                  Problem Statement *
                </label>
                <textarea
                  className='form-control'
                  id='validationCustom10'
                  rows='5'
                  placeholder='Describe you problem'
                  name='problemStatement'
                  value={formValues.problemStatement}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className='col-md-12 text-end'>
                <button
                  type='submit'
                  className='btn btn-blue round-0 ms-2 w-100'
                  style={{
                    maxWidth: 'fit-content'
                  }}
                >
                  Save & Proceed to Idea Submission
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Tooltip
        anchorSelect='#program-tooltip'
        place='right'
        content='Please choose your desired track and theme you wish to work on.'
        className='custom-tooltip'
        style={{
          background: '#f5F5F5',
          boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)',
          color: '#11142D',
          fontSize: '14px',
          borderRadius: '4px',
          padding: '10px 20px'
        }}
      />
    </div>
  )
}

export default ProgramSelection
