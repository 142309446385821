import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getTeamIdeas, sendTeamIdeas } from '../../../API/teamAPI'
import Cookies from 'js-cookie'
import { getIdea } from '../../../API/eventsAPI'
import swal from 'sweetalert'
import { useDispatch } from 'react-redux'
import { logOut } from '../../../redux/loginLogout'
import { infoIcon } from '../../../assets/svg'
import { Tooltip } from 'react-tooltip'

const MyIdeas = () => {
  const [formValues, setformValues] = useState({
    title: '',
    problemStatement: ''
  })

  const formRef = useRef(null)
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = JSON.parse(Cookies.get('alt-hack-user'))

  const location = useLocation()

  useEffect(() => {
    if (!location?.pathname?.includes('cep')) {
      getIdeas()
      const getIdeaInterval = setInterval(() => {
        getIdeas()
      }, 10000)

      return () => clearInterval(getIdeaInterval)
    }
  }, [])

  useEffect(() => {
    // Loop over them and prevent submission

    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  const [ideas, setIdeas] = useState(null)

  const getIdeas = async () => {
    try {
      const res = await getTeamIdeas(params.id)

      const { data } = res

      if (data?.data && data?.data.length !== 0) {
        setIdeas([...data?.data])
      }
    } catch (error) {}
  }

  const sendIdeas = async (e) => {
    e.preventDefault()
    try {
      const res = await sendTeamIdeas({
        userId: user.id,
        eventId: params.id,
        title: formValues.title,
        problemStatement: formValues.problemStatement
      })

      getIdeas()
      formRef.current.classList.remove('was-validated')
      swal(
        `${res?.data?.message || 'Idea uploaded successfully.'}`,
        '',
        'success'
      )
      setformValues({
        title: '',
        problemStatement: ''
      })
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]

        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  const handleChange = (e) => {
    setformValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <div className='create-team py-3'>
      {/* Add Idea Form */}
      <div className='card card-form-bg border-0 shadow-none'>
        <div className='card-body'>
          <div className='d-flex align-items-start justify-content-start'>
            <h6 className='head-h6'>Add New Idea</h6>
            <div className='tool-tip ms-2 d-flex align-items-center'>
              <div className='tooltip-icon' id='my-idea-tooltip'>
                {infoIcon}
              </div>
            </div>
          </div>

          <form
            className='row g-3 py-3 needs-validation'
            onSubmit={sendIdeas}
            noValidate
            ref={formRef}
          >
            <div className='col-12'>
              <label htmlFor='validationCustom01' className='form-label'>
                Title
              </label>

              <input
                type='text'
                className='form-control'
                id='validationCustom01'
                placeholder='Title of idea'
                required
                name='title'
                value={formValues.title}
                onChange={handleChange}
              />

              <div className='invalid-feedback'>
                Please input title of idea!
              </div>
            </div>
            <div className='col-md-12 position-relative'>
              <label htmlFor='validationCustom02' className='form-label'>
                Problem Statement
              </label>

              <textarea
                className='form-control'
                id='validationCustom02'
                rows='5'
                placeholder='Problem Statement....'
                name='problemStatement'
                value={formValues.problemStatement}
                onChange={handleChange}
                required
              ></textarea>

              <div className='invalid-feedback'>
                Please input Problem Statement!
              </div>
            </div>

            <div className='col-md-12 text-start'>
              <button type='submit' className='btn btn-blue round-0 '>
                Add
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* My Ideas Lists */}
      <div className='border-top'>
        <div className='card-body px-3'>
          <div className='d-flex align-items-start justify-content-start'>
            <h4 className='mb-4'>My Ideas</h4>
            <div className='tool-tip ms-2 d-flex align-items-center'>
              <div className='tooltip-icon' id='ideas-tooltip'>
                {infoIcon}
              </div>
            </div>
          </div>

          {/* One Title boxx */}

          {ideas && ideas.length !== 0 ? (
            ideas.map((idea, index) => {
              return (
                <div key={index}>
                  <p className='mb-0 fw-bold'>
                    <small>{idea.title}</small>
                  </p>
                  <div className={`pt-1 pb-2 quiz-page__card `}>
                    <div
                      className='card quiz-page__card--title rounded-0 p-0 mt-2 pb-3'
                      style={{
                        border: '0',
                        borderBottom: '1px solid #e5e5e5'
                      }}
                    >
                      <p className='mb-0'>{idea.problemStatement}</p>
                      {/* If need arrow */}
                      {/* <i className='fa fa-chevron-right'></i> */}
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <p>No Ideas found</p>
          )}

          {/* One Title boxx */}
        </div>
      </div>
      <Tooltip
        anchorSelect='#my-idea-tooltip'
        place='right'
        content='Give a title and a brief description of your idea in this section.'
        className='custom-tooltip'
        style={{
          background: '#f5F5F5',
          boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)',
          color: '#11142D',
          fontSize: '14px',
          borderRadius: '4px',
          padding: '10px 20px'
        }}
      />
      <Tooltip
        anchorSelect='#ideas-tooltip'
        place='right'
        content='List of Ideas you added'
        className='custom-tooltip'
        style={{
          background: '#f5F5F5',
          boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)',
          color: '#11142D',
          fontSize: '14px',
          borderRadius: '4px',
          padding: '10px 20px'
        }}
      />
    </div>
  )
}

export default MyIdeas

