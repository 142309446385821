import React, { useState, useEffect, useRef } from 'react'
import swal from 'sweetalert'
import { getUserSkills, updateUserSkills } from '../../../API/userAPI'

import {
  codingSkills,
  describeOptions,
  technologyOption
} from '../../../utils/constants'
import Cookies from 'js-cookie'
import { logOut } from '../../../redux/loginLogout'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

const Skills = ({ setTabActive, getPercentage }) => {
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState({
    codingSkillLevel: '',
    knownTechLanguages: [],
    describesYou: ''
  })
  const [touched, setTouched] = useState(false)

  const getSkills = async () => {
    try {
      const res = await getUserSkills()
      const { data } = res

      if (data?.data && data?.data.length !== 0) {
        setFormValues({
          ...formValues,
          ...data?.data
        })
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }
    }
  }

  useEffect(() => {
    // Loop over them and prevent submission
    getSkills()
    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  useEffect(() => {
    const fetchUserSkill = async () => {
      try {
        const res = await getUserSkills()
        const { data } = res

        if (data?.data && data?.data.length !== 0) {
          setFormValues({
            ...formValues,
            codingSkillLevel: data?.data?.codingSkillLevel,
            knownTechLanguages: [...data?.data?.knownTechLanguages],
            describesYou: data?.data?.describesYou
          })
        }
      } catch (error) {
        if (
          error?.response?.data?.message ===
            'Your session has expired. Please relogin.' ||
          error?.response?.data?.message === 'Invalid Token'
        ) {
          Cookies.remove('alt-hack-user')
          Cookies.remove('isUserLoggedIn')
          dispatch(logOut())

          navigate('/login')
        }
      }
    }

    fetchUserSkill()
  }, [])
  const handleChange = (e) => {
    setTouched(true)
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const updateSkills = async (e) => {
    e.preventDefault()

    // if (!touched) {
    //   return false
    // }

    if (formValues?.knownTechLanguages.length === 0) {
      swal('Need to select Skills.', '', 'warning')

      return false
    }
    try {
      const res = await updateUserSkills({
        ...formValues
      })

      swal('Skills updated.', '', 'success')
      getSkills()
      getPercentage()
      //setTabActive(2)
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }

      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error

      swal(`${response?.data?.message || 'Update failed.'}`, '', 'error')
    }
  }

  console.log('formvalues skills', formValues)

  return (
    <div className='multistep-form'>
      <div className='container py-2'>
        <div className='card card-form-bg border-0 shadow-none'>
          <div className='card-body'>
            <form
              className='row g-3 needs-validation'
              noValidate
              ref={formRef}
              onSubmit={updateSkills}
            >
              <div className='col-md-6'>
                <label htmlFor='validationCustom01' className='form-label'>
                  Coding Skill Level *
                </label>
                <select
                  className='form-select form-select-lg mb-2'
                  aria-label='.form-select-lg example'
                  id='validationCustom01'
                  required
                  name='codingSkillLevel'
                  value={formValues?.codingSkillLevel}
                  onChange={handleChange}
                >
                  <option value='' selected disabled>
                    Select Skill Level
                  </option>
                  {codingSkills.map((skill, index) => {
                    return (
                      <option value={skill} key={index}>
                        {skill}
                      </option>
                    )
                  })}
                </select>
                <div className='invalid-feedback'>
                  Please select your Skill Level!
                </div>
              </div>
              <div className='col-md-12'>
                <label htmlFor='validationCustom02' className='form-label'>
                  Skill *
                </label>
                <select
                  className='form-select form-select-lg mb-2 no-tick-field'
                  id='validationCustom02'
                  value={``}
                  name='knownTechLanguages'
                  onChange={(e) => {
                    setTouched(false)
                    let temp = [...formValues?.knownTechLanguages]

                    temp.push(e.target.value)
                    setFormValues((prev) => ({
                      ...formValues,
                      knownTechLanguages: [...temp]
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    Select skills
                  </option>
                  {technologyOption.map((tech, index) => {
                    return (
                      <option
                        key={index}
                        value={tech}
                        disabled={
                          formValues?.knownTechLanguages?.includes(tech)
                            ? true
                            : false
                        }
                      >
                        {tech}
                      </option>
                    )
                  })}
                </select>
                <div className='invalid-feedback'>
                  Please select your Skill Level!
                </div>
              </div>
              <div className='col-lg-12'>
                {formValues?.knownTechLanguages?.map((tech, index) => {
                  return (
                    <button
                      className='btn btn-outline-secondary rounded-pill me-3'
                      onClick={(e) => {
                        e.preventDefault()
                      }}
                    >
                      {tech}
                      <span
                        className='ms-3'
                        onClick={() => {
                          const array = [...formValues?.knownTechLanguages]
                          const element = tech

                          const index = array.indexOf(element)
                          if (index !== -1) {
                            array.splice(index, 1)
                          }

                          setFormValues((prev) => ({
                            ...formValues,
                            knownTechLanguages: [...array]
                          }))
                        }}
                      >
                        &#x2715;
                      </span>
                    </button>
                  )
                })}
              </div>
              <div className='col-md-12'>
                <label htmlFor='validationCustom03' className='form-label'>
                  Which of the following best describes you? *{' '}
                </label>
                <select
                  className='form-select form-select-lg mb-2'
                  aria-label='.form-select-lg example'
                  id='validationCustom03'
                  required
                  name='describesYou'
                  value={formValues?.describesYou}
                  onChange={handleChange}
                >
                  <option value='' selected disabled>
                    Select Description
                  </option>
                  {describeOptions?.map((opt, index) => {
                    return (
                      <option value={opt} key={index}>
                        {opt}
                      </option>
                    )
                  })}
                </select>
                <div className='invalid-feedback'>
                  Please select your Skill Level!
                </div>
              </div>
              <div className='col-md-12'>
                <button
                  type='submit'
                  className='btn btn-blue round-0 float-end'
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills

