import React, { useState, useEffect, useRef } from 'react'
import { Country, State, City } from 'country-state-city'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import swal from 'sweetalert'

import {
  getEducationDetails,
  getcolleges,
  logoutUser,
  updateEducationDetails
} from '../../../API/userAPI'
import { logOut } from '../../../redux/loginLogout'
import { useNavigate } from 'react-router'

const Education = ({ setTabActive, getPercentage }) => {
  const formRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [country, setCountry] = useState('')
  const [countryState, setCountryState] = useState('')
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [touched, setTouched] = useState(false)

  const [colleges, setColleges] = useState([])
  const [collegename, setcollegeName] = useState('')

  const [formValues, setFormValues] = useState({
    specialization: '',
    qualification: '',
    fromYear: '',
    toYear: '',
    marksPercent: '',
    country: '',
    state: '',
    city: '',
    otherInstitution: '',
    institution: ''
  })

  useEffect(() => {
    if (formValues?.institution !== '' && colleges.length > 0) {
      checkCollege(formValues?.institution)
    }
  }, [formValues?.institution, colleges])

  useEffect(() => {
    fetchEducation()
    fetchColleges()
  }, [])

  const fetchColleges = async () => {
    try {
      const res = await getcolleges()
      const { data } = res

      setColleges([...data?.data])
      console.log('formValues', formValues)
    } catch (error) {
      debugger
    }
  }

  const fetchEducation = async () => {
    try {
      const res = await getEducationDetails()
      const { data } = res
      if (data?.data) {
        setFormValues({
          ...formValues,
          ...data?.data
        })
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }
    }
  }

  const countries = Country.getAllCountries().map((val) =>
    Object.assign({}, { label: val.name, value: val.isoCode })
  )

  useEffect(() => {
    // Loop over them and prevent submission

    if (formRef) {
      formRef.current.addEventListener(
        'submit',
        (event) => {
          if (!formRef.current.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          formRef.current.classList.add('was-validated')
        },
        false
      )
    }
  }, [])

  useEffect(() => {
    if (formValues.country && formValues.country !== '') {
      if (!country) {
        let cntry = countries.filter(
          (country) => formValues.country === country.label
        )

        setCountry(cntry[0].value)
      }

      let temp = []

      State.getStatesOfCountry(country).forEach((val) => {
        temp.push({ label: val.name, value: val.isoCode })
      })

      setStates([...temp])
    }
  }, [formValues.country, country])

  useEffect(() => {
    if (!countryState) {
      let states = []

      State.getStatesOfCountry(country).forEach((val) => {
        states.push({ label: val.name, value: val.isoCode })
      })

      let state = states.filter((sta) => sta.label === formValues.state)

      setCountryState(state[0]?.value)
    }

    if (countryState && country) {
      let temp = []

      City.getCitiesOfState(country, countryState).forEach((val) => {
        temp.push({ label: val.name, value: val.name })
      })

      setCities([...temp])
    }
  }, [formValues.state, countryState, country])

  const handleChange = (e) => {
    setTouched(true)
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const updateEducation = async (e) => {
    e.preventDefault()
    const fromYear = parseInt(formValues?.fromYear)
    const toYear = parseInt(formValues?.toYear)

    if (fromYear > toYear) {
      swal('From year should be less than to year.', '', 'warning')
      return false
    }

    if (!touched) {
      setTabActive(3)
      return false
    }

    let body = { ...formValues }

    body.institution = parseInt(body.institution)

    try {
      const res = await updateEducationDetails({ ...body })

      swal('Education updated.', '', 'success')
      setTabActive(3)
      getPercentage()
    } catch (error) {
      if (error?.response?.data?.errors?.length) {
        let obj = error?.response?.data?.errors[0]
        let err = Object.values(obj)[0]
        swal(`${err || 'Unable to update.'}`, '', 'error')
        return false
      }
      if (
        error?.response?.data?.message ===
          'Your session has expired. Please relogin.' ||
        error?.response?.data?.message === 'Invalid Token'
      ) {
        Cookies.remove('alt-hack-user')
        Cookies.remove('isUserLoggedIn')
        dispatch(logOut())

        navigate('/login')
      }

      const { response } = error
      swal(
        `${response?.data?.errors?.message || 'Unable to Update.'}`,
        '',
        'error'
      )
    }
  }

  const checkCollege = (id) => {
    let collegeId = parseInt(id)

    colleges.forEach((college) => {
      if (college.id === collegeId) {
        setcollegeName(college.name)
      }
    })
  }

  console.log('collegename', collegename)

  return (
    <div className='multistep-form'>
      <div className='container py-2'>
        <div className='card card-form-bg border-0 shadow-none'>
          <div className='card-body '>
            <form
              className='row g-3 needs-validation'
              noValidate
              ref={formRef}
              onSubmit={updateEducation}
            >
              <div className='col-md-6'>
                <label htmlFor='validationCustom01' className='form-label'>
                  Major *
                </label>

                <input
                  type='text'
                  className='form-control'
                  id='validationCustom01'
                  required
                  name='specialization'
                  value={formValues.specialization}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input you are Major
                </div>
              </div>
              <div className='col-md-6'>
                <label htmlFor='validationCustom02' className='form-label'>
                  Degree *
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='validationCustom02'
                  required
                  name='qualification'
                  value={formValues.qualification}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your Degree!
                </div>
              </div>
              {/* <div className='col-md-12'>
                <label htmlFor='validationCustom03' className='form-label'>
                  Institution *
                </label>

                <input
                  type='text'
                  className='form-control'
                  id='validationCustom03'
                  required
                  name='institution'
                  value={formValues.institution}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your Institution Name!
                </div>
              </div> */}
              <div className='col-md-6'>
                <label htmlFor='validationCustom04' className='form-label'>
                  From Year *
                </label>
                <input
                  type='number'
                  className='form-control'
                  id='validationCustom04'
                  required
                  name='fromYear'
                  value={formValues.fromYear}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your From Year!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='validationCustom05' className='form-label'>
                  To Year *
                </label>
                <input
                  type='number'
                  className='form-control'
                  id='validationCustom05'
                  required
                  name='toYear'
                  value={formValues.toYear}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your To Year!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='validationCustom05' className='form-label'>
                  College Name *
                </label>
                <select
                  className='form-select form-control'
                  aria-label='Select College'
                  name='institution'
                  value={formValues?.institution}
                  required
                  onChange={(e) => {
                    handleChange(e)
                    checkCollege(e.target.value)
                  }}
                >
                  <option selected value='' disabled>
                    College Name
                  </option>
                  {colleges.map((college, index) => {
                    return (
                      <option value={college?.id} key={index}>
                        {college.name}
                      </option>
                    )
                  })}
                </select>
                <div className='invalid-feedback'>Required !</div>
              </div>

              {collegename == 'Others' ? (
                <div className='col-12 col-md-6'>
                  <label htmlFor='validationCustom03' className='form-label'>
                    Institution *
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    placeholder='College Name*'
                    id='validationCustom03'
                    required
                    name='otherInstitution'
                    value={formValues?.otherInstitution || ''}
                    onChange={handleChange}
                  />
                  <div className='invalid-feedback'>
                    Please input your college name!
                  </div>
                </div>
              ) : (
                <div className='col-12 col-md-6'></div>
              )}

              <div className='col-12 col-md-6'>
                <label htmlFor='validationCustom05' className='form-label'>
                  Percentage/cgpa
                </label>
                <input
                  type='number'
                  className='form-control'
                  id='validationCustom05'
                  name='marksPercent'
                  value={formValues.marksPercent}
                  onChange={handleChange}
                />
                <div className='invalid-feedback'>
                  Please input your percentage/cgpa
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='country' className='form-label'>
                  Country *
                </label>
                <select
                  className='form-select mb-2 form-control'
                  required
                  id='country'
                  value={formValues?.country}
                  defaultValue={country}
                  onChange={(e) => {
                    e.preventDefault()
                    setTouched(true)
                    const selectedOption =
                      e.target.options[e.target.selectedIndex]
                    const selectedCountryCode =
                      selectedOption.getAttribute('data-country-code')

                    setCountry(selectedCountryCode)
                    setFormValues((prev) => ({
                      ...prev,
                      country: e.target.value,
                      state: '',
                      city: ''
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    Select Country
                  </option>
                  {countries.map((cntry, i) => (
                    <option
                      key={i}
                      value={cntry.label}
                      data-country-code={cntry?.value}
                      selected={formValues.country === cntry.label}
                    >
                      {cntry.label}
                    </option>
                  ))}
                </select>
                <div className='invalid-feedback'>
                  Please input your Country Name!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='state' className='form-label'>
                  State *
                </label>
                <select
                  className='form-select form-select-lg mb-2 form-control'
                  aria-label='.form-select-lg'
                  id='state'
                  required
                  value={formValues.state}
                  defaultValue={formValues?.state}
                  onChange={(e) => {
                    e.preventDefault()
                    setTouched(true)
                    const selectedOption =
                      e.target.options[e.target.selectedIndex]
                    const selectedStateCode =
                      selectedOption.getAttribute('data-state-code')
                    setCountryState(selectedStateCode)
                    setFormValues((prev) => ({
                      ...prev,
                      state: e.target.value,
                      city: ''
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    State
                  </option>

                  {states.map((state, i) => (
                    <option
                      key={i}
                      value={state.label}
                      data-state-code={state.value}
                    >
                      {state.label}
                    </option>
                  ))}
                </select>
                <div className='invalid-feedback'>
                  Please input your State Name!
                </div>
              </div>

              <div className='col-md-6'>
                <label htmlFor='city' className='form-label'>
                  City *
                </label>

                <select
                  className='form-select form-select-lg mb-2 form-control'
                  aria-label='.form-select-lg example'
                  required
                  id='city'
                  value={formValues.city}
                  defaultValue={formValues?.city}
                  name='city'
                  onChange={(e) => {
                    e.preventDefault()
                    setTouched(true)
                    setFormValues((prev) => ({
                      ...prev,
                      city: e.target.value
                    }))
                  }}
                >
                  <option value='' disabled selected>
                    City
                  </option>

                  {cities.map((city, i) => (
                    <option key={i} value={city.label}>
                      {city.label}
                    </option>
                  ))}
                </select>
                <div className='invalid-feedback'>
                  Please input your City Name!
                </div>
              </div>
              <div className='col-md-12'>
                <button
                  type='submit'
                  className='btn btn-blue round-0 float-end'
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Education

