import './App.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import Header from './components/Header'
import AppRoutes from './routes'

import 'react-phone-input-2/lib/style.css'
import { useEffect } from 'react'
import { checkData } from './redux/loginLogout'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'js-cookie'
import QuizModal from './components/views/QuizModal'

import 'react-tooltip/dist/react-tooltip.css'

const App = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (Cookies.get('isUserLoggedIn')) {
      dispatch(checkData())
    } else if (location.pathname.includes('/dashboard')) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    const logoutInterval = setInterval(() => {
      const isloggedIn = Cookies?.get('isUserLoggedIn') ? true : false
      if (!isloggedIn) {
      }
    }, 10 * 1000)

    return () => clearInterval(logoutInterval)
  }, [])

  return (
    <div>
      <ToastContainer autoClose={2000} closeButton={false} />
      <Header />
      <AppRoutes />
      <QuizModal />
    </div>
  )
}

export default App
