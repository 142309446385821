import React, { useState } from 'react'
import { useLocation } from 'react-router'
// import { learningData } from '../../../utils/constants'
import { Link } from 'react-router-dom'
import { playBtnIcon, quizIcon } from '../../../assets/svg'
import { Button, Collapse } from 'react-bootstrap'

const Topics = ({
  material,
  subjectId,
  subjectQuiz,
  subjectCourseTitle = 'Chapter Quiz'
}) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  // const [courseTitle, setCourseTitle] = useState('')
  // const [subjectTitle, setSubjectTitle] = useState('')

  // useEffect(() => {
  //   if (params?.courseId && params?.subjectId) {
  //     const { courseId, subjectId } = params
  //     const courseSubs = learningData.filter(
  //       (data) => data?.courseId === parseInt(courseId)
  //     )
  //     setCourseTitle(courseSubs[0]?.courseTitle)
  //     const subjectTopics = courseSubs[0]?.courseSubjects?.filter(
  //       (data) => data?.subjectId === parseInt(subjectId)
  //     )
  //     setMaterial(subjectTopics[0]?.subjectMaterial || [])
  //     setSubjectTitle(subjectTopics[0]?.subjectTitle)
  //   }
  // }, [])

  return (
    <div className='topic-overflow-div'>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls='example-collapse-text'
        aria-expanded={open}
        style={{
          color: '#148EFF'
        }}
        className={`bg-white border-0 fs-14 mt-3 topic-btn ${
          open ? 'open' : null
        }`}
      >
        <span className='icon me-3'>
          <i
            className='fa-solid fa-angle-right'
            style={{
              color: '#148EFF'
            }}
          ></i>
        </span>
        Show learning objectives
      </Button>
      <Collapse in={open}>
        <div id='example-collapse-text'>
          {material?.map((data, i) => {
            return (
              <div className='chapter-topic-info pt-3' key={i}>
                <Link
                  to={{
                    pathname: `${location?.pathname}/${subjectId}/${data?.materialId}`
                  }}
                >
                  <div className='row m-0'>
                    <div className='col-2 col-md-1 pe-0'>
                      <div className='icon d-flex w-100 justify-content-center'>
                        {playBtnIcon}
                      </div>
                    </div>
                    <div className='col-10 col-md-11 p-0'>
                      <p className='fs-6 mb-1'>{data?.materialTitle}</p>
                      <p
                        className='fs-14'
                        style={{
                          color: '#999'
                        }}
                      >
                        Video
                      </p>
                    </div>
                  </div>
                </Link>
                <QuizListItem key={i} title={data?.materialTitle} data={data} />
              </div>
            )
          })}

          {/* ///////////////////////
           Global Course Quiz 
          ////////////////////////// */}
          {subjectQuiz.map((data, i) => {
            return (
              <QuizListItem key={i} title={subjectCourseTitle} data={data} />
            )
          })}
        </div>
      </Collapse>
    </div>
  )
}

export default Topics

const QuizListItem = ({ data, title = 'Quiz' }) => {
  return (
    <div
      className='row m-0'
      onClick={() => {
        if (data?.quizLink) {
          window.open(data?.quizLink, '_blank')
        }
      }}
      style={{
        cursor: data?.quizLink ? 'pointer' : 'not-allowed'
      }}
    >
      <div className='col-2 col-md-1 pe-0'>
        <div className='icon d-flex w-100 justify-content-center'>
          {quizIcon}
        </div>
      </div>
      <div className='col-10 col-md-11 p-0'>
        <p className='fw- mb-1 fs-6'> {title}</p>
        <p
          className='fs-14'
          style={{
            color: '#999'
          }}
        >
          Quiz
        </p>
      </div>
    </div>
  )
}
