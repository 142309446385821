import React from 'react'
import { Outlet, useParams } from 'react-router'
import { NavLink } from 'react-router-dom'

const CepMain = () => {
  return (
    <div className='alt-hack-page pb-4 pt-3'>
      {/* SubLinks : Top Links -> Attendance, Create Team , Quizzes*/}
      <CepSubLinks />
      {/* Other Child */}
      <Outlet />
    </div>
  )
}

const CepSubLinks = () => {
  const params = useParams()

  // console.log('params cep', params)

  const cepSubLinks = [
    {
      id: 1,
      htmlBody: <i className='fa-solid fa-plus'></i>,
      title: 'Event Schedule',
      route: `/dashboard/cep/${params?.id}/schedule`,
      isClickable: true
    },
    {
      id: 2,
      htmlBody: <div />,
      title: 'Team - Program Selection - Idea Submission',
      route: `/dashboard/cep/${params?.id}/create-team`,
      isClickable: true
    },
    {
      id: 3,
      htmlBody: (
        <>
          <div className='d-flex justify-content-center align-items-end'>
            <p className='fw-semibold'>Total</p>
            <h1 className='fw-bold clr-d-grey ps-1'>10</h1>
          </div>
          <p className='m-1 position-absolute bottom-0 end-0 text-end light-text'>
            <small className='' style={{ fontSize: '0.75rem' }}>
              Attended 8/10
            </small>
          </p>
        </>
      ),
      title: 'Quizzes and Assignments',
      route: `/dashboard/cep/${params?.id}/quiz`,
      isClickable: true
    },

    {
      id: 4,
      htmlBody: (
        <div className='d-flex justify-content-center align-items-end'>
          <p className='fw-semibold'>Total</p>
          <h1 className='fw-bold clr-d-grey px-1'>10</h1>
          <p className='fw-semibold'>questions</p>
        </div>
      ),
      title: 'Feedback Form',
      route: `/dashboard/cep/${params?.id}/feedback`,
      isClickable: true
    }
  ]
  return (
    <div className='alt-hack-page__grid'>
      {cepSubLinks.map((link) => {
        if (link?.isClickable) {
          return (
            <NavLink
              key={link.id}
              to={link.route}
              className={`card p-3 alt-hack-card text-decoration-none`}
            >
              <h6 className='card-title text-center mb-0'>{link.title}</h6>

              <div
                style={{ display: 'none' }}
                className='card-body text-center dfcenter'
              >
                {link.htmlBody}
              </div>
            </NavLink>
          )
        } else {
          return (
            <div className={`card p-3 alt-hack-card text-decoration-none`}>
              <h6 className='card-title text-center mb-0'>{link.title}</h6>

              <div
                style={{ display: 'none' }}
                className='card-body text-center dfcenter'
              >
                {link.htmlBody}
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

export default CepMain
