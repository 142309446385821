import logoWhite from '../assets/images/logo-white.svg'
import logoBlack from '../assets/images/logo-black.svg'

//Home
import banner from '../assets/images/banner1.png'
import ellipse from '../assets/images/home/ellipse.png'
import ellipse1 from '../assets/images/home/ellipse-1.png'
import ellipse2 from '../assets/images/home/ellipse-2.png'
import ellipse3 from '../assets/images/home/ellipse-3.png'
import ellipse4 from '../assets/images/home/ellipse-4.png'
import linkedin from '../assets/images/icons/linkedin.svg'
import facebook from '../assets/images/icons/facebook.svg'
import twitter from '../assets/images/icons/twitter.svg'
import whatsapp from '../assets/images/icons/whatsapp.svg'
import discord from '../assets/images/icons/discord.svg'
import youtube from '../assets/images/icons/youtube.svg'
import telegram from '../assets/images/icons/telegram.svg'
import instagram from '../assets/images/icons/instagram.svg'
import cep from '../assets/images/icons/cep.svg'
import eventImg from '../assets/images/home/event-img.png'
import event6 from '../assets/images/home/orientationicon.png'
import event1 from '../assets/images/home/education.png'
import event2 from '../assets/images/home/exploration.png'
import event3 from '../assets/images/home/panel.png'
import event4 from '../assets/images/home/ideation.png'
import event5 from '../assets/images/home/certificate.png'
import successStories from '../assets/images/home/alt-hack-stories.png'
import attend1 from '../assets/images/home/upskill.png'
import attend2 from '../assets/images/home/w3-developer.png'
import attend3 from '../assets/images/home/network.png'
import attend4 from '../assets/images/home/w3-idea.png'
import attend5 from '../assets/images/home/certified.png'
import attend6 from '../assets/images/home/mentorship.png'
import notificationIcon from '../assets/images/icons/notification.svg'
import onlineLearnig from '../assets/images/icons/online-learning.svg'
import monitor from '../assets/images/icons/monitor.svg'
import indiaGate from '../assets/images/icons/india-gate.svg'
import certification from '../assets/images/icons/certification.svg'
import supportIcon from '../assets/images/icons/supportIcon.svg'
import imgUploadIcon from '../assets/images/icons/imgUploadIcon.svg'
import user from '../assets/images/user.png'
import qrCode from '../assets/images/qrcode.png'
import qr from '../assets/images/qr.png'
import althackheader from '../assets/images/home/althackheader.png'
import althacklogo from '../assets/images/home/althhack-logo.png'
import hydStory from '../assets/images/home/hyd-story.png'
import vizStory from '../assets/images/home/viz-story.png'
import chennaiStory from '../assets/images/home/chennai-story.png'
import settings from '../assets/images/icons/settings.png'
import bannerText from '../assets/images/banner_text.png'
import iitDelhi from '../assets/images/iitdelhi.jpeg'
import iitDelhiLogo from '../assets/images/iitdelhiLogo.svg'
import banner_svg from '../assets/images/banner_svg.svg'
import learningIcon from '../assets/images/icons/learning.svg'

//Home icons
import home_certification from '../assets/images/homeicons/certification.png'
import home_certified from '../assets/images/homeicons/certified.png'
import home_education from '../assets/images/homeicons/education.png'
import home_exploration from '../assets/images/homeicons/exploration.png'
import home_ideation from '../assets/images/homeicons/ideation.png'
import home_mentorship from '../assets/images/homeicons/mentorship.png'
import home_network from '../assets/images/homeicons/network.png'
import home_orientation from '../assets/images/homeicons/orientation.png'
import home_panelDiscussions from '../assets/images/homeicons/panelDiscussions.png'
import home_upskill from '../assets/images/homeicons/upskill.png'
import home_web3developer from '../assets/images/homeicons/web3developer.png'
import home_web3ideas from '../assets/images/homeicons/web3ideas.png'

import whyAttend1Slider from '../assets/images/whyAttend1.png'
import whyAttend2Slider from '../assets/images/whyAttend2.png'

//Speakers Images
import speaker1 from '../assets/images/speakers/urban-osvald.png'
import speaker2 from '../assets/images/speakers/ch_hill.png'
import speaker3 from '../assets/images/speakers/avinash.png'
import speaker4 from '../assets/images/speakers/prernaa.png'
import speaker5 from '../assets/images/speakers/snadhya.png'
import speaker6 from '../assets/images/speakers/sneha.png'
import speaker7 from '../assets/images/speakers/vilma.png'
import speaker8 from '../assets/images/speakers/pruthvi-raj.png'
import speaker9 from '../assets/images/speakers/olga.png'
import speaker10 from '../assets/images/speakers/clara-filecoin.png'
import speaker11 from '../assets/images/speakers/trang.png'
import speaker12 from '../assets/images/speakers/sameep-quickswap.png'
import speaker13 from '../assets/images/speakers/ovia.png'
import speaker14 from '../assets/images/speakers/abhishek.png'
import speaker15 from '../assets/images/speakers/praveen.png'

export const images = {
  logoWhite,
  logoBlack,
  banner,
  ellipse,
  ellipse1,
  ellipse2,
  ellipse3,
  ellipse4,
  //icons
  linkedin,
  twitter,
  facebook,
  whatsapp,
  discord,
  youtube,
  telegram,
  instagram,
  eventImg,
  event1,
  event2,
  event3,
  event4,
  event5,
  event6,
  attend1,
  attend2,
  attend3,
  attend4,
  attend5,
  attend6,
  learningIcon,
  successStories,
  notificationIcon,
  onlineLearnig,
  monitor,
  indiaGate,
  certification,
  supportIcon,
  imgUploadIcon,
  user,
  qrCode,
  // passwordIcon,
  // envolopeIcon,
  qr,
  althackheader,
  althacklogo,
  hydStory,
  vizStory,
  chennaiStory,
  settings,
  bannerText,
  iitDelhi,
  iitDelhiLogo,
  banner_svg,
  cep,

  home_certification,
  home_certified,
  home_education,
  home_exploration,
  home_ideation,
  home_mentorship,
  home_network,
  home_orientation,
  home_panelDiscussions,
  home_upskill,
  home_web3developer,
  home_web3ideas,
  whyAttend1Slider,
  whyAttend2Slider,

  //Speakers
  speaker1,
  speaker2,
  speaker3,
  speaker4,
  speaker5,
  speaker6,
  speaker7,
  speaker8,
  speaker9,
  speaker10,
  speaker11,
  speaker12,
  speaker13,
  speaker14,
  speaker15
}
