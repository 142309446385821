import React, { useState } from 'react'
import { images } from '../Pictures/images'

const Certificates = () => {
  const [certificates, setCertificates] = useState([])

  return (
    <div className='py-3'>
      <div className='card'>
        <div className='card-body px-3'>
          {certificates.length > 0 ? (
            <div className='certificates__grid row'>
              <div className='certificates__box col-md-6 my-3'>
                <div className='certitficate__img'>
                  <img src={images.certificateImg} alt='Certificate img' />
                </div>
                <button className='btn btn-outline-dark my-3'>
                  <i className='fa fa-download me-3'></i>
                  <span>Download Certificate</span>
                </button>
              </div>

              <div className='certificates__box col-md-6 my-3'>
                <div className='certitficate__img'>
                  <img src={images.certificateImg} alt='Certificate img' />
                </div>
                <button className='btn btn-outline-dark my-3'>
                  <i className='fa fa-download me-3'></i>
                  <span>Download Certificate</span>
                </button>
              </div>
              <div className='certificates__box col-md-6 my-3'>
                <div className='certitficate__img'>
                  <img src={images.certificateImg} alt='Certificate img' />
                </div>
                <button className='btn btn-outline-dark my-3'>
                  <i className='fa fa-download me-3'></i>
                  <span>Download Certificate</span>
                </button>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{ minHeight: '90vh' }}
                className='h-100 d-flex justify-content-center align-items-center'
              >
                <img
                  src={images.noCertificate}
                  alt='No certificates available'
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Certificates
