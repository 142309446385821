import React from 'react'
import { images } from '../Pictures/images'

const Footer = () => {
  const socialLinks = [
    {
      title: 'Linkedin',
      icon: images.linkedin,
      url: 'https://www.linkedin.com/company/ibcmediaoffical'
    },
    {
      title: 'Facebook',
      icon: images.facebook,
      url: 'https://www.facebook.com/people/IBC-Media/100084045159312'
    },
    {
      title: 'Twitter',
      icon: images.twitter,
      url: 'https://twitter.com/IBCMedia_'
    },
    {
      title: 'Youtube',
      icon: images.youtube,
      url: 'https://www.youtube.com/channel/UCf71rrDmJkHVicqeaEvz0VQ?sub_confirmation=1'
    },
    {
      title: 'Discord',
      icon: images.discord,
      url: 'https://discord.com/invite/zSWaTrZtwP'
    },
    {
      title: 'Whatsapp',
      icon: images.whatsapp,
      url: 'https://chat.whatsapp.com/D8g6XXukSJO6H3eyFUFqN9'
    },
    {
      title: 'Telegram',
      icon: images.telegram,
      url: 'https://t.me/ibcmediaofficial'
    },
    {
      title: 'Instagram',
      icon: images.instagram,
      url: 'https://www.instagram.com/ibcmediaofficial/?igshid=NTc4MTIwNjQ2YQ%3D%3D'
    }
  ]

  return (
    <div className='footer-bg'>
      <div className='container footer'>
        <div className='footer__grid'>
          <div className='footer__box '>
            <h6>Social Media</h6>
            <div className='img-mobile social-links'>
              {socialLinks.map((link, i) => {
                return (
                  <a
                    href={link.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label={link.title}
                    title={link.title}
                    key={i}
                  >
                    <img
                      src={link.icon}
                      className='img-fluid'
                      alt={link.title}
                      loading='lazy'
                    />
                  </a>
                )
              })}
            </div>
            <div className='border-top mx-auto'></div>
            <div className='py-5'>
              <p className='mob-para-center'>
                Copyright @ 2022-23 Ibc Media |{' '}
                <a
                  href='https://ibc.media/terms-and-conditions'
                  target='_blank'
                  rel='noreferrer'
                  className='text-white'
                >
                  T&C
                </a>
              </p>
            </div>
          </div>
          <div className='footer__box box-2'>
            <h6>Support</h6>
            <div className='py-4'>
              <p className='text-white mob-para-center'>
                Email -{' '}
                <a
                  href='mailto:education@ibc.media'
                  className='text-white text-no-decoration'
                >
                  education@ibc.media
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
