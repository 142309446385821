import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { getEvents } from '../API/eventsAPI'

const AltHackLanding = () => {
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isWarning, setIsWarning] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getIBCEvents()
  }, [])

  const getIBCEvents = async () => {
    try {
      const res = await getEvents('', 'Hackathon')
      const { data } = res
      setEvents([...data?.data])
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setIsWarning(true)
    }
  }

  return (
    <div className='alt-hack-landing py-3'>
      <div className='card px-4 py-4 px-0-mobile'>
        {isLoading ? (
          <div className='loading-div'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : null}

        {events && events.length > 0 ? (
          <>
            <div className='alt-hack-landing__boxes'>
              <>
                {events.map((obj, index) => {
                  return (
                    <div
                      className='alt-hack-landing__box rounded-3'
                      key={index}
                      // console.log('obj alt hack', obj)
                    >
                      <div className='img-background rounded-3'>
                        <img
                          src={obj?.image}
                          className='w-100 d-block'
                          alt='alt-hack-img'
                        />
                      </div>
                      <div className='alt-hack-landing__text rounded-3'>
                        <h1>{obj?.name}</h1>
                        <div className='buttons px-2 pb-2 position-relative'>
                          <button
                            className='btn-purple'
                            // style={{ flex: '1' }}
                            onClick={() =>
                              navigate(
                                `/dashboard/alt-hack/${obj?.id}/schedule`
                              )
                            }
                          >
                            Schedule
                          </button>
                          <button
                            className='btn-purple'
                            // style={{
                            //   flex: '3'
                            // }}
                            onClick={() =>
                              navigate(
                                `/dashboard/alt-hack/${obj?.id}/create-team`
                              )
                            }
                          >
                            Team - Program Selection -
                            <br />
                            Idea Submission
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            </div>
          </>
        ) : isWarning ? (
          <div className='warning-banner d-flex flex-column'>
            No events found
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default AltHackLanding
