import React from 'react'
import { Routes, Route } from 'react-router'
import Home from '../pages/Home'
import Signup from '../pages/Signup'
import Login from '../pages/Login'

import ProfilePage from '../pages/ProfilePage'
import QrPage from '../pages/QrPage'
import ForgetPassword from '../pages/ForgetPassword'
import ResetPassword from '../pages/ResetPassword'
import PrivateRoute from './PrivateRoutes'
import DashboardLayout from '../layout/DashboardLayout'
import AltHackLanding from '../pages/AltHackLanding'
import AltHackSchedule from '../pages/AltHackSchedule'
import AltHack from '../pages/AltHack'
import Quizzes from '../pages/Quizzes'
import Team from '../pages/Team'
import FeedbackForm from '../pages/FeedbackForm'
import Certificates from '../pages/Certificates'
import Faq from '../pages/Faq'
import AltHackScheduleByHook from '../pages/AltHackScheduleHooks'
import QuizAssementPage from '../pages/QuizAssementPage'
import CepLanding from '../pages/CepLanding'
import CepEventSchedule from '../pages/cep/CepEventSchedule'
import CepMain from '../pages/cep/CepMain'
import CepQuizzes from '../pages/cep/CepQuizzes'
import Support from '../pages/Support'
import Learning from '../pages/Learning'
import Courses from '../components/views/Learning/Courses'
import Subjects from '../components/views/Learning/Subjects'
import Topics from '../components/views/Learning/Topics'
import TopicLanding from '../components/views/Learning/TopicLanding'
import { isRegistrationClosed } from '../utils/constants'
import RegistrationClosed from '../components/RegistrationClosed'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route
        path='/signup'
        element={isRegistrationClosed ? <RegistrationClosed /> : <Signup />}
      />
      <Route path='/login' element={<Login />} />
      <Route path='/forget-password' element={<ForgetPassword />} />
      <Route path='/forgotPassword/:id' element={<ResetPassword />} />

      <Route path='/faq' element={<Faq />} />

      <Route path='/' element={<PrivateRoute />}>
        <Route path='/dashboard' element={<DashboardLayout />}>
          <Route index element={<ProfilePage />} />
          <Route path='learn' element={<Learning />}>
            <Route index element={<Courses />} />
            <Route path='courses' element={<Courses />} />
            <Route path='courses/:courseId' element={<Subjects />} />
            <Route path='courses/:courseId/:subjectId' element={<Topics />} />
            <Route
              path='courses/:courseId/:subjectId/:materialId'
              element={<TopicLanding />}
            />
          </Route>

          <Route path='profile-settings' element={<ProfilePage />} />
          <Route path='certificates' element={<Certificates />} />

          <Route path='alt-hack' element={<AltHackLanding />} />
          <Route path='alt-hack/:id' element={<AltHack />}>
            <Route index element={<AltHackSchedule />} />
            <Route path='schedule' element={<AltHackScheduleByHook />} />
            <Route path='create-team' element={<Team />} />
            <Route path='quiz' element={<Quizzes />} />
            <Route path='feedback' element={<FeedbackForm />} />
          </Route>

          {/* //to change accorging to cep */}
          <Route path='cep' element={<CepLanding />} />
          <Route path='cep/:id' element={<CepMain />}>
            <Route path='schedule' element={<CepEventSchedule />} />
            <Route path='create-team' element={<Team />} />
            <Route path='quiz' element={<CepQuizzes />} />
            <Route path='feedback' element={<FeedbackForm />} />
          </Route>

          <Route path='support' element={<Support />} />
          <Route path='qrcode' element={<QrPage />} />
          <Route path='assesment' element={<QuizAssementPage />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRoutes
